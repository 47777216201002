import React from "react";
import moment from "moment";
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Tooltip
} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const ActionsMenu = ({ question, onDelete, onArchive }) => {
  if (question.gameQuestionsCount > 0) {
    return null
  }

  return (
    <>
      <Tooltip title="Archive" aria-label="archive">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            const isConfirmed = window.confirm(`Are you sure you want to archive question ID ${question.id} connected to ${question.gameQuestionsCount} games?`)

            if (isConfirmed) {
              onArchive(question.id);
            }
          }}
        >
          <ArchiveIcon style={{ color: "DarkRed" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete" aria-label="delete">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            const isConfirmed = window.confirm(`Are you sure you want to delete question ID ${question.id} connected to ${question.gameQuestionsCount} games?`)

            if (isConfirmed) {
              onDelete(question.id)
            }
          }}
        >
          <DeleteIcon style={{ color: "DarkRed" }} />
        </IconButton>
      </Tooltip>
    </>
  )
}

const SimilarQuestionsModal = ({ selectedQuestion, isReloading, onArchive, onDelete, onClose }) => {
  if (!selectedQuestion) {
    return null;
  }

  return (
    <Modal
      open={!!selectedQuestion}
      onClose={onClose}
    >
      <Box sx={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto', width: 1000 }}>
        <Card>
          <CardHeader
            title={`Similar questions to ID: ${ selectedQuestion.id }`}
            action={
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Table>
              <TableHead sx={{ fontWeight: 'bold' }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Prompt</TableCell>
                  <TableCell>Last updated</TableCell>
                  <TableCell>Games no</TableCell>
                  <TableCell>Similarity rate</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isReloading ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    selectedQuestion.similars.map(
                      ({ question, similarityRate }) => (
                        <TableRow key={question.id}>
                          <TableCell>{question.id}</TableCell>
                          <TableCell>{question.prompt}</TableCell>
                          <TableCell>
                            <Typography variant="caption" title={question.lastUpdated}>
                              {moment(question.lastUpdated).calendar()}
                            </Typography>
                          </TableCell>
                          <TableCell>{question.gameQuestionsCount}</TableCell>
                          <TableCell>{similarityRate}</TableCell>
                          <TableCell sx={{ width: 100 }}>
                            <ActionsMenu
                              question={question}
                              onArchive={onArchive}
                              onDelete={onDelete}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  )
}

export default SimilarQuestionsModal;
