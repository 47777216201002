import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import commaNumber from "comma-number";
import formatPercentage from "../formatPercentage";

const Completions = ({ usersBreakdown }) => {
  const { reportedUsers, completedOnce, completedTwice } = usersBreakdown;

  return (
    <Card>
      <CardHeader title="Completions" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {commaNumber(reportedUsers)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed Once</TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {commaNumber(completedOnce)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completion Rate</TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {formatPercentage(completedOnce, reportedUsers)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed Twice</TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {commaNumber(completedTwice)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>% Repeat Players</TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {formatPercentage(completedTwice, completedOnce)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Completions;
