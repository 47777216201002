import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import endpoints from "../../../api/endpoints";
import SwapDrawer from "./SwapDrawer";
import Html from "../../Html";

const getConfirmationMessage = (type, id, numCompaniesUsing) => {
  const usageMessage =
    numCompaniesUsing === 0
      ? "It is currently unused."
      : `It is currently used by ${pluralize(
          "company",
          numCompaniesUsing,
          true
        )}`;

  if (type === "archive") {
    return `Are you sure you want to ARCHIVE question ${id}?\n\n${usageMessage}\n\nThe question will be removed from use going forward but will be preserved in the database.`;
  }

  if (type === "delete") {
    return `Are you sure you want to DELETE question ${id}?\n\n${usageMessage}\n\nThis cannot be undone.`;
  }
};

const QuestionRow = ({ q, setEditQuestion, refresh }) => {
  const [swapActive, setSwapActive] = useState(false);

  return (
    <>
      <TableCell sx={{ minWidth: 130 }}>
        <Tooltip title="Edit" aria-label="edit">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              window.scrollTo(0, 0);
              setEditQuestion(q);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Duplicate" aria-label="duplicate">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              window.scrollTo(0, 0);
              setEditQuestion({
                ...q,
                duplicate: true,
              });
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Preview" aria-label="preview">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_GAME_APP_URL}/quiz?embed=true&questions=${q.id}`
              );
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive" aria-label="archive">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (
                window.confirm(
                  getConfirmationMessage("archive", q.id, numCompanies)
                )
              ) {
                endpoints.questions
                  .archive({
                    id: q.id,
                  })
                  .then((response) => {
                    const { id, name } = _.get(response, "data.question", {});
                    window.alert(
                      `Question ${id} archived.\n\nTo unarchive it please contact support.\n\nIt read: ${name}.`
                    );
                    refresh();
                  })
                  .catch((err) => {
                    window.alert(`Could not archive question.\n\n${err}`);
                  });
              }
            }}
          >
            <ArchiveIcon style={{ color: "DarkRed" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (
                window.confirm(
                  getConfirmationMessage("delete", q.id, numCompanies)
                )
              ) {
                endpoints.questions
                  .delete({
                    id: q.id,
                  })
                  .then((response) => {
                    const { id } = _.get(response, "data.question", {});
                    window.alert(
                      `Question ${id} deleted.\n\nThis cannot be undone.`
                    );
                    refresh();
                  })
                  .catch((err) => {
                    window.alert(`Could not delete question.\n\n${err}`);
                  });
              }
            }}
          >
            <DeleteIcon style={{ color: "DarkRed" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Swap" aria-label="swap">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSwapActive(true);
            }}
          >
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>
        <SwapDrawer
          open={swapActive}
          question={q}
          onClose={(e) => {
            e.stopPropagation();
            setSwapActive(false);
          }}
        />
      </TableCell>
      <TableCell>{q.id}</TableCell>
      <TableCell>
        <Typography>
          <Html>{q.prompt}</Html>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{moment(q.lastUpdated).calendar()}</Typography>
      </TableCell>
      <TableCell>{q.numCompanies || _.get(q, "companies.length", 0)}</TableCell>
      <TableCell>
        <Typography>{_.get(q, "customCompany.name")}</Typography>
      </TableCell>

      <TableCell align="center">
        {q.published ? <CheckCircleIcon color="primary" /> : null}
      </TableCell>
      <TableCell align="center">
        {q.harvardApproved ? (
          <CheckCircleIcon
            style={{
              color: "#A41034",
            }}
          />
        ) : null}
      </TableCell>

      <TableCell>
        <Typography>{q.module}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{q.format}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography>{q.length}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{q.grade}</Typography>
      </TableCell> */}
    </>
  );
};

export default QuestionRow;
