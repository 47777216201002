import React, { useState, useReducer, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  FormControl,
  TextField,
  Switch,
  Button,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  CardHeader,
  FormControlLabel,
  Stack,
  AppBar,
  Toolbar,
} from "@mui/material";
import * as R from "ramda";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import LoadingButton from "../LoadingButton";
import StandardAnswersEditor from "./StandardAnswersEditor";
import SliderAnswerEditor from "./SliderAnswerEditor";
import QuestionImagesEditor from "./QuestionImagesEditor";
import { default as api } from "../../api/endpoints";
import { basic, slider, tiles } from "../../utils/formats";
import RichTextField from "./RichTextField";
import answersReducer, { initialState, init } from "./answersReducer.js";

const filter = createFilterOptions();

const defaultQuestion = {
  format: "basic",
  images: [],
  answers: [],
  module: "None",
};

const someAnswersInvalid = (answers) =>
  answers.some((answer) => !answer.isValid);

function excludeField(obj, field) {
  const { [field]: _, ...newObj } = obj;
  return newObj;
}

const QuestionEditorContainer = ({ question = {}, ...rest }) => {
  const [questionData, setQuestionData] = useState();
  const [loading, setLoading] = useState(true);

  const { id, duplicate } = question;

  useEffect(() => {
    if (id) {
      api.questionsSearch
        .searchById({
          id,
          include_all: true,
        })
        .then((response) => {
          let questionData = response.data.question;

          if (duplicate) {
            questionData = excludeField(questionData, "id");
          }

          setQuestionData(questionData);
          setLoading(false);
        })
        .catch((error) => {
          window.alert("Could not load question: ", error.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id, duplicate]);

  if (!id) {
    return <QuestionEditor question={defaultQuestion} {...rest} />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questionData) {
    return <div>Error loading question</div>;
  }

  return (
    <QuestionEditor
      question={questionData}
      {...rest}
      duplicate={duplicate}
      originalId={id}
    />
  );
};

const QuestionEditor = ({
  question: q = defaultQuestion,
  onFinishEditing,
  onCancelEditing,
  modules,
  companies,
  limited,
  duplicate,
  originalId,
}) => {
  const [saving, setSaving] = useState();
  const [images, setImages] = useState(q.images);
  const [answers, dispatchAnswers] = useReducer(
    answersReducer,
    q.answers || initialState,
    init
  );
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  function getTitle(q, duplicate, originalId) {
    if (q.id) {
      return `Edit Question ${q.id}`;
    } else if (duplicate) {
      return `Duplicate of Question ${originalId}`;
    } else {
      return "New Question";
    }
  }

  const title = getTitle(q, duplicate, originalId);

  const defaultValues = {
    ...q,
    customCompanyId: _.get(q, "customCompany.id", 0),
  };

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const watchFormat = watch("format");

  const onSubmit = handleSubmit(
    ({
      intro,
      startingValue,
      min,
      max,
      unit,
      tolerance,
      feedbackText,
      solution,
      prompt,
      harvardApproved,
      learnMoreUrl,
      format,
      module,
      published,
      customCompanyId,
      interval,
    }) => {
      setTriedToSubmit(true);

      if (someAnswersInvalid(answers)) {
        return;
      }

      setSaving(true);
      api.questionsSearch
        .updateQuestion({
          question_id: q.id,
          intro,
          name: prompt,
          custom_company_id: customCompanyId,
          harvard_approved: harvardApproved,
          link: learnMoreUrl,
          format,
          learning_module: module,
          images,
          startingValue,
          min,
          max,
          unit,
          tolerance,
          feedbackText,
          solution,
          published,
          interval,
          answers:
            answers?.map((answer) => ({
              ...answer,
              id: answer.id,
              is_right: answer.isCorrect,
              name: answer.mainText,
              explanation: answer.feedbackText,
            })) || [],
        })
        .then((response) => {
          setSaving(false);
          onFinishEditing(response.data);
        })
        .catch((error) => {
          setSaving(false);
          if (error.response.status === 422) {
            const messages = error.response.data.errors;
            window.alert(
              `Please fix these and try again: ${messages.join(", ")}`
            );
          } else {
            window.alert(`Could not save: ${error}`);
          }
        });
    }
  );

  return (
    <form onSubmit={onSubmit}>
      <AppBar position="fixed" color="gray" sx={{ top: 0 }}>
        <Toolbar>
          <CardHeader
            sx={{ width: "100%" }}
            title={title}
            action={
              <IconButton onClick={onCancelEditing} disabled={saving}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Toolbar>
      </AppBar>
      <Card
        sx={{
          height: "100%",
          overflowY: "auto",
          marginTop: "75px",
          marginBottom: "75px",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {companies && (
              <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name="customCompanyId"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="customize-for-label">
                        Applies To
                      </InputLabel>
                      <Select
                        inputRef={ref}
                        id="customize-for"
                        labelId="customize-for-label"
                        label="Applies To"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={0}>
                          <em>All Companies</em>
                        </MenuItem>
                        {companies.map((c) => (
                          <MenuItem value={c.id} key={c.id}>
                            {c.name} ({c.accountType})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={3}
              style={limited ? { display: "none" } : {}}
            >
              <Controller
                control={control}
                name="format"
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="format-label">Question Format</InputLabel>
                    <Select
                      inputRef={ref}
                      labelId="format-label"
                      id="format"
                      name="format"
                      label="Question Format"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <MenuItem value={basic} key={basic}>
                        Basic
                      </MenuItem>
                      <MenuItem value={slider} key={slider}>
                        Slider
                      </MenuItem>
                      <MenuItem value={tiles} key={tiles}>
                        Tiles (2x2)
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            {modules && (
              <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name="module"
                  render={({
                    field: { onChange, value, defaultValue, ref },
                  }) => (
                    <Autocomplete
                      disableClearable
                      style={{ minWidth: 200 }}
                      options={[{ title: "None", type: "clear" }].concat(
                        modules.map((m) => ({ title: m }))
                      )}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          onChange(newValue);
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          onChange(newValue.inputValue);
                        } else if (newValue && newValue.type === "clear") {
                          onChange("");
                        } else {
                          onChange(newValue.title);
                        }
                      }}
                      freeSolo
                      defaultValue={defaultValue}
                      value={value}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      autoHighlight
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          variant="outlined"
                          label="Module"
                          placeholder="e.g., Bone Density"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} style={limited ? { display: "none" } : {}}>
              <Stack direction="row" spacing={2}>
                <Controller
                  control={control}
                  name="harvardApproved"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          inputRef={ref}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                          inputProps={{
                            "aria-label": "Harvard Approved checkbox",
                          }}
                        />
                      }
                      label="Harvard Approved"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="published"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          inputRef={ref}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "published checkbox" }}
                        />
                      }
                      label="Published"
                      labelPlacement="end"
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="intro"
                render={({ field: { onChange, value, defaultValue, ref } }) => (
                  <RichTextField
                    label="Question Intro"
                    helperText="Input text that appears above the question in certain contexts as color commentary. E.g., 'Quizzify offers several types of nutrition questions:'"
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="prompt"
                rules={{
                  required: true,
                  min: 10,
                }}
                render={({ field: { onChange, value, defaultValue, ref } }) => {
                  return (
                    <RichTextField
                      label="Question Prompt"
                      helperText="A question prompt is required. Please do not put instructions into the prompt (e.g., 'Select two answers')"
                      onChange={onChange}
                      defaultValue={defaultValue}
                      value={value}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                {...register("learnMoreUrl")}
                variant="outlined"
                label="Learn More Link"
              />
            </Grid>
            <Grid item xs={12}>
              <QuestionImagesEditor
                images={images}
                onAddImage={(img) => {
                  setImages(images.concat([img]));
                }}
                onRemoveImage={(img) => {
                  setImages(images.filter((i) => i !== img));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {R.cond([
                [
                  R.equals(slider),
                  () => (
                    <SliderAnswerEditor
                      register={register}
                      control={control}
                      watch={watch}
                      errors={errors}
                    />
                  ),
                ],
                [
                  R.T,
                  () => (
                    <StandardAnswersEditor
                      answers={answers}
                      dispatch={dispatchAnswers}
                      triedToSubmit={triedToSubmit}
                    />
                  ),
                ],
              ])(watchFormat)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <AppBar position="fixed" color="gray" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <CardActions>
            <LoadingButton loading={saving} type="submit">
              Save Question
            </LoadingButton>
            <Button
              onClick={() => onCancelEditing()}
              color="primary"
              disabled={saving}
              variant="outlined"
            >
              Cancel
            </Button>
          </CardActions>
        </Toolbar>
      </AppBar>
    </form>
  );
};

export default QuestionEditorContainer;
