import React, { memo, useRef, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const libraries = ["places"];
const mapContainerStyle = { height: "100vh", width: "100%" };
const options = { disableDefaultUI: true };

function MapComponent({ locations = [], center, setCenter }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // useEffect(() => {
  //   if (locations.length > 0 && window.google) {
  //     console.log("I RAN");
  //     const bounds = new window.google.maps.LatLngBounds();
  //     locations.forEach((location) =>
  //       bounds.extend({
  //         lat: location.geometry.location.lat,
  //         lng: location.geometry.location.lng,
  //       })
  //     );
  //     mapRef.current.fitBounds(bounds);
  //   }
  // }, [locations]);

  const mapRef = useRef();

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = () => {
    const lat = mapRef.current.getCenter().lat();
    const lng = mapRef.current.getCenter().lng();
    setCenter({ lat, lng });
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      center={center}
      options={options}
      onLoad={onMapLoad}
      onDragEnd={onDragEnd}
    >
      {locations.map((location, i) => (
        <Marker
          key={i}
          position={{
            lat: location.geometry.location.lat,
            lng: location.geometry.location.lng,
          }}
        />
      ))}
    </GoogleMap>
  );
}

export default memo(MapComponent);
