import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ImagePicker from "../ImagePicker";
import RichTextField from "./RichTextField";
import { newAnswer, updateAnswer, deleteAnswer } from "./answersReducer.js";

const isAnswerDisharmonious = (answer) => {
  const { isCorrect, feedbackText } = answer;

  const correctWronglyMarked =
    isCorrect && feedbackText && feedbackText.includes("INCORRECT");
  const incorrectWronglyMarked =
    !answer.isCorrect &&
    feedbackText &&
    feedbackText.includes("CORRECT") &&
    !feedbackText.includes("INCORRECT");

  return correctWronglyMarked || incorrectWronglyMarked;
};

const StandardAnswersEditor = ({ answers = [], dispatch, triedToSubmit }) => {
  const [showImage, setShowImage] = useState(true);

  return (
    <Grid container direction="column" spacing={3}>
      <Divider />
      <Grid item>
        <Typography variant="h6">Edit Answers</Typography>
      </Grid>
      {answers?.length === 0 && (
        <Grid item>
          <Typography color="error">Create some answers!</Typography>
        </Grid>
      )}
      {answers?.map((answer, index) => (
        <Grid item key={answer.id}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <RichTextField
                label={`Answer ${index + 1} Text`}
                onChange={(value) =>
                  dispatch(updateAnswer(answer, "mainText", value))
                }
                value={answer.mainText}
                defaultValue={answer.mainText}
                error={triedToSubmit && !answer.isValid && !answer.mainText}
                helperText={
                  triedToSubmit && !answer.isValid && !answer.mainText
                    ? "Answer text must be present"
                    : ""
                }
              />
            </Grid>
            <Grid item>
              <RichTextField
                label={`Answer ${index + 1} Explanation`}
                onChange={(value) =>
                  dispatch(updateAnswer(answer, "feedbackText", value))
                }
                value={answer.feedbackText}
                defaultValue={answer.feedbackText}
                error={triedToSubmit && !answer.isValid && !answer.feedbackText}
                helperText={
                  triedToSubmit && !answer.isValid && !answer.feedbackText
                    ? "Explanation must be present"
                    : ""
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    defaultValue={answer.isCorrect}
                    checked={answer.isCorrect}
                    onChange={(e) =>
                      dispatch(
                        updateAnswer(answer, "isCorrect", e.target.checked)
                      )
                    }
                    color="primary"
                  />
                }
                label="Is Correct?"
                labelPlacement="end"
              />
              {isAnswerDisharmonious(answer) && (
                <Typography color="error">
                  The answer must say{" "}
                  {answer.isCorrect ? "'CORRECT'" : "'INCORRECT'"}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {answer.image ? (
                <>
                  <Typography>Image to appear on this answer</Typography>
                  <img
                    src={answer.image.url}
                    alt={answer.image.url}
                    width="300"
                  />
                </>
              ) : (
                <>
                  {showImage ? (
                    <>
                      <Typography>
                        Add image to this answer (optional)
                      </Typography>
                      <ImagePicker
                        path={process.env.REACT_APP_S3_QUIZ_IMAGES_PATH}
                        onSelect={({ id, url }) =>
                          dispatch(
                            updateAnswer(answer, "image", { id, url })
                          )
                        }
                      />
                    </>
                  ) : (
                    <Button
                      onClick={() => setShowImage(true)}
                      variant="outlined"
                      color="primary"
                    >
                      Add Image
                    </Button>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete Answer ${index + 1}?`
                    )
                  ) {
                    dispatch(deleteAnswer(answer));
                  }
                }}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Delete Answer {index + 1}
              </Button>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
      ))}
      <Grid item>
        <Button
          type="button"
          onClick={() => {
            dispatch(newAnswer());
          }}
          color="secondary"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add Answer
        </Button>
      </Grid>
    </Grid>
  );
};

export default StandardAnswersEditor;
