import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab
} from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";

const LinkTab = ({ label, to }) => <Tab component={Link} to={to} label={label} />;

const Navigation = () => {
  const { path } = useRouteMatch();
  const links = [
    {
      path: "/email-manager",
      label: "Overall stats"
    },
    {
      path: "/email-manager/deliveries",
      label: "Deliveries"
    },
    {
      path: "/email-manager/send-email",
      label: "Send email"
    }
  ]

  const value = links.findIndex((link) => link.path === path) || 0;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value}>
        {
          links.map(
            ({ path, label }) => (
              <LinkTab to={path} label={label} key={path} />
            )
          )
        }
      </Tabs>
    </Box>
  )
};

export default Navigation;
