import { useState } from "react";
import {
  Stack,
  Typography,
  Alert,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Collapse,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Controller } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";

import RichTextField from "../../../../Questions/RichTextField";
import sanitizeHtml from "../../../../../utils/sanitizeHtml";

const Prompt = ({ current, approved, reviewed, control }) => {
  const [showEdit, setShowEdit] = useState();

  if (current === approved && approved === reviewed) {
    return (
      <Card>
        <CardHeader title="Prompt" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Alert>No Changes</Alert>
            <Typography variant="subtitle1">CURRENT PROMPT</Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(current, {
                  formatLinks: true,
                }),
              }}
            />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (approved === reviewed) {
    return (
      <Card>
        <CardHeader title="Prompt" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Alert severity="warning">
              The reviewer did not recommend changes, but the prompt has changed
              since the review. Please choose the version you'd like to use. You
              may also choose to edit a final version and submit that.
            </Alert>

            {showEdit ? (
              <>
                <Typography variant="subtitle1">APPROVED PROMPT</Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(approved, {
                      formatLinks: true,
                    }),
                  }}
                />
                <Typography variant="subtitle1">CURRENT PROMPT</Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(current, {
                      formatLinks: true,
                    }),
                  }}
                />
                <Typography variant="subtitle1">FINAL EDITED PROMPT</Typography>

                <Controller
                  control={control}
                  name="prompt"
                  rules={{
                    required: "Required",
                  }}
                  render={({
                    field: { onChange, value, defaultValue },
                    fieldState: { error },
                  }) => (
                    <RichTextField
                      onChange={onChange}
                      defaultValue={defaultValue}
                      value={value}
                      error={error}
                      helperText={error}
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Controller
                  control={control}
                  name="prompt"
                  rules={{
                    required: "Required",
                  }}
                  render={({ field: { onChange, value, defaultValue } }) => (
                    <>
                      <RadioGroup
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value={approved}
                          control={<Radio />}
                          label={
                            <>
                              <Typography>APPROVED PROMPT</Typography>
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHtml(approved, {
                                    formatLinks: true,
                                  }),
                                }}
                              />
                            </>
                          }
                        />
                        <br />
                        <FormControlLabel
                          value={current}
                          control={<Radio />}
                          label={
                            <>
                              <Typography>CURRENT PROMPT</Typography>
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHtml(current, {
                                    formatLinks: true,
                                  }),
                                }}
                              />
                            </>
                          }
                        />
                      </RadioGroup>
                    </>
                  )}
                />
                <Button startIcon={<EditIcon />} onClick={setShowEdit}>
                  Make Your Own Edits
                </Button>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Prompt" />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Alert severity="warning">The Reviewer Changed the Prompt</Alert>
          <Typography variant="subtitle1">CURRENT PROMPT</Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(current, {
                formatLinks: true,
              }),
            }}
          />
          <Typography variant="subtitle1">APPROVED PROMPT</Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(approved, {
                formatLinks: true,
              }),
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Prompt;
