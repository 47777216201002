import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  CircularProgress,
  IconButton,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { default as api } from "../../api/endpoints";
import CommonUserForm from "./CommonUserForm";
import ErrorsContainer from "./ErrorsContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EditUser = ({ setCurrentView, user }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backendErrors, setBackendErrors] = useState(null);

  const {
    id,
    firstName,
    lastName,
    email,
    employeeId,
    companyGroupId,
    companyId,
    companyName,
    subscribed,
  } = user;

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      employeeId,
      companyGroupId,
      subscribed,
      company: {
        id: companyId,
        name: companyName,
      },
    },
  });

  const goToList = () => setCurrentView({ view: "list" });

  const onSubmit = handleSubmit((values) => {
    values["id"] = id;

    setIsSubmitting(true);

    api.users.update(values).then(
      () => {
        setIsSubmitting(false);
        goToList();
      },
      ({ response }) => {
        if (response.status === 422) {
          setBackendErrors(response.data.errors);
        } else if (response.status === 403) {
          setBackendErrors({
            authorization: "you are not authorized for this action",
          });
        }

        setIsSubmitting(false);
      }
    );
  });

  const onCancel = (e) => {
    e.preventDefault();
    goToList();
  };

  return (
    <div>
      <Button
        onClick={() => setCurrentView({ view: "list", payload: {} })}
        size="small"
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>

      <form onSubmit={onSubmit} autoComplete="off" style={{ marginTop: 8 }}>
        <Card>
          <CardHeader title="Edit User" />
          <Divider />
          <ErrorsContainer errors={backendErrors} />
          <CardContent>
            <Stack spacing={2}>
              <CommonUserForm
                register={register}
                errors={errors}
                control={control}
                watch={watch}
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="inherit" onClick={onCancel}>
              Cancel
            </Button>

            {isSubmitting && <CircularProgress size={30} />}
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

export default EditUser;
