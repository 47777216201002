import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Box,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { default as api } from "../../../api/endpoints";

const Images = ({ control }) => {
  const [images, setImages] = useState();

  useEffect(() => {
    api.bannerImages
      .get()
      .then((response) => setImages(response.data.banner_images))
      .catch((error) => console.log(error));
  }, []);

  const renderImage = (img, width = 150, height = 100) => {
    if (!img) return null;

    if (img.url.includes("mp4")) {
      return (
        <video
          style={{
            width,
            maxHeight: height,
            objectFit: "cover",
          }}
          autoPlay
          muted
          loop
        >
          <source src={img.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return (
      <img
        src={img.url}
        width={width}
        height={height}
        style={{ objectFit: "cover" }}
        alt={img.alt}
      />
    );
  };

  return (
    <Card>
      <CardHeader
        title="Select a Banner Image for the Quiz"
        subheader="This will show up on the user home page"
        action={
          <Button href="mailto:peter@quizzify.com?subject=Quiz Banner Image Request">
            Request new image
          </Button>
        }
      />
      <Divider />
      {!images ? (
        <CardContent>
          <CircularProgress />
        </CardContent>
      ) : (
        <Controller
          name="bannerImage"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <CardContent>
                  <Autocomplete
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    options={images}
                    getOptionLabel={(opt) => opt.name}
                    autoSelect
                    openOnFocus
                    renderInput={(params) => (
                      <TextField {...params} label="Banner Image" />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem key={option.id} value={option} {...props}>
                        {renderImage(option)}
                        <Typography variant="subtitle1" sx={{ ml: 2 }}>
                          {option.name}
                        </Typography>
                      </MenuItem>
                    )}
                  />
                  {value && (
                    <>
                      <Box
                        sx={{
                          p: 1,
                          backgroundColor: "secondary.main",
                          width: "fit-content",
                        }}
                      >
                        {renderImage(value, 300, 200)}
                      </Box>
                      <Button onClick={() => onChange(null)}>
                        Remove Image
                      </Button>
                    </>
                  )}
                </CardContent>

                {/* <CardContent sx={{ overflowX: "auto" }}>
                  <Stack spacing={2} direction="row">
                    {images.map((img) => (
                      <Card key={img.id} sx={{ minWidth: 150 }}>
                        <CardActionArea onClick={() => onChange(img)}>
                          <CardHeader
                            title={
                              <Typography variant="subtitle1">
                                {img.name}
                              </Typography>
                            }
                            action={
                              value && value.id === img.id ? (
                                <CheckCircleIcon color="secondary.dark" />
                              ) : null
                            }
                          />
                      
                        </CardActionArea>
                      </Card>
                    ))}
                  </Stack>
                </CardContent> */}
              </>
            );
          }}
        />
      )}
    </Card>
  );
};

export default Images;
