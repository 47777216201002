import { useState } from "react";
import { Controller } from "react-hook-form";
import {
  Stack,
  Alert,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";

import RichTextField from "../../../../../Questions/RichTextField";
import sanitizeHtml from "../../../../../../utils/sanitizeHtml";
import SimpleDisplay from "./SimpleDisplay";
import AnswerLine from "./AnswerLine";

const getChangeStatus = (current, approved, prior) => {
  if (!current) {
    return "answer-deleted";
  }

  if (
    current.name === approved.name &&
    current.explanation === approved.explanation
  ) {
    return "same";
  }

  if (
    current.name === prior.name &&
    current.explanation === prior.explanation
  ) {
    return "reviewer-changes";
  }

  return "complex-changes";
};

const renderChangeStatus = (status) => {
  if (status === "answer-deleted")
    return (
      <Alert severity="error">Answer Has Been Deleted Since the Review</Alert>
    );

  if (status === "same") return <Alert>No Changes</Alert>;

  if (status === "reviewer-changes")
    return <Alert severity="warning">Reviewer Made Changes</Alert>;

  if (status === "complex-changes")
    return (
      <Alert severity="error">
        Reviewer Made Changes and Answer Changed Since Review
      </Alert>
    );
};

const StandardAnswer = ({ index, control, current, approved, prior }) => {
  const [showEdit, setShowEdit] = useState();

  const editStatus = getChangeStatus(current, approved, prior);

  if (editStatus === "same") {
    return (
      <SimpleDisplay
        title={`Answer ${index + 1}`}
        subtitle={renderChangeStatus(editStatus)}
        answer={current}
      />
    );
  }

  if (editStatus === "answer-deleted") {
    return (
      <SimpleDisplay
        title={`Answer ${index + 1}`}
        subtitle={renderChangeStatus(editStatus)}
        answer={approved}
      />
    );
  }

  return (
    <Card>
      <CardHeader
        title={`Answer ${index + 1}`}
        subheader={renderChangeStatus(editStatus)}
      />
      <Divider />

      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="subtitle1">APPROVED ANSWER</Typography>
            <AnswerLine title="Header" body={approved.name} />
            <AnswerLine title="Explanation" body={approved.explanation} />
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle1">CURRENT ANSWER</Typography>
            <AnswerLine title="Header" body={current.name} />
            <AnswerLine title="Explanation" body={current.explanation} />
          </Box>

          {showEdit ? (
            <Stack spacing={2}>
              <Divider />
              <Typography variant="subtitle1">
                MAKE YOUR OWN FINAL EDITS
              </Typography>

              <Controller
                control={control}
                name={`answers.${index}.name`}
                render={({
                  field: { onChange, value, defaultValue },
                  fieldState: { errors },
                }) => (
                  <RichTextField
                    label="Header"
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                    error={Boolean(_.get(errors, `answers.[${index}].name`))}
                    helperText={
                      Boolean(_.get(errors, `answers.[${index}].name`)) &&
                      "Required"
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name={`answers.${index}.explanation`}
                render={({
                  field: { onChange, value, defaultValue },
                  fieldState: { errors },
                }) => (
                  <RichTextField
                    label="Feedback / Explanation"
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                    error={Boolean(
                      _.get(errors, `answers.[${index}].explanation`)
                    )}
                    helperText={
                      Boolean(
                        _.get(errors, `answers.[${index}].explanation`)
                      ) && "Required"
                    }
                  />
                )}
              />
            </Stack>
          ) : (
            <>
              <Button startIcon={<EditIcon />} onClick={setShowEdit}>
                Make Your Own Edits
              </Button>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StandardAnswer;
