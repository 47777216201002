import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Divider,
  CardHeader,
} from "@mui/material";
import moment from "moment";

const chipColor = (type) => {
  if (type === "approved") {
    return "success";
  } else if (type === "requested") {
    return "warning";
  } else {
    return "default";
  }
};

const getUserName = (user) => {
  if (!user) return "";

  return user.name_at_the_time || user.email_at_the_time || "";
};

const HistoryItem = ({ item }) => (
  <Card variant="outlined">
    <CardHeader
      title={
        <Typography variant="subtitle1">{getUserName(item.user)}</Typography>
      }
      subheader={moment(item.created_at).calendar()}
      action={<Chip label={item.type} color={chipColor(item.type)} />}
    />

    {item.comment && (
      <>
        <Divider />
        <CardContent>
          <Typography variant="body1">{item.comment}</Typography>
        </CardContent>
      </>
    )}
  </Card>
);

export default HistoryItem;
