import { Button, Stack, CircularProgress } from "@mui/material";
import UsersList from "./UsersList";
import InviteUsers from "./InviteUsers";

const UsersPanel = ({ users, showAddUser, setShowAddUser }) => {
  if (!users) return <CircularProgress />;

  return (
    <Stack spacing={2}>
      {showAddUser ? (
        <InviteUsers onClose={() => setShowAddUser(false)} />
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowAddUser(true)}
          >
            Invite Users
          </Button>
          <UsersList users={users} />
        </>
      )}
    </Stack>
  );
};

export default UsersPanel;
