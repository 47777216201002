import React from "react";
import { Grid, Stack, Alert } from "@mui/material";
import Content from "./Content";

const ErrorAlert = ({ error }) =>
  error ? (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <Alert severity="error">{error}</Alert>
      </Grid>
    </Grid>
  ) : null;

const ContentEditor = (props) => (
  <Stack spacing={2}>
    <ErrorAlert {...props} />
    <Content {...props} />
  </Stack>
);

export default ContentEditor;
