import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  TextField,
  Divider,
  IconButton,
  Button,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CloseIcon from "@mui/icons-material/Close";
import MUIRichTextEditor from "mui-rte";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { htmlToText } from "html-to-text";
import muiTheme from "../../mui-theme";

const theme = createTheme(muiTheme);

const isHtml = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

const useStyles = makeStyles({
  richTextField: {
    "& #mui-rte-root": {
      minHeight: 192,
      padding: "0 16px",
    },
  },
});

const convertToContentState = (value) => {
  const contentHTML = convertFromHTML(value || "");
  const contentState = ContentState.createFromBlockArray(
    contentHTML.contentBlocks,
    contentHTML.entityMap
  );
  return JSON.stringify(convertToRaw(contentState));
};

const convertToHTML = (stringifiedRawDraftContentState) =>
  stateToHTML(convertFromRaw(JSON.parse(stringifiedRawDraftContentState)));

const RichTextEditor = forwardRef((props, ref) => {
  const { defaultValue, onSave, ...otherProps } = props;
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save: () => inputRef.current.save(),
  }));

  return (
    <ThemeProvider theme={theme}>
      <MUIRichTextEditor
        ref={inputRef}
        controls={["bold", "italic", "link"]}
        defaultValue={convertToContentState(defaultValue)}
        onSave={(content) => onSave(convertToHTML(content))}
        autoFocus
        {...otherProps}
      />
    </ThemeProvider>
  );
});

const RichEditorDialog = ({ onClose, open, label, onSave, defaultValue }) => {
  const styles = useStyles();
  const editorRef = useRef(null);

  const handleSaveClick = () => {
    editorRef.current.save();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true}>
      <Card>
        <CardHeader
          title={label}
          subheader="Edit text normally below the following buttons, and add links, bold, italics, etc. Then remember to save."
          action={
            <Tooltip title="Cancel without saving">
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Divider />
        <CardContent className={styles.richTextField}>
          <RichTextEditor
            ref={editorRef}
            defaultValue={defaultValue}
            onSave={onSave}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={handleSaveClick} color="primary">
            Save Changes
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

const RichTextField = ({
  value,
  onChange,
  label,
  helperText,
  error = false,
  preventAddingHtml,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const dialogButtonClick = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        label={label}
        helperText={helperText}
        error={error}
        InputLabelProps={{ shrink: !!value }}
        value={
          isHtml(value)
            ? htmlToText(value, {
                wordwrap: null,
              })
            : value
        }
        onChange={(e) => handleChange(e.target.value)}
        onClick={() => {
          // If it is html then don't allow direct editing, pop the rich-text dialog
          if (isHtml(value)) {
            dialogButtonClick();
          }
        }}
        InputProps={{
          readOnly: isHtml(value), // Do not allow direct editing if it's HTML
          endAdornment: preventAddingHtml ? null : (
            <InputAdornment position="end">
              <Tooltip title="Edit as Rich Text (e.g., add links)">
                <Button
                  size="small"
                  onClick={dialogButtonClick}
                  color="primary"
                  type="button"
                >
                  <AspectRatioIcon />
                </Button>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <RichEditorDialog
        onClose={handleDialogClose}
        open={dialogOpen}
        label={label}
        onSave={handleChange}
        defaultValue={value}
        onChange={onChange}
      />
    </>
  );
};

export default RichTextField;
