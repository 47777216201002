import React, { useState, useMemo, useEffect } from "react";
import {
  Autocomplete,
  ListItem,
  ListItemText,
  TextField
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import { default as api } from "../../../../api/endpoints";

const QuestionAutocomplete = ({ question, onChange }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () => debounce(
      (inputValue, callback) => {
        api.quizManager.questionSearch.post({ term: inputValue }).then(callback);
      },
      400
    ),
    []
  );

  useEffect(() => {
    if (inputValue.length > 0) {
      fetch(inputValue, ({ data }) => {
        setOptions(data.questions);
      });
    }
  }, [inputValue, fetch])

  return (
    <ListItem>
      <ListItemText>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => `ID ${option.id}: ${option.prompt}`}
          autoComplete
          value={value}
          noOptionsText={value && value.length > 0 ? "No matching question found" : "Please write question prompt or ID"}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={
            (e, newValue) => {
              setValue(newValue)
              onChange(newValue)
            }
          }
          renderInput={(params) => (
            <TextField {...params} label="Question to swap with" fullWidth />
          )}
        />
      </ListItemText>
    </ListItem>
  );
};

export default QuestionAutocomplete;
