import React from "react";
import { Paper, Grid } from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import muiTheme from "../../mui-theme";

const theme = createTheme(muiTheme);

const RichTextField = ({ label, name, onChange, value }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sx={{ fontWeight: 500 }}>
      <label htmlFor={name}>{label}</label>
    </Grid>
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ minHeight: 40, padding: 0.5 }}>
        <ThemeProvider theme={theme}>
          <MUIRichTextEditor
            label={label}
            id={name}
            inlineToolbar={true}
            inlineToolbarControls={["bold", "italic", "strikethrough", "link"]}
            controls={[]}
            onChange={onChange}
            value={value}
            key={name}
            autoFocus
          />
        </ThemeProvider>
      </Paper>
    </Grid>
  </Grid>
);

export default RichTextField;
