import React from "react";
import {
  ListItem,
  ListItemText,
  Button
} from "@mui/material";

const ConfirmationDialog = ({ question, quizzes, buttonLabel, confirmationText, onConfirm, onCancel }) => {
  return (
    <>
      <ListItem>
        <ListItemText>
          { confirmationText }
        </ListItemText>
      </ListItem>
      {
        quizzes.map(
          (quiz) => (
            <ListItem key={quiz.id}>
              <ListItemText>
                ID: {quiz.id} {quiz.name} (company ID: {quiz.company.id} {quiz.company.name})
              </ListItemText>
            </ListItem>
          )
        )
      }
      <ListItem>
        <Button variant="contained" color="primary" onClick={
          (e) => {
            e.stopPropagation();
            onConfirm({ selectedQuizzes: quizzes });
          }
        }>
          { buttonLabel }
        </Button>

        <Button color="primary" onClick={
          (e) => {
            e.stopPropagation();
            onCancel();
          }
        }>
          Cancel
        </Button>
      </ListItem>
    </>
  )
}

export default ConfirmationDialog;
