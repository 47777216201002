import React from "react";
// import _ from "lodash";

import { TextField, Autocomplete } from "@mui/material";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CompaniesAutocomplete = ({
  allCompanies,
  errors,
  parentRef,
  ...props
}) => {
  const options = allCompanies.map(({ id, name }) => ({
    id,
    name,
  }));

  return (
    <Autocomplete
      {...props}
      multiple
      openOnFocus
      name="companies_list"
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      autoHighlight
      // renderOption={(option, { selected }) => {
      //   return (
      //     <div key={option.id}>
      //       <Checkbox
      //         icon={
      //           <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
      //         }
      //         checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
      //         style={{ marginRight: 8 }}
      //         checked={selected}
      //       />
      //     </div>
      //   );
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={parentRef}
          error={"companies" in errors}
          helperText={errors?.companies?.message}
          variant="outlined"
          label={"Included Companies"}
          placeholder={"Select Companies for Quiz"}
        />
      )}
    />
  );
};

export default CompaniesAutocomplete;
