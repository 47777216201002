import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { green } from "@mui/material/colors";
import { htmlToText } from "html-to-text";
import TemplateModal from "./TemplateModal";

const CreateTemplateModal = ({
  template,
  selectedQuestions,
  handleSave,
  name,
  description,
  ...rest
}) => {
  const selectedQuestionIds = selectedQuestions?.map((q) => q.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...template,
      questionIds: selectedQuestionIds,
      name,
      description,
    },
  });

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit(handleSave);

  const renderQuestions = () => (
    <>
      {selectedQuestions?.map((question) => {
        if (!question.id) return null;

        const text = `${question.id}: ${question.prompt}`;

        return (
          <Typography
            key={question.id}
            sx={{
              backgroundColor: green[50],
              color: green[600],
            }}
          >
            {htmlToText(text)}
          </Typography>
        );
      })}
    </>
  );

  return (
    <TemplateModal
      {...rest}
      errors={errors}
      register={register}
      onSubmit={onSubmit}
      questions={renderQuestions()}
      title="Create Template"
    />
  );
};

export default CreateTemplateModal;
