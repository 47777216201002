const keyMirror = (keyMap) => {
  let newMap = {};
  Object.keys(keyMap).forEach((element) => {
    var key = String(element);
    newMap[key] = element;
  });
  return newMap;
};

export default keyMirror;
