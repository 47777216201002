import React from "react";
import {
  CardContent,
  AlertTitle,
  Alert
} from "@mui/material";

const ErrorsContainer = ({ errors }) => {
  if (!errors) {
    return null;
  }

  return (
    <CardContent>
      <Alert severity="error">
        <AlertTitle>Errors while saving to database</AlertTitle>
        <ul>
          {Object.keys(errors).map((field) => {
            const fieldErrors = errors[field];

            return (
              <li key={field}>
                <strong>{field}</strong>: {fieldErrors.join('; ')}
              </li>
            )
          })}
        </ul>
      </Alert>
    </CardContent>
  )
};

export default ErrorsContainer;
