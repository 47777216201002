import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { QuizzesStateProvider, context, actions } from "./QuizzesContext";
import { Loader } from "../Loader";
import { default as api } from "../../api/endpoints";
import CreateOrEditQuiz from "./CreateOrEditQuiz";
import PageContainer from "../PageContainer";

const Quizzes = ({ quizId, ...rest }) => {
  const { id, companyId } = useParams();

  const idToUse = quizId || id;

  const { dispatch } = useContext(context);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      api.quizzes
        .get({ id: idToUse })
        .then(({ data }) => {
          dispatch({ type: actions.loadState, data });
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error.message);
          setLoaded(true);
        });
    }
  }, [dispatch, setLoaded, loaded, idToUse]);

  return (
    <PageContainer>
      {loaded ? (
        <CreateOrEditQuiz id={idToUse} companyId={companyId} {...rest} />
      ) : (
        <Loader />
      )}
    </PageContainer>
  );
};

const QuizzesWithProvider = (props) => (
  <QuizzesStateProvider>
    <Quizzes {...props} />
  </QuizzesStateProvider>
);

export default QuizzesWithProvider;
