import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Switch,
  Divider,
  Box,
  Autocomplete,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import ImageEditor from "../ImageEditor";
import endpoints from "../../api/endpoints";
import LoadingButton from "../LoadingButton";

const ACCOUNT_TYPES = [
  {
    displayName: "Prospect",
    value: "prospect",
    explanation: "Companies in the running to be a client",
  },
  {
    displayName: "Client",
    value: "client",
    explanation: "Only companies with a signed contract",
  },
  {
    displayName: "Autopilot",
    value: "autopilot",
    explanation: "Self service clients or prospects",
  },
];

const CompanyEditor = ({ company = {}, onSave }) => {
  const { id, name, subdomain, accountType, logoUrl, autoEmails } = company;

  const [templateCompanies, setTemplateCompanies] = useState(false);
  const [isSaving, setIsSaving] = useState();

  const type = !!id ? "update" : "create";

  useEffect(() => {
    if (!templateCompanies) {
      endpoints.companies
        .getCompanyTemplates()
        .then((response) => {
          setTemplateCompanies(response.data.templates);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [templateCompanies]);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      id,
      name,
      subdomain,
      accountType: accountType || "prospect",
      logoUrl,
      autoEmails: id ? autoEmails : true,
      autopilot: type === "create" ? true : null,
    },
  });

  const editedName = watch("name");
  const isSubdomainDirty = dirtyFields.subdomain;
  const alreadyHasSubdomain = !!subdomain;

  useEffect(() => {
    if (editedName && !isSubdomainDirty && !alreadyHasSubdomain) {
      const hyphenatedName = editedName.replaceAll(" ", "-").toLowerCase();

      setValue("subdomain", hyphenatedName);
    }
  }, [editedName, isSubdomainDirty, alreadyHasSubdomain]);

  const onSubmit = handleSubmit((values) => {
    setIsSaving(true);

    endpoints.companies
      .createOrUpdate(values)
      .then(({ data }) => {
        setIsSaving(false);
        reset();
        onSave(data);
      })
      .catch((err) => {
        setIsSaving(false);
        const messages = _.get(err, "response.data.messages");
        if (messages) {
          window.alert(
            `Could not create company:\n\n${messages.map(
              (msg) => `${msg}\n\n`
            )}`
          );
        } else {
          window.alert(err);
        }
      });
  });

  const templateCompany = watch("templateCompany");

  return (
    <form onSubmit={onSubmit} style={{ maxWidth: 750, height: "100%" }}>
      <Box
        sx={{
          height: "calc(100% - 64px)",
          boxSizing: "border-box",
          overflowY: "auto",
          padding: 3,
        }}
      >
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title="Company Profile"
              subheader={type === "update" ? `ID: ${id}` : null}
            />
            <Divider />
            <input name="id" {...register("id")} hidden />
            <CardContent>
              <Stack spacing={3}>
                <TextField
                  autoFocus
                  name="name"
                  label="Company Name"
                  variant="outlined"
                  {...register("name", {
                    required: "Company Name is Required",
                  })}
                  aria-label={"Company Name"}
                  fullWidth={true}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  name="subdomain"
                  label="Subdomain"
                  variant="outlined"
                  {...register("subdomain", {
                    required: "Subdomain is Required",
                  })}
                  aria-label="Subdomain"
                  fullWidth={true}
                  error={!!errors.subdomain}
                  helperText={
                    errors.subdomain?.message ||
                    "https://[SUBDOMAIN].quizzify.com - Subdomains should be brief, lowercase, and use short dashes (-) instead of spaces"
                  }
                />
                <Controller
                  control={control}
                  name="accountType"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="account-type-label">
                        Account Type
                      </InputLabel>
                      <Select
                        inputRef={ref}
                        labelId="account-type-label"
                        defaultValue={ACCOUNT_TYPES[0]}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        label="Account Type"
                      >
                        {ACCOUNT_TYPES.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.displayName} ({type.explanation})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="logoUrl"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ImageEditor
                        title="Company Logo"
                        image={value ? { url: value } : null}
                        onChange={(values) => {
                          if (!values) {
                            onChange(null);
                          } else {
                            onChange(values?.url);
                          }
                        }}
                        uploadPath={process.env.REACT_APP_S3_COMPANY_LOGOS_PATH}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="autoEmails"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            inputRef={ref}
                            color="primary"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        }
                        label="Send Auto Emails"
                      />
                    </>
                  )}
                />
              </Stack>
            </CardContent>
          </Card>
          {type === "create" && (
            <Card>
              <CardHeader
                title="Copy Settings from Another Company"
                subheader="This will copy over the program message, contest message, news feed and quizzes"
              />
              <Divider />
              <CardContent>
                {templateCompanies ? (
                  <Controller
                    control={control}
                    name="templateCompany"
                    render={({ field: { onChange, value, ref } }) => (
                      <Autocomplete
                        getOptionLabel={(option) =>
                          `${option.id} - ${option.name}`
                        }
                        options={templateCompanies}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                        value={value}
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            label="Company"
                            placeholder="Type company name or ID"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    )}
                  />
                ) : (
                  <CircularProgress />
                )}
              </CardContent>
            </Card>
          )}

          {type === "create" && (
            <Card>
              <CardContent>
                <Controller
                  control={control}
                  name="autopilot"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            inputRef={ref}
                            color="primary"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        }
                        label="Create Quizzes from Standard Curriculum (Supercedes Quizzes Copied from Other Companies)"
                      />
                    </>
                  )}
                />
              </CardContent>
            </Card>
          )}

          {/* <Grid item xs={12}>
            <Card>
              <CardHeader title="Program Message" />
              <Divider />
              <CardContent style={{ paddingBottom: 50 }}>
                <Controller
                  control={control}
                  name="programMessage"
                  render={({ field: { onChange, value, ref } }) => (
                    <MUIRichTextEditor
                      ref={ref}
                      onSave={(data) => console.log(data)}
                      value={value}
                      controls={[
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "clear",
                        "save",
                      ]}
                      label="Add Program Message to introduce Quizzify to users"
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid> */}
          {templateCompany && (
            <Typography variant="subtitle2" gutterBottom>
              The new company will copy settings from {templateCompany.name}
            </Typography>
          )}
        </Stack>
      </Box>
      <AppBar
        position="absolute"
        sx={{ top: "auto", bottom: 0, backgroundColor: "white" }}
      >
        <Toolbar>
          <LoadingButton type="submit" loading={isSaving}>
            Submit
          </LoadingButton>
        </Toolbar>
      </AppBar>
    </form>
  );
};

export default CompanyEditor;
