import { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import NumberFormat from "react-number-format";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import QuizIcon from "@mui/icons-material/Quiz";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import NotesIcon from "@mui/icons-material/Notes";
import Fuse from "fuse.js";
import _ from "lodash";
import QrCodeWithLogo from "qrcode-with-logos";

import PageContainer from "./PageContainer";
import { Loader } from "./Loader";
import Notes from "./Notes";
import DetailDrawer from "./DetailDrawer";
import CompanyCreator from "./CompanyEditor/CompanyEditor";
import CompanyEditor from "./CompanyEditor";

import { default as api } from "../api/endpoints";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CompanyRow = ({ d, rootPath, handleNotesClick, handleEditClick }) => {
  const links = [
    {
      href: `${rootPath}/my_dashboard/${d.id}`,
      icon: <HomeIcon />,
      tooltip: "Dashboard",
    },
    {
      href: `${rootPath}/my_quizzes/${d.id}`,
      icon: <QuizIcon />,
      tooltip: "Quizzes",
    },
  ];

  if (d.permissions && d.permissions.createQuiz) {
    links.push({
      href: `${rootPath}/admin/new_admin_redirect/create_quiz_for_company/${d.id}`,
      icon: <NoteAddIcon />,
      tooltip: "Create Quiz",
    });
  }

  if (d.permissions && d.permissions.edit) {
    links.push({
      onClick: handleEditClick,
      icon: <EditIcon />,
      tooltip: "Edit Account",
      keyName: "Edit Account",
    });
  }

  if (d.permissions?.showNotes) {
    links.push({
      onClick: handleNotesClick,
      icon: <NotesIcon color={d.has_notes ? "warning" : "primary"} />,
      tooltip: "Notes",
      keyName: "notes",
    });
  }

  if (d.home_page_url) {
    links.push({
      onClick: () => {
        new QrCodeWithLogo({
          canvas: document.createElement("canvas"),
          content: `${d.home_page_url}/?utm_medium=qrcode`,
          width: 500,
          download: true,
          image: document.createElement("img"),
          downloadName: `${d.name} - qrcode`,
          nodeQrCodeOptions: {
            color: {
              dark: "#2962FF",
            },
            margin: 0,
          },
        }).toImage();
      },
      keyName: "qr",
      icon: <QrCode2Icon />,
      tooltip: "Save QR Code",
    });
  }

  return (
    <TableRow
      sx={(theme) => ({
        backgroundColor: d.blink
          ? theme.palette.secondary.light
          : "transparent",
      })}
    >
      <TableCell>
        {d.logo ? <img src={d.logo} alt="logo" width="60" /> : ""}
      </TableCell>
      <TableCell sx={{ wordBreak: "break-word" }}>{d.id}</TableCell>
      <TableCell sx={{ wordBreak: "break-word" }}>{d.name}</TableCell>
      <TableCell>
        <NumberFormat
          thousandSeparator
          value={d.num_users}
          displayType={"text"}
        />
      </TableCell>
      <TableCell>{d.account_type}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {d.deactivated_at ? "" : <CheckCircleIcon />}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {d.auto_emails && <MarkEmailReadIcon />}
      </TableCell>

      <TableCell sx={{ wordBreak: "break-word" }}>
        {d.home_page_url && (
          <>
            <Link
              href={d.home_page_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {d.home_page_url}
            </Link>
          </>
        )}
      </TableCell>
      <TableCell sx={{ minWidth: 200 }}>
        {links.map(({ tooltip, href, icon, onClick, keyName }) => (
          <Tooltip title={tooltip} key={href || keyName}>
            <IconButton
              href={href}
              onClick={onClick}
              target="_top"
              color="primary"
            >
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </TableCell>
    </TableRow>
  );
};

const CompaniesList = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState();
  const [permissions, setPermissions] = useState({ canCreate: false });
  const [searchTerm, setSearchTerm] = useState();
  const [clientTypes, setClientTypes] = useState(["client"]);
  const [showNotes, setShowNotes] = useState();
  const [editCompanyId, setEditCompanyId] = useState();
  const [showCreateCompany, setShowCreateCompany] = useState();

  const handleNotesChange = (companyId, hasNotes) => {
    setCompanies(
      companies.map((item) =>
        item.id === companyId ? { ...item, has_notes: hasNotes } : item
      )
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClientTypes(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    api.companies
      .list()
      .then(({ data }) => {
        setCompanies(data.companies);
        setPermissions(data.permissions);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader />;
  }

  const rootPath = process.env.REACT_APP_MAIN_ROOT;

  const options = {
    keys: ["name"],
    threshold: 0.2,
    minMatchCharLength: 3,
  };

  const fuse = new Fuse(companies, options);

  const typeFilteredData = companies.filter((d) => {
    if (d.deactivated_at) return clientTypes.includes("deactivated");

    const matchingTypes = [
      "example",
      "client",
      "prospect",
      "autopilot",
      "demo",
    ];

    if (d.blink) return true;

    if (matchingTypes.includes(d.account_type)) {
      return clientTypes.includes(d.account_type);
    } else {
      return clientTypes.includes("other");
    }
  });

  const filteredData = searchTerm
    ? fuse.search(searchTerm).map((s) => s.item)
    : permissions.selectAccountType
    ? typeFilteredData
    : companies;

  const allClientTypes = [
    "example",
    "client",
    "prospect",
    "autopilot",
    "demo",
    "deactivated",
    "other",
  ];

  return (
    <>
      {editCompanyId ? (
        <DetailDrawer
          width={750}
          open
          title={`Edit Company ${editCompanyId}`}
          handleClose={() => setEditCompanyId()}
        >
          <CompanyEditor
            id={editCompanyId}
            onSave={({ company }) => {
              setCompanies(
                companies.map((c) =>
                  c.id === company.id ? { ...c, ...company } : c
                )
              );
              setEditCompanyId();
            }}
          />
        </DetailDrawer>
      ) : null}

      <DetailDrawer
        width={750}
        open={showCreateCompany}
        title="Create Company"
        handleClose={() => setShowCreateCompany(false)}
      >
        <CompanyCreator
          onSave={({ company }) => {
            setCompanies([{ ...company, blink: true }, ...companies]);
            setShowCreateCompany(false);
          }}
        />
      </DetailDrawer>
      <PageContainer>
        <Notes
          companyId={showNotes}
          handleClose={() => setShowNotes()}
          onNotesChange={handleNotesChange}
        />
        <Card>
          <CardHeader title="Accounts" />
          <Divider />
          <CardContent>
            <Grid container spacing={2} alignItems="flex-start" sx={{ mb: 2 }}>
              <Grid item>
                {permissions.canCreate ? (
                  <Button
                    onClick={() => setShowCreateCompany(true)}
                    color="primary"
                    startIcon={<AddIcon />}
                    variant="outlined"
                    sx={{
                      height: "100%",
                    }}
                  >
                    Create Company
                  </Button>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  sx={{ maxWidth: 500 }}
                  id="companies-autocomplete"
                  value={searchTerm}
                  onChange={(e, newValue) => {
                    const val = _.get(newValue, "name", "");
                    setSearchTerm(val);
                  }}
                  options={companies}
                  getOptionLabel={(opt) =>
                    `${opt.name} (${opt.num_users} Users – ${
                      opt.account_type || "No Type"
                    }${opt.deactivated_at ? " – Deactivated" : ""})`
                  }
                  freeSolo
                  autoHighlight
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Search Companies"
                      placeholder="ACME"
                    />
                  )}
                />
              </Grid>
              {permissions.selectAccountType && (
                <Grid item>
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel id="types-multiple-checkbox-label">
                      Account Type
                    </InputLabel>
                    <Select
                      labelId="types-multiple-checkbox-label"
                      id="types-multiple-checkbox"
                      multiple
                      value={clientTypes}
                      onChange={handleChange}
                      input={<OutlinedInput label="Account Type" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {allClientTypes.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={clientTypes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <TableContainer sx={{ height: "calc(100vh - 250px)" }}>
              <Table stickyHeader sx={{ maxHeight: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell sx={{ minWidth: 100 }}>Auto Emails</TableCell>
                    <TableCell>User Home Page</TableCell>
                    <TableCell>Admin Links</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((d) => (
                    <CompanyRow
                      key={d.id}
                      d={d}
                      rootPath={rootPath}
                      handleNotesClick={() => setShowNotes(d.id)}
                      handleEditClick={() => {
                        setEditCompanyId(d.id);
                      }}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default CompaniesList;
