import React from "react";
import {
  Grid,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const UserRow = ({ entry: { id, email, employee_id, company } }) => (
  <TableRow>
    <TableCell>{id}</TableCell>
    <TableCell>{email}</TableCell>
    <TableCell>{employee_id}</TableCell>
    <TableCell>{company.name} (ID: {company.id})</TableCell>
  </TableRow>
)

const EmptyUserRow = () => (
  <TableRow>
    <TableCell>--</TableCell>
    <TableCell>--</TableCell>
    <TableCell>--</TableCell>
    <TableCell>--</TableCell>
  </TableRow>
)

const UserTable = ({ label, entries }) => (
  <Grid item xs={12}>
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        component="div"
      >
        {label}
      </Typography>
    </Toolbar>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Employee ID</TableCell>
            <TableCell>Company</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            entries.length > 0 ? (
              entries.map((entry) => (
                <UserRow entry={entry} />
              ))
            ) : <EmptyUserRow />
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
);

export default UserTable;
