import { Box } from "@mui/material";
import { AppHeader } from "./AppHeader";

const AppContainer = ({ children, title }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    }}
  >
    <AppHeader title={title} />
    <Box sx={{ flexGrow: 1, overflowY: "auto", p: { xs: 0, sm: 1, md: 2 } }}>
      {children}
    </Box>
  </Box>
);

export default AppContainer;
