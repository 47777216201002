import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api/endpoints";
import { CircularProgress } from "@mui/material";

const QuizAIReview = () => {
  const [corrections, setCorrections] = useState("");
  const { id: quizId } = useParams();

  useEffect(() => {
    api.spellCheck
      .checkQuiz(quizId)
      .then((response) => {
        setCorrections(response.data.corrections);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [quizId]);

  return (
    <div>
      <h1>Quiz Review</h1>
      {corrections ? (
        <p style={{ whiteSpace: "pre-wrap" }}>{corrections}</p>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default QuizAIReview;
