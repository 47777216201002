import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import QrCodeWithLogo from "qrcode-with-logos";
import QrCode2 from "@mui/icons-material/QrCode2";

import AppContainer from "./AppContainer";
import api from "../api/endpoints";

const QuestionLinks = () => {
  const { id: quizId } = useParams();

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    api.quizzes
      .questions(quizId)
      .then(({ data: { questions } }) => setQuestions(questions))
      .catch((err) => window.alert(err));
  }, []);

  return (
    <AppContainer>
      <Typography variant="h6">Direct Starting Links to Questions</Typography>
      <Typography>
        The quiz will open to the given question but if they refresh or click
        away it'll go back to normal
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Save QR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map(({ id: questionId, name, intro }) => {
              const directLink = `${process.env.REACT_APP_GAME_APP_URL}/quizzes/${quizId}?go-to-question=${questionId}`;

              return (
                <TableRow key={questionId}>
                  <TableCell>
                    <Typography variant="caption">{intro}</Typography>
                    <Typography>{name}</Typography>
                  </TableCell>
                  <TableCell>
                    <a href={directLink} target="_blank" rel="noreferrer">
                      {directLink}
                    </a>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Download QR Code">
                      <IconButton
                        onClick={() => {
                          new QrCodeWithLogo({
                            canvas: document.createElement("canvas"),
                            content: `${directLink}/?utm_medium=qrcode`,
                            width: 500,
                            download: true,
                            image: document.createElement("img"),
                            downloadName: `QR-Quiz-${quizId}-Question-${questionId}-${name}.png`,
                            nodeQrCodeOptions: {
                              color: {
                                dark: "#2962FF",
                              },
                              margin: 0,
                            },
                          }).toImage();
                        }}
                      >
                        <QrCode2 />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </AppContainer>
  );
};

export default QuestionLinks;
