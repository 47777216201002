import React from "react";

import {
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Accordion = ({ opened, label, action, children, onToggle }) => (
  <>
    <ListItem>
      <ListItemButton
        onClick={
          (e) => {
            e.stopPropagation();
            onToggle(action);
          }
        }
      >
        <ListItemText>
          <Typography sx={{ fontWeight: (opened ? 'bold' : 'normal') }}>
            { label }
          </Typography>
        </ListItemText>
        <KeyboardArrowDownIcon
          sx={{
            mr: -1,
            transform: (opened ? 'rotate(-180deg)' : 'rotate(0)'),
            transition: '0.2s',
          }}
        />
      </ListItemButton>
    </ListItem>
    { opened ? children : null }
  </>
);

export default Accordion;
