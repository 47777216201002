import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  TextField,
  Stack,
  CardActions,
} from "@mui/material";
import { useForm } from "react-hook-form";

import api from "../api/endpoints";
import { context, actions } from "../AppContext";
import LoadingButton from "./LoadingButton";

const SetPassword = () => {
  const {
    state: { userProfile },
    dispatch,
  } = useContext(context);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: userProfile?.email,
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    const res = await api.auth.setInitialPassword(values);

    if (res.status !== 200) {
      window.alert("Failed to set password");
      return;
    }

    dispatch({
      type: actions.setPassword,
    });
  });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
      }}
    >
      <CardHeader
        title={<Typography variant="h6">Set Your Password</Typography>}
      />
      <Divider />
      <form onSubmit={onSubmit}>
        <CardContent sx={{ flex: 1, overflowY: "scroll" }}>
          <Stack spacing={2}>
            {userProfile && (
              <Typography>
                Please set a password for your account under:{" "}
                {userProfile.email}
              </Typography>
            )}

            <input type="hidden" {...register("email")} />

            <TextField
              label={"Password"}
              variant="outlined"
              type="password"
              {...register("password", {
                required: "You must enter a password",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              })}
              aria-label={"Password"}
              fullWidth
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <TextField
              label={"Password Confirmation"}
              variant="outlined"
              type="password"
              {...register("password_confirmation", {
                validate: (value) => {
                  if (value !== watch("password")) {
                    return "Passwords do not match";
                  }
                  return true;
                },
              })}
              aria-label={"Password Confirmation"}
              fullWidth
              error={!!errors.password_confirmation}
              helperText={errors.password_confirmation?.message}
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            size="small"
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleSubmit}
            loading={isSubmitting}
            startIcon={null}
          >
            Save
          </LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
};

export default SetPassword;
