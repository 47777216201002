import React from "react";

const appendPreviewParams = (link) => {
  if (link.indexOf("timestamp") > -1) {
    return link.replace(/timestamp=[^&]+/, "timestamp=" + Date.now()) + '&end_screen=1';
  } else {
    return link + (link.indexOf("?") > -1 ? "&" : "?") + "timestamp=" + Date.now() + '&end_screen=1';
  }
}

const PreviewEndScreen = ({ link }) => {
  if (!link) {
    return null;
  }

  const src = appendPreviewParams(link);

  return (
    <div style={{ flex: 1, minHeight: 1000 }}>
      <iframe
        title="Quiz Preview"
        src={src}
        style={{
          width: "100%",
          height: "100%",
          minHeight: 1000,
          border: "none",
          display: "block",
        }}
      />
    </div>
  );
};

export default PreviewEndScreen;
