const getEmail = (company, accountNumbers, groupNumber, hospitals) =>
  `I apologize for bothering you with this, but I can't locate the direct email for the facilities listed below. Can you please forward?

This serves as a blanket Financial Consent for anyone using ${company} Account Numbers:
  ${accountNumbers.map((number) => `- ${number}`).join("\n")}

for Group # ${groupNumber} while seeking services reasonably believed to be covered under EMTALA. The Consent will also be referenced on their insurance card, viewable via a QR code:

"Superseding other consents, I consent to responsibility (including insurance) for up to 2 times Medicare following receipt of an itemized bill for appropriate treatment coded at the correct Level."

Please remember not to ask people with these accounts to sign your Consent, as it is superseded by this one. (They may not recall that, during a clinical emergency.) If any concerns arise during the visit re this Consent, you may call 877-252-1570.

We don't mean to disrupt your workflow or require extra training, so if it is easier to have our members sign both the treatment and financial Consents, that's fine too. We will both know that the second of those is superseded, of course.

Please forward this to the following billing and ER departments for their files:
${hospitals.map((hospital) => `- ${hospital}`).join("\n")}

We will gladly meet with anyone who has questions about this, EMTALA, and why this is our Financial Consent, but if we don't hear from anyone, we will assume this has been accepted and will be upheld for everyone covered under our Group #.`;

export default getEmail;
