import { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  TextField,
  Button,
  Card,
  CardContent,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Fuse from "fuse.js";
import _ from "lodash";

import { Loader } from "../Loader";
import CompanyRow from "./CompanyRow";
import Notes from "../Notes";
import CreateCompanyDrawer from "./CreateCompanyDrawer";

import { default as api } from "../../api/endpoints";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CompaniesList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [permissions, setPermissions] = useState({ canCreate: false });
  const [searchTerm, setSearchTerm] = useState();
  const [clientTypes, setClientTypes] = useState(["client"]);
  const [showNotes, setShowNotes] = useState();
  const [showCreate, setShowCreate] = useState();

  const handleNotesChange = (companyId, hasNotes) => {
    setData(
      data.map((item) =>
        item.id === companyId ? { ...item, has_notes: hasNotes } : item
      )
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClientTypes(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const loadCompanies = () => {
    setLoading(true);

    api.companies
      .list()
      .then(({ data }) => {
        setData(data.companies);
        setPermissions(data.permissions);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(loadCompanies, []);

  if (loading) {
    return <Loader />;
  }

  const rootPath = process.env.REACT_APP_MAIN_ROOT;

  const options = {
    keys: ["name"],
    threshold: 0.2,
    minMatchCharLength: 3,
  };

  const fuse = new Fuse(data, options);

  const typeFilteredData = data?.filter((d) => {
    if (d.deactivated_at) return clientTypes.includes("deactivated");

    const matchingTypes = [
      "example",
      "client",
      "prospect",
      "autopilot",
      "demo",
    ];

    if (matchingTypes.includes(d.account_type)) {
      return clientTypes.includes(d.account_type);
    } else {
      return clientTypes.includes("other");
    }
  });

  const filteredData = searchTerm
    ? fuse.search(searchTerm).map((s) => s.item)
    : permissions.selectAccountType
    ? typeFilteredData
    : data;

  const allClientTypes = [
    "example",
    "client",
    "prospect",
    "autopilot",
    "demo",
    "deactivated",
    "other",
  ];

  return (
    <Box>
      <Notes
        companyId={showNotes}
        handleClose={() => setShowNotes()}
        onNotesChange={handleNotesChange}
      />
      <CreateCompanyDrawer
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          setShowCreate(false);
          loadCompanies();
        }}
      />
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="flex-start" sx={{ mb: 2 }}>
            <Grid item>
              {permissions.canCreate ? (
                <Button
                  onClick={() => setShowCreate(true)}
                  target="_top"
                  color="primary"
                  startIcon={<AddIcon />}
                  variant="outlined"
                  sx={{
                    height: "100%",
                  }}
                >
                  Create Company
                </Button>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                sx={{ maxWidth: 500 }}
                id="companies-autocomplete"
                value={searchTerm}
                onChange={(e, newValue) => {
                  const val = _.get(newValue, "name", "");
                  setSearchTerm(val);
                }}
                options={data}
                getOptionLabel={(opt) =>
                  `${opt.name} (${opt.num_users} Users – ${
                    opt.account_type || "No Type"
                  }${opt.deactivated_at ? " – Deactivated" : ""})`
                }
                freeSolo
                autoHighlight
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search Companies"
                    placeholder="ACME"
                  />
                )}
              />
            </Grid>
            {permissions.selectAccountType && (
              <Grid item>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="types-multiple-checkbox-label">
                    Account Type
                  </InputLabel>
                  <Select
                    labelId="types-multiple-checkbox-label"
                    id="types-multiple-checkbox"
                    multiple
                    value={clientTypes}
                    onChange={handleChange}
                    input={<OutlinedInput label="Account Type" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {allClientTypes.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={clientTypes.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <TableContainer sx={{ height: "calc(100vh - 250px)" }}>
            <Table stickyHeader sx={{ maxHeight: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell sx={{ minWidth: 100 }}>Auto Emails</TableCell>
                  <TableCell>User Home Page</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((d) => (
                  <CompanyRow
                    key={d.id}
                    d={d}
                    rootPath={rootPath}
                    handleNotesClick={() => setShowNotes(d.id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CompaniesList;
