import { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Stack, Button } from "@mui/material";
import moment from "moment";

import DetailDrawer from "../DetailDrawer";
import CompanySelect from "./CompanySelect";
import api from "../../../api/endpoints";

const AddQuizConfirm = ({ handleClose, quiz, onAdd }) => {
  const today = moment();

  const [company, setCompany] = useState();
  const [date, setDate] = useState(today);

  return (
    <DetailDrawer
      open
      width={500}
      handleClose={handleClose}
      title={`Add Quiz ${quiz.name}`}
    >
      <Stack spacing={2}>
        <CompanySelect onSelect={(company) => setCompany(company)} />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Schedule Quiz For"
            inputFormat="MM/DD/YYYY"
            value={date}
            onChange={(value) => {
              setDate(value);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
        <Button
          disabled={!company}
          onClick={() => {
            api.quizzes
              .addToCompany({ quiz, company, date })
              .then(({ data }) => {
                onAdd(data.quiz);
              })
              .catch((error) => {
                window.alert(error);
              });
          }}
        >
          Save
        </Button>
      </Stack>
    </DetailDrawer>
  );
};

export default AddQuizConfirm;
