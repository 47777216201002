import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  InputLabel,
  Link,
} from "@mui/material";
import ImagePicker from "./ImagePicker";

const ImageEditor = ({ image, title, onChange, uploadPath }) => (
  <Card style={{ backgroundColor: "transparent" }}>
    {title && <CardHeader title={<InputLabel>{title}</InputLabel>} />}
    {image ? (
      <>
        <CardContent>
          <Link href={image.url} target="_blank" rel="noopener noreferrer">
            <img
              src={image.url}
              alt={image.alt || ""}
              style={{
                maxWidth: "100%",
                maxHeight: 250,
              }}
            />
          </Link>
        </CardContent>
        <CardActions>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              if (
                window.confirm(`Are you sure you want to remove this image?`)
              ) {
                onChange(null);
              }
            }}
          >
            Remove Image
          </Button>
        </CardActions>
      </>
    ) : (
      <ImagePicker
        path={uploadPath}
        onSelect={({ id, url }) => onChange({ id, url })}
      />
    )}
  </Card>
);

export default ImageEditor;
