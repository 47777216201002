import React, { useState } from "react";
import {
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import FilterSelect from "./FilterSelect";
import LoadingButton from "../LoadingButton";

const SPOUSES_OPTION = "spouses";
const EMPLOYEES_OPTION = "employees";

const Filters = ({
  onFilterChange,
  onExportClick,
  exporting,
  filterOptions: { quizzes, years, companyGroups, supportsSpouses },
}) => {
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCompanyGroups, setSelectedCompanyGroups] = useState([]);
  const [selectedSpouse, setSelectedSpouse] = useState([]);
  const [userType, setUserType] = useState("default");

  const spouseOptions = supportsSpouses
    ? [
        { id: SPOUSES_OPTION, name: "spouses" },
        { id: EMPLOYEES_OPTION, name: "employees" },
      ]
    : [];

  const handleQuizzesChange = (event) => {
    setSelectedQuizzes(event.target.value);
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
  };

  const handleCompanyGroupChange = (event) => {
    setSelectedCompanyGroups(event.target.value);
  };

  const handleSpouseChange = (event) => {
    setSelectedSpouse(event.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();

    onFilterChange({
      quizzes: selectedQuizzes,
      years: selectedYears,
      companyGroups: selectedCompanyGroups,
      userType,
      spouses: selectedSpouse.includes(SPOUSES_OPTION),
      employees: selectedSpouse.includes(EMPLOYEES_OPTION),
    });
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sx={{ display: years.length > 1 ? "block" : "none" }}>
          <FilterSelect
            sx={{ m: 1, width: 120 }}
            name="year"
            label="Year Filter"
            optionsToSelect={years}
            selected={selectedYears}
            onSelect={handleYearsChange}
          />
        </Grid>
        <Grid item>
          <FilterSelect
            sx={{ m: 1, width: 200 }}
            name="quiz"
            label="Quiz Filter"
            optionsToSelect={quizzes}
            selected={selectedQuizzes}
            onSelect={handleQuizzesChange}
          />
        </Grid>
        {companyGroups.length > 0 && (
          <Grid item>
            <FilterSelect
              sx={{ m: 1, width: 200 }}
              name="company-group"
              label="Group Filter"
              optionsToSelect={companyGroups.sort(function (a, b) {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              })}
              selected={selectedCompanyGroups}
              onSelect={handleCompanyGroupChange}
            />
          </Grid>
        )}
        <Grid
          item
          sx={{ display: spouseOptions.length > 0 ? "block" : "none" }}
        >
          <FilterSelect
            sx={{ m: 1, width: 200 }}
            name="spouses"
            label="Spouse Filter"
            optionsToSelect={spouseOptions}
            selected={selectedSpouse}
            onSelect={handleSpouseChange}
          />
        </Grid>

        <Grid item>
          <FormControl>
            <InputLabel id="user-type-select-label">
              User Type Filter
            </InputLabel>
            <Select
              sx={{ m: 1, width: 200 }}
              labelId="user-type-select-label"
              id="user-type-select"
              value={userType}
              label="User Type"
              onChange={(event) => setUserType(event.target.value)}
            >
              <MenuItem value="default">Default (All except excluded)</MenuItem>
              <MenuItem value="inactive">
                Inactive only (Non-excluded users who haven't played in 30 days)
              </MenuItem>
              <MenuItem value="excluded">
                Excluded (Users archived or not on enforced roster, as
                applicable)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="outlined" sx={{ m: 1 }} onClick={handleButtonClick}>
            Apply filters
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <LoadingButton
        fullWidth={false}
        sx={{ m: 1 }}
        onClick={onExportClick}
        loading={exporting}
        startIcon={<SaveIcon />}
      >
        Export to Excel
      </LoadingButton>
    </>
  );
};

export default Filters;
