import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import commaNumber from "comma-number";
import formatPercentage from "../formatPercentage";

const Scores = ({ averageScoreGameOne, averageScoreGameTwo }) => (
  <Card>
    <CardHeader title="Average Scores" />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Box>
            <Typography>Game One</Typography>
            <Typography variant="h5" align="center">
              {commaNumber(averageScoreGameOne) || "-"}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Typography>Game Two</Typography>
            <Typography variant="h5" align="center">
              {commaNumber(averageScoreGameTwo) || "-"}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Typography>% Improvement</Typography>
            <Typography variant="h5" align="center">
              {averageScoreGameTwo
                ? formatPercentage(
                    averageScoreGameTwo,
                    averageScoreGameOne,
                    true
                  )
                : "-"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Scores;
