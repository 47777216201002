import React from "react";
import { Stack, Typography, Slider, Box } from "@mui/material";

const SliderAnswer = ({
  feedbackText,
  startingValue,
  solution,
  min,
  max,
  unit,
}) => {
  function valuetext(value) {
    if (value === startingValue) return `Starting Value: ${startingValue}`;
    if (value === solution) return `Solution: ${solution}`;
  }

  function getLabel(value, unit, solution) {
    const solutionLabel = unit ? ` ${unit} (Answer)` : " (Answer)";

    const label = value === solution ? solutionLabel : "";

    return `${value}${label}`;
  }

  const marks = [min, solution, max].map((v) => ({
    value: v,
    label: getLabel(v, unit, solution),
  }));

  return (
    <Stack spacing={3}>
      <Box sx={{ pl: 5, pr: 5 }}>
        <Slider
          track={false}
          aria-labelledby="question-preview-slider"
          getAriaValueText={valuetext}
          disabled={true}
          defaultValue={[solution]}
          marks={marks}
          min={min}
          max={max}
        />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>Feedback:</Typography>
        <Typography>{feedbackText}</Typography>
      </Box>
    </Stack>
  );
};

export default SliderAnswer;
