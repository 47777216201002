import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Button,
} from "@mui/material";

import { useForm } from "react-hook-form";
import TargetEmails from "./TargetEmails";
import getEmail from "./getEmail";
import PlanAndGroupIDs from "./PlanAndGroupIDs";
import Hospitals from "./Hospitals";

import api from "../../../api/endpoints";

const StepContainer = ({ title, children }) => (
  <Card>
    <CardHeader title={title} />
    <Divider />
    <CardContent>{children}</CardContent>
  </Card>
);

const Consent = ({ onCreateEmail }) => {
  const [trackedEmailAddress, setTrackedEmailAddress] = useState();
  const [hospitals, setHospitals] = useState([]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      carrier: "",
      groupNumber: "",
      accountNumbers: [{ value: "" }],
      emails: [{ value: "" }],
    },
  });

  useEffect(() => {
    if (!trackedEmailAddress) {
      api.trackedEmails
        .getAddress()
        .then((res) => setTrackedEmailAddress(res.data.email));
    }
  }, [trackedEmailAddress]);

  const onSubmit = handleSubmit(
    ({ emails, accountNumbers, carrier, groupNumber }) => {
      const subject = "Financial Consent";

      const recipients = emails.map((email) => email.value).join();
      const formattedAccountNumbers = accountNumbers.map((id) => id.value);

      const formattedHospitals = hospitals.map(
        ({ name, vicinity }) => `${name} - ${vicinity}`
      );

      const unencodedBodyText = getEmail(
        carrier,
        formattedAccountNumbers,
        groupNumber,
        formattedHospitals
      );

      const emailBody = encodeURI(unencodedBodyText).replaceAll("?", "%3F");

      api.consentEmails
        .create({
          hospitals,
          accountNumbers: formattedAccountNumbers,
          recipients,
          carrier,
          groupNumber,
          emailBody,
        })
        .then(({ data }) => {
          const { consent_email } = data;

          const bcc = `${trackedEmailAddress}+${consent_email.id}@parse.quizzify.com,dev@quizzify.com`;

          const emailLink = `mailto:${recipients}?bcc=${bcc}&subject=${subject}&body=${emailBody}`;

          window.open(emailLink);
        })
        .catch((err) => window.alert(err));
    }
  );

  return (
    <form onSubmit={onSubmit}>
      <CardContent>
        <Stack spacing={2}>
          <StepContainer title="Company and Plan Information">
            <PlanAndGroupIDs
              register={register}
              control={control}
              errors={errors}
            />
          </StepContainer>
          <StepContainer title="Recipients to Emails">
            <TargetEmails
              control={control}
              register={register}
              errors={errors}
            />
          </StepContainer>
          <StepContainer title="Add Hospitals">
            <Hospitals hospitals={hospitals} setHospitals={setHospitals} />
          </StepContainer>

          <StepContainer title="Let's Get It Sent">
            <Typography sx={{ mb: 2 }}>
              Click below to open the email in your own email client so you can
              send it in your own name. This is important to make it legally
              binding (we'd love to send it for you otherwise). Note that we
              included a special BCC address for tracking so we can keep your
              records organized.
            </Typography>
            <Button type="submit" variant="contained">
              Open Email and Send It From Your Account
            </Button>
          </StepContainer>
        </Stack>
      </CardContent>
    </form>
  );
};

export default Consent;
