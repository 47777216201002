import { useState, useEffect } from "react";
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Container,
  TableHead,
} from "@mui/material";
import moment from "moment";
import { default as api } from "../api/endpoints";

const SummaryDashboard = () => {
  const [data, setData] = useState();

  useEffect(() => {
    api
      .summaryDashboard()
      .then((response) => setData(response.data))
      .catch((error) => window.alert(error));
  }, []);

  if (!data) {
    return <CircularProgress />;
  }

  console.log(data.latest_games);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">System Summary (Experimental)</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Global Activity"
              subheader="Logged In Users Only"
            />
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Total Users Who Completed 1+ Quizzes in Last Year
                    </TableCell>
                    <TableCell sx={{ fontSize: 40 }}>
                      {data.unique_users_last_year}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Total Users Who Completed 1+ Quizzes in Last Week
                    </TableCell>
                    <TableCell sx={{ fontSize: 40 }}>
                      {data.unique_users_last_week}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Annual Run Rate Based On Last Week</TableCell>
                    <TableCell sx={{ fontSize: 40 }}>
                      {data.unique_users_last_week * 52}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Last Week Increase / Decrease vs. the Average Week Over
                      The Last Year
                    </TableCell>
                    <TableCell sx={{ fontSize: 40 }}>
                      {Math.round(
                        100.0 *
                          (data.unique_users_last_week /
                            (data.unique_users_last_year / 52.0) -
                            1)
                      )}
                      %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Most recent quizzes completed" />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Quiz</TableCell>
                    <TableCell>User Email</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Times Completed</TableCell>
                    <TableCell>Completed At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.latest_games.map((g) => (
                    <TableRow key={g.id}>
                      <TableCell>{g.company}</TableCell>
                      <TableCell>{g.quiz}</TableCell>
                      <TableCell>{g.email}</TableCell>
                      <TableCell>{g.name}</TableCell>
                      <TableCell>{g.number}</TableCell>
                      <TableCell>{moment(g.completed_at).calendar()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SummaryDashboard;
