import axios from "axios";
import { curry } from "ramda";

const API_ENDPOINT = `${process.env.REACT_APP_MAIN_ROOT}${process.env.REACT_APP_API_ROOT_PATH}`;
const authToken = localStorage.getItem("authToken");

const authTokenHeader = authToken
  ? { Authorization: `Token ${authToken}` }
  : {};

const headers = {
  ...authTokenHeader,
};

const instance = axios.create({
  baseURL: API_ENDPOINT,
  headers,
});

// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Token ${token}` : "";
  return config;
});

const downloader = axios.create({
  baseURL: API_ENDPOINT,
  responseType: "blob",
  headers,
});

// Set the AUTH token for any request
downloader.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Token ${token}` : "";
  return config;
});

const download = (path) => downloader.get(path);

const setAuthToken = (authToken) => {
  localStorage.setItem("authToken", authToken);
};

const clearAuthToken = () => {
  localStorage.removeItem("authToken");
};

const get = curry((path, config) => instance.get(path, config));
const post = curry((path, config) => instance.post(path, config));
const patch = curry((path, config) => instance.patch(path, config));
const deleteRequest = curry((path, config) => instance.delete(path, config));
const postFormData = curry((path, formData) =>
  instance.post(path, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
);

const randomDelay = () => Math.random() * 1000;
const fake = (data, delay = randomDelay()) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve({ data }), delay);
  });

export {
  get,
  post,
  patch,
  deleteRequest,
  fake,
  download,
  setAuthToken,
  clearAuthToken,
  API_ENDPOINT,
  postFormData,
};
