const getComments = (questionReview) =>
  questionReview.history.filter((item) => item.type === "commented");

export const hasComments = (questionReview) =>
  getComments(questionReview).length > 0;

const getLastCommentStatus = (questionReview, currentUserId) => {
  const comments = getComments(questionReview);

  const lastComment = comments.length > 0 ? comments.slice(-1)[0] : null;

  if (!lastComment) return null;

  return lastComment.user?.id === currentUserId ? "yours" : "theirs";
};

export default getLastCommentStatus;
