import React from "react";
import { Grid, Card, CardHeader, Typography } from "@mui/material";
import _ from "lodash";
import ImageEditor from "../../ImageEditor";

const getImageByType = (images, usage, order) => {
  // Get the image for the given usage scenario
  let imageByUsage = images.find((i) => i.usage === usage);
  if (imageByUsage) return imageByUsage;

  // Some legacy images don't have a usage label, so return them by order
  const imageByOrder = _.get(images, `[${order}]`);
  if (imageByOrder && !imageByOrder.usage) return imageByOrder;

  return null;
};

const QuestionImagesEditor = ({ images, onAddImage, onRemoveImage }) => {
  const startImage = getImageByType(images, "questionStart", 0);
  const endImage = getImageByType(images, "questionEnd", 1);

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Card style={{ width: "100%", height: "100%" }}>
          <CardHeader
            title={<Typography variant="subtitle1">Start Image</Typography>}
            subheader={
              <Typography>
                If this is the only image, it appears throughout
              </Typography>
            }
          />
          <ImageEditor
            image={startImage}
            onChange={(values) => {
              if (!values) {
                onRemoveImage(startImage);
              } else {
                onAddImage({ ...values, usage: "questionStart" });
              }
            }}
          />
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card style={{ width: "100%", height: "100%" }}>
          <CardHeader
            title={<Typography variant="subtitle1">End Image</Typography>}
            subheader={
              <Typography>
                Image that appears only after the question is complete
              </Typography>
            }
          />
          <ImageEditor
            image={endImage}
            onChange={(values) => {
              if (!values) {
                onRemoveImage(endImage);
              } else {
                onAddImage({ ...values, usage: "questionEnd" });
              }
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default QuestionImagesEditor;
