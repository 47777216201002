import {
  Stack,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";

const Summary = ({ register, errors }) => (
  <Card>
    <CardHeader title="Tell Us About Your Quiz!" />
    <Divider />
    <CardContent>
      <Stack spacing={2}>
        <TextField
          label="Quiz Title"
          variant="outlined"
          {...register("name", {
            required: "You must name your quiz",
          })}
          aria-label={"Quiz Name"}
          fullWidth
          error={!!errors.name}
          placeholder="A Short Quiz Title"
          helperText={
            errors.name?.message || "Use 'Title Case' with capitalized letters"
          }
        />

        <TextField
          multiline
          label={"Describe this Quiz"}
          variant="outlined"
          {...register("description", {
            required: "You must describe your quiz",
          })}
          error={!!errors.description}
          helperText={errors.description?.message}
          fullWidth
        />
      </Stack>
    </CardContent>
  </Card>
);

export default Summary;
