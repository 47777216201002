import React from "react";
import { Typography, Divider, Stack } from "@mui/material";
import HistoryItem from "./HistoryItem";

const History = ({ history }) => (
  <Stack spacing={2}>
    <Typography variant="h6">Review History</Typography>
    <Divider />
    {history.map((item, index) => (
      <HistoryItem item={item} key={index} />
    ))}
  </Stack>
);

export default History;
