import React from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { useFieldArray, Controller } from "react-hook-form";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import RichTextField from "../Questions/RichTextField";

const Content = ({ control, register, name, errors }) => {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name,
  });

  const handleDeleteClick = (index) => () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      remove(index);
    }
  };

  const onDragEnd = ({
    source: { index: sourceIndex },
    destination: { index: destinationIndex },
  }) => {
    move(sourceIndex, destinationIndex);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list" style={{ display: "table" }}>
          {(provided) => (
            <Table size="small" ref={provided.innerRef}>
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>
                    <ImportExportIcon />
                  </TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <Draggable
                    draggableId={field.id}
                    key={field.id}
                    index={index}
                  >
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <TableCell {...provided.dragHandleProps}>
                          <DragHandleIcon />
                        </TableCell>
                        <TableCell sx={{ width: "95%" }}>
                          <Controller
                            control={control}
                            name={`[${name}].${index}.content`}
                            rules={{
                              required:
                                "Content cannot be blank. If you'd like to remove it please delete it.",
                            }}
                            render={({
                              field: { onChange, value, defaultValue },
                            }) => (
                              <RichTextField
                                fullWidth
                                multiline
                                onChange={onChange}
                                defaultValue={defaultValue}
                                value={value}
                                error={_.get(
                                  errors,
                                  `${name}.${index}.content`
                                )}
                                helperText={_.get(
                                  errors,
                                  `${name}.${index}.content.message`
                                )}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              type="button"
                              color="error"
                              onClick={handleDeleteClick(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        startIcon={<AddIcon />}
        type="button"
        onClick={() => append({ content: "", position: "", category: name })}
      >
        Add New Item
      </Button>
    </>
  );
};

export default Content;
