import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Modal,
  Box,
  Grid,
  IconButton,
  Button,
  Alert
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import sanitizeHtml from '../../utils/sanitizeHtml';

const Field = ({ label, children }) => (
  <React.Fragment>
    <Grid item xs={4}>
      <strong>
        {label}
      </strong>
    </Grid>
    <Grid item xs={8}>{children}</Grid>
  </React.Fragment>
)

const EmailsSample = ({ emails }) => {
  if (emails.allCount === emails.sample.length) {
    return (
      <div>
        { emails.sample.join(', ') }
      </div>
    );
  } else if (emails.allCount > emails.sample.length) {
    return (
      <div>
        { emails.sample.join(', ') } <em>(+{ emails.allCount - emails.sample.length } emails)</em>
      </div>
    );
  } else {
    return 'No matching';
  }
}

const EmailSummary = ({ open, summary, isSubmitting, onClose, onSubmit }) => {
  const { company, userSelector, quiz, subject, message, emails } = summary;

  if (!open) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(summary);
  }

  return (
    <Modal open={open}>
      <Box sx={{ width: 800, height: 600, margin: '50px auto', overflow: 'auto' }}>
        <Card>
          <CardHeader
            title="Summary" action={
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Field label="Company:">{ company.name }</Field>
              <Field label="User criteria:">{ userSelector.label }</Field>
              <Field label="Quiz:">{ quiz && quiz.name }</Field>
              <Field label="Email addresses:"><EmailsSample emails={emails} /></Field>
              <Field label="Subject:">{ subject }</Field>
              <Grid item xs="12">
                <strong>Message:</strong>
              </Grid>
              <Grid item dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>Send</Button>
            <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>Cancel</Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  )
}

export default EmailSummary;
