import React, { useState, useEffect } from "react";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Alert,
  Tooltip,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { default as api } from "../../api/endpoints";

const PermissionRow = ({
  permission,
  companyId,
  companyName,
  label,
  onClick,
}) => {
  const companyLabel = label ? label : `${companyName} (ID: ${companyId})`;

  return (
    <TableRow>
      <TableCell>{permission}</TableCell>
      <TableCell>{companyLabel}</TableCell>
      <TableCell>
        <Tooltip title="Revoke permissions" aria-label="revoke permissions">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const CurrentPermissions = ({ permissions, user, onPermissionChanged }) => {
  const [permissionToRevoke, setPermissionToRevoke] = useState(null);

  if (
    !permissions.isAdmin &&
    !permissions.isQuizzifyAdmin &&
    permissions.roles.length === 0
  ) {
    return null;
  }

  const resetPermissionToRevoke = () => {
    setPermissionToRevoke(null);
  };

  const handleConfirmClick = () => {
    api.users.revokePermission(user.id, permissionToRevoke).then(() => {
      resetPermissionToRevoke();
      onPermissionChanged();
    });
  };

  const renderRows = () => {
    const rows = [];

    if (permissions.isAdmin) {
      rows.push(
        <PermissionRow
          key="is-admin"
          permission="admin"
          companyId={permissions.companyId}
          companyName={permissions.companyName}
          onClick={() => setPermissionToRevoke({ oldRoleType: "is_admin" })}
        />
      );
    }

    if (permissions.isQuizzifyAdmin) {
      rows.push(
        <PermissionRow
          key="is-quizzify-admin"
          permission="quizzify admin"
          label="none"
          onClick={() =>
            setPermissionToRevoke({ oldRoleType: "is_quizzify_admin" })
          }
        />
      );
    }

    permissions.roles.forEach(({ roleType, companies }) => {
      if (companies.length === 0) {
        rows.push(
          <PermissionRow
            key={roleType}
            permission={roleType}
            label="none"
            onClick={() => setPermissionToRevoke({ roleType, companyId: null })}
          />
        );
      } else {
        companies.forEach((company) => {
          const key = `${roleType}-${company.id}`;

          rows.push(
            <PermissionRow
              key={key}
              permission={roleType}
              companyId={company.id}
              companyName={company.name}
              onClick={() =>
                setPermissionToRevoke({ roleType, companyId: company.id })
              }
            />
          );
        });
      }
    });

    return rows;
  };

  return (
    <React.Fragment>
      <Dialog open={!!permissionToRevoke}>
        <DialogContent>Revoke permission?</DialogContent>
        <DialogActions>
          <Button onClick={resetPermissionToRevoke}>Cancel</Button>
          <Button onClick={handleConfirmClick}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
};

const AddPermissions = ({
  user,
  availablePermissions,
  onPermissionChanged,
}) => {
  const [justAdded, setJustAdded] = useState(false);
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      roleType: "",
    },
  });

  const watchRoleType = watch("roleType");

  if (!availablePermissions) {
    return null;
  }

  const onSubmit = handleSubmit(({ roleType, company }) => {
    api.users
      .grantPermission(user.id, { roleType, companyId: company.id })
      .then((response) => {
        onPermissionChanged();
        setJustAdded(true);
        setTimeout(() => setJustAdded(false), 10000);
        reset();
      })
      .catch((error) => {
        window.alert(error);
      });
  });

  const selectedRole = !!watchRoleType
    ? availablePermissions.roles.find(({ role }) => role === watchRoleType)
    : null;
  const companyById = (companyId) =>
    availablePermissions.companies.find(({ id }) => id === companyId);
  const availableCompanies = !!selectedRole
    ? selectedRole.companyIds.map((companyId) => companyById(companyId))
    : [];
  const shouldShowCompanies =
    !!selectedRole && !selectedRole.canAssignWithoutCompany;

  return (
    <Paper style={{ marginTop: "2rem" }}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={justAdded}>
              <Alert severity="success" onClose={() => setJustAdded(false)}>
                Permission granted!
              </Alert>
            </Collapse>

            <Card>
              <CardHeader title="Add Permissions" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="roleType"
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                          <InputLabel id="role-type">Role</InputLabel>
                          <Select
                            id="role-type"
                            label="Role"
                            variant="outlined"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {availablePermissions.roles.map(({ role }) => (
                              <MenuItem value={role} key={role}>
                                {role}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {shouldShowCompanies && availableCompanies.length > 0 ? (
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            id="company"
                            variant="outlined"
                            value={value}
                            options={availableCompanies}
                            isOptionEqualToValue={(opt, value) =>
                              opt.id === value.id
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue);
                            }}
                            disabled={availableCompanies.length === 0}
                            getOptionLabel={(opt) =>
                              opt ? `(ID: ${opt.id}) - ${opt.name}` : ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Assign Company"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" type="submit">
                  Grant Permission
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const Permissions = ({ setCurrentView, user }) => {
  const [availablePermissions, setAvailablePermissions] = useState(null);
  const [currentPermissions, setCurrenPermissions] = useState(user.permissions);

  const refreshCurrentPermissions = (userId) => {
    api.users.currentPermissions(user.id).then((response) => {
      setCurrenPermissions(response.data.permissions);
    });
  };

  const onPermissionChanged = () => {
    refreshCurrentPermissions(user.id);
  };

  useEffect(() => {
    api.users.availablePermissions(user.id).then((response) => {
      setAvailablePermissions(response.data);
    });
  }, [user.id]);

  return (
    <React.Fragment>
      <IconButton
        onClick={() => setCurrentView({ view: "list", payload: {} })}
        size="small"
      >
        <ArrowBackIcon /> back
      </IconButton>

      <Typography variant="h5" gutterBottom>
        User Permissions - {user.email} ({user.id})
      </Typography>

      <CurrentPermissions
        user={user}
        permissions={currentPermissions}
        onPermissionChanged={onPermissionChanged}
      />

      <AddPermissions
        user={user}
        availablePermissions={availablePermissions}
        onPermissionChanged={onPermissionChanged}
      />
    </React.Fragment>
  );
};

export default Permissions;
