import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";

const Status = ({ review: { status } }) => {
  if (status === "approved_conditionally") {
    return (
      <Box sx={{ display: "flex", alignItems: "center " }}>
        <CheckCircleIcon color="success" />

        <Typography sx={{ pl: 1 }}>Reviewer approved with edits</Typography>
      </Box>
    );
  }

  if (status === "approved_conditionally") {
    return (
      <Box sx={{ display: "flex", alignItems: "center " }}>
        <CheckCircleIcon color="success" />

        <Typography sx={{ pl: 1 }}>Approved Pending Edits</Typography>
      </Box>
    );
  }

  if (status === "approved") {
    return (
      <Box sx={{ display: "flex", alignItems: "center " }}>
        <CheckCircleIcon color="success" />

        <Typography sx={{ pl: 1 }}>Approved</Typography>
      </Box>
    );
  }

  return status || "";
};

export default Status;
