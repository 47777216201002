import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import keyMirror from "keymirror";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  FormControl,
  InputLabel,
  Divider,
  Card,
  CardContent,
  CardActions,
  Autocomplete,
} from "@mui/material";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import reducer from "../../utils/curriedReducer";
import { searchState as initialState } from "../../stateModel";
import { mergeDeepLeft, pipe, prop } from "ramda";
import endpoints from "../../api/endpoints";
import { Loader } from "../Loader";
import { context as appContext } from "../../AppContext";
import {
  QuestionsStateProvider,
  context as questionsContext,
  actions as questionsActions,
} from "./QuestionsContext";
import QuestionEditor from "./QuestionEditor";
import QuestionsTable from "./QuestionsTable";
import PageContainer from "../PageContainer";

const actions = keyMirror({
  update: null,
});
const { update } = actions;

const Questions = () => {
  const [addQuestion, setAddQuestion] = useState(false);
  const [editQuestion, setEditQuestion] = useState();
  const [maxCharacters, setMaxCharacters] = useState(0);
  const [maxGradeLevel, setMaxGradeLevel] = useState(0);

  const [selectedMaxCharacters, setSelectedMaxCharacters] = useState(0);
  const [selectedMaxGradeLevel, setSelectedMaxGradeLevel] = useState(0);

  const { handleSubmit, register, setValue, control } = useForm({
    defaultValues: {
      term: "",
      module: "",
      questionFormat: "",
      harvardApproved: "",
      withImagesOnly: false,
    },
  });

  const [state, dispatch] = React.useReducer(
    reducer({
      [update]: pipe(prop("state"), mergeDeepLeft),
    }),
    initialState
  );

  const { loading, searched } = state;
  const { state: questionsState, dispatch: questionsDispatch } =
    useContext(questionsContext);
  const { dispatch: appDispatch } = useContext(appContext);

  const { questions } = questionsState;

  const questionsToDisplay = questions;

  // const questionsToDisplay = questions.filter(
  //   (q) => q.length <= selectedMaxCharacters && q.grade <= selectedMaxGradeLevel
  // );

  useEffect(() => {
    dispatch({ type: update, state: { loading: true } });

    endpoints.questionsSearch
      .get()
      .then((response) => {
        console.log(response);
        questionsDispatch({
          type: questionsActions.loadState,
          data: response.data,
        });
        dispatch({ type: update, state: { loading: false } });
      })
      .catch((err) => {
        const errorMsg = _.get(err, "response.statusText");
        window.alert(
          `An error occurred. Please contact support.\n\n${errorMsg}`
        );
        dispatch({ type: update, state: { loading: false } });
      });
  }, [questionsDispatch, appDispatch]);

  useEffect(() => {
    const characters = questions.map((q) => q.length);
    const grades = questions.map((q) => q.grade);

    const maxCharacters = Math.max(...characters);
    const maxGrades = Math.max(...grades);

    setMaxCharacters(maxCharacters);
    setMaxGradeLevel(maxGrades);
    setSelectedMaxCharacters(maxCharacters);
    setSelectedMaxGradeLevel(maxGrades);
  }, [questions]);

  const onSubmit = handleSubmit((values) => {
    dispatch({ type: update, state: { loading: true, searched: true } });

    endpoints.questionsSearch.post(values).then((response) => {
      questionsDispatch({
        type: questionsActions.loadState,
        data: response.data,
      });
      dispatch({ type: update, state: { loading: false } });
    });
  });

  if (editQuestion) {
    return (
      <PageContainer>
        <QuestionEditor
          companies={questionsState.companies}
          question={editQuestion}
          onCancelEditing={() => {
            window.scrollTo(0, 0);
            setEditQuestion(false);
          }}
          onFinishEditing={() => {
            onSubmit();
            window.scrollTo(0, 0);
            setEditQuestion(false);
          }}
          modules={questionsState.modules}
        />
      </PageContainer>
    );
  }

  if (addQuestion) {
    return (
      <PageContainer>
        <QuestionEditor
          companies={questionsState.companies}
          modules={questionsState.modules}
          onCancelEditing={() => {
            window.scrollTo(0, 0);
            setAddQuestion(false);
          }}
          onFinishEditing={(updatedQuestion) => {
            setAddQuestion(false);
            window.scrollTo(0, 0);
            if (updatedQuestion?.id) {
              setValue("term", updatedQuestion?.id);
              onSubmit();
            }
          }}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <form onSubmit={onSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ flex: 1 }}>
                <TextField
                  autoFocus
                  variant="outlined"
                  fullWidth
                  name="term"
                  label="Search Term"
                  {...register("term")}
                  placeholder="Search by ID or Question Text"
                  onChange={(e) => {
                    dispatch({
                      type: update,
                      state: { searched: false, term: e.target.value },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="module"
                  render={({
                    field: { onChange, value, defaultValue, ref },
                  }) => (
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                      <InputLabel id="module-label">Module</InputLabel>
                      <Select
                        inputRef={ref}
                        labelId="module-label"
                        defaultValue={defaultValue}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        label="Module"
                      >
                        <MenuItem value="">
                          <em>All Modules</em>
                        </MenuItem>
                        <MenuItem value="none">
                          <em>No Module Specified</em>
                        </MenuItem>
                        {questionsState.modules.map((m, i) => (
                          <MenuItem value={m} key={i}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="questionFormat"
                  render={({
                    field: { onChange, value, defaultValue, ref },
                  }) => (
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                      <InputLabel id="question-format">
                        Question Format
                      </InputLabel>
                      <Select
                        inputRef={ref}
                        labelId="question-format"
                        defaultValue={defaultValue}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        label="Question Format"
                      >
                        <MenuItem value="">
                          <em>Any</em>
                        </MenuItem>
                        {questionsState.questionFormats.map((m, i) => (
                          <MenuItem value={m} key={i}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {questionsState.companies && (
                <Grid item>
                  <Controller
                    control={control}
                    name="customCompany"
                    render={({ field: { onChange, value, ref } }) => (
                      <Autocomplete
                        style={{ minWidth: 200 }}
                        getOptionLabel={(option) =>
                          `${option.id} - ${option.name}`
                        }
                        options={questionsState.companies}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                        value={value}
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            label="Custom For"
                            placeholder="Type company name or ID"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item>
                <Controller
                  control={control}
                  name="harvardApproved"
                  render={({
                    field: { onChange, value, defaultValue, ref },
                  }) => (
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                      <InputLabel id="harvard-approved">
                        Harvard Approval Status
                      </InputLabel>
                      <Select
                        inputRef={ref}
                        labelId="harvard-approved"
                        defaultValue={defaultValue}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        label="Harvard Approval Status"
                      >
                        <MenuItem value="">
                          <em>Any</em>
                        </MenuItem>
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="withImagesOnly"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      label="Image Questions Only"
                      control={
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              disabled={loading}
              color="secondary"
              variant="contained"
              type="submit"
            >
              Search
            </Button>
            <Button
              type="button"
              variant="outlined"
              aria-label="add"
              onClick={setAddQuestion}
              startIcon={<AddIcon />}
            >
              New Question
            </Button>
            {/* <Box sx={{ marginLeft: "auto", mr: 5 }}>
              <Slider
                size="small"
                aria-label="Max Characters"
                valueLabelDisplay="on"
                value={selectedMaxCharacters}
                onChange={(e, v) => setSelectedMaxCharacters(v)}
                step={10}
                min={0}
                max={maxCharacters}
              />
              <Typography>Max Characters</Typography>
            </Box>
            <Box sx={{ ml: 5, mr: 5 }}>
              <Slider
                size="small"
                aria-label="Max Grade Level"
                valueLabelDisplay="on"
                value={selectedMaxGradeLevel}
                onChange={(e, v) => setSelectedMaxGradeLevel(v)}
                step={0.5}
                min={0}
                max={maxGradeLevel}
              />
              <Typography>Max Grade Level</Typography>
            </Box> */}
          </CardActions>
        </Card>
      </form>
      <Divider />
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {questionsToDisplay && questionsToDisplay.length > 0 ? (
            <QuestionsTable
              state={questionsState}
              questions={questionsToDisplay}
              refresh={onSubmit}
              setEditQuestion={setEditQuestion}
            />
          ) : (
            searched && <Typography>0 Questions Found</Typography>
          )}
        </Box>
      )}
    </PageContainer>
  );
};

const QuestionsWithProvider = () => (
  <QuestionsStateProvider>
    <Questions />
  </QuestionsStateProvider>
);

export default QuestionsWithProvider;
