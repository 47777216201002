import React from "react";
import moment from "moment";
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

const BaseQuestionRow = ({ question, onShowSimilarsClick }) => (
  <TableRow>
    <TableCell>{question.id}</TableCell>
    <TableCell>{question.prompt}</TableCell>
    <TableCell>
      <Typography variant="caption" title={question.lastUpdated}>
        {moment(question.lastUpdated).calendar()}
      </Typography>
    </TableCell>
    <TableCell>{question.gameQuestionsCount}</TableCell>
    <TableCell>{question.similars.length}</TableCell>
    <TableCell>
      <Tooltip title="Edit" aria-label="edit">
        <IconButton
          onClick={(e) => {
            onShowSimilarsClick(e, question);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  </TableRow>
);

export default BaseQuestionRow;
