import React, { useState } from "react";

import ConfirmationDialog from "./ConfirmationDialog";
import SelectionList from "./SelectionList";
import Accordion from "./Accordion";
import QuestionAutocomplete from "./QuestionAutocomplete";

const QuizRemoveDialog = ({ opened, question, quizzes, onAction, onToggle }) => {
  const [selectedQuizzes, setSelectedQuizzes] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleSelect = (selectedQuizzes) => {
    setSelectedQuizzes(selectedQuizzes);
  };

  const handleChange = (question) => {
    setSelectedQuestion(question)
  }

  const handleConfirm = ({ selectedQuizzes }) => {
    onAction({ selectedQuestion, selectedQuizzes });
  }

  const handleCancel = () => {
    setSelectedQuizzes(null);
    selectedQuestion(null);
  }

  return (
    <Accordion opened={opened} label="Swap question in quizzes" action="swap" onToggle={onToggle}>
      {
        (selectedQuizzes === null || selectedQuestion === null) ? (
          <>
            <QuestionAutocomplete question={question} onChange={handleChange} />
            <SelectionList
              quizzes={quizzes}
              buttonLabel={"Swap"}
              onSelect={handleSelect}
            />
          </>
        ) : (
          <ConfirmationDialog
            question={question}
            quizzes={selectedQuizzes}
            buttonLabel="Swap"
            confirmationText={`Do you really want to swap question ID ${question.id} with question ID ${selectedQuestion.id} in following ${selectedQuizzes.length} quizzes?`}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )
      }
    </Accordion>
  )
}

export default QuizRemoveDialog;
