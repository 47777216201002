import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Chip,
  Autocomplete,
  Typography,
  MenuItem,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import pluralize from "pluralize";

import endpoints from "../../api/endpoints";
const { htmlToText } = require("html-to-text");

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const QuestionsAutocomplete = ({
  errors,
  onChange,
  loading,
  setLoading,
  parentRef,
  value,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [term, setTerm] = useState();
  const [termSearched, setTermSearched] = useState();
  const [customOnly, setCustomOnly] = useState();
  const [getCustomQuestionsRan, setGetCustomQuestionsRan] = useState();

  const debouncedTerm = useDebounce(term, 750);

  const addOptions = useCallback(
    (newOptions) => {
      const combined = options;

      newOptions.forEach((option) => {
        if (!combined.find((opt) => opt.id === option.id)) {
          combined.push(option);
        }
      });

      setOptions(combined);
    },
    [options]
  );

  useEffect(() => {
    if (options.length === 0) {
      endpoints.quizManager.initialQuestions
        .get()
        .then((response) => {
          addOptions(response.data.questions);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    }
  }, [options.length, addOptions, setLoading]);

  useEffect(() => {
    if (value) {
      // Add options when for example a template is loaded
      addOptions(value);
    }
  }, [value, addOptions]);

  useEffect(() => {
    if (termSearched !== debouncedTerm && debouncedTerm) {
      setLoading(true);

      setTermSearched(debouncedTerm);
      endpoints.quizManager.questionSearch
        .post({
          term: debouncedTerm,
        })
        .then((response) => {
          addOptions(response.data.questions);

          if (response.data.autoSelect) {
            onChange(null, response.data.questions);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  }, [
    debouncedTerm,
    setLoading,
    termSearched,
    setTermSearched,
    term,
    addOptions,
    onChange,
  ]);

  useEffect(() => {
    // Get all the custom questions for the user
    if (getCustomQuestionsRan) {
      setLoading(true);
      setGetCustomQuestionsRan(true);
      endpoints.quizManager.questionSearch
        .post({
          custom_only: true,
        })
        .then((response) => {
          addOptions(response.data.questions);
          if (response.data.autoSelect) {
            onChange(null, response.data.questions);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  }, [
    addOptions,
    onChange,
    setLoading,
    getCustomQuestionsRan,
    setGetCustomQuestionsRan,
  ]);

  const autocompleteOptions = options
    .map((opt) => ({
      ...opt,
      label: `${opt.id} - ${opt.prompt}`,
    }))
    .filter((opt) => !customOnly || !!opt.custom_for);

  return (
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={customOnly}
            onChange={(e) => setCustomOnly(e.target.checked)}
          />
        }
        label="Search My Custom Questions Only"
      />

      <Autocomplete
        id="questions-autocomplete"
        {...rest}
        value={value}
        onChange={onChange}
        multiple
        onInputChange={(_, newValue) => {
          // This anticipates the debounced search, unless the term is the same
          // If it's clear don't indicate loading
          if (newValue && newValue !== termSearched) {
            setLoading(true);
          } else {
            setLoading(false);
          }

          setTerm(newValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const styleProps = option.custom_for
              ? { color: "warning" }
              : { color: "secondary" };

            return (
              <Chip
                label={option.id.toString()}
                {...getTagProps({ index })}
                {...styleProps}
              />
            );
          })
        }
        autoHighlight
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        openOnFocus
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          const customLabel = option.custom_for
            ? `(Custom: ${option.custom_for})`
            : "";

          return (
            <MenuItem {...props}>
              <div>
                <Typography variant="subtitle1">
                  {option.id}: {htmlToText(option.prompt)}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Typography variant="caption">
                    Used in {pluralize("quizzes", option.num_quizzes, true)}
                  </Typography>
                  <Typography color="error" variant="caption">
                    {customLabel}
                  </Typography>
                </Stack>
              </div>
            </MenuItem>
          );
        }}
        options={autocompleteOptions}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={parentRef}
            fullWidth
            label="Quiz Questions"
            variant="outlined"
            error={"questions" in errors}
            helperText={errors?.questions?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

export default QuestionsAutocomplete;
