import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardContent, Divider, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Loader } from "../Loader";
import ReportTable from "./ReportTable";
import Filters from "./Filters";
import api from "../../api/endpoints.js";

const companyGroupFrom = (companyGroups, companyGroupId) =>
  companyGroupId
    ? companyGroups.find(({ id }) => companyGroupId === id)?.name
    : null;

const Report = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [entries, setEntries] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    years: [],
    quizzes: [],
    companyGroups: [],
    supportsSpouses: false,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState({
    quizzes: [],
    years: [],
    companyGroups: [],
    spouses: false,
    employees: false,
    userType: "default",
  });
  const [showRoster, setShowRoster] = useState();
  const [lastRosterUpdateAt, setShowRosterUpdateAt] = useState();

  useEffect(() => {
    setLoading(true);
    api.reports
      .participation({ companyId, page, pageSize, filter })
      .then(({ data }) => {
        console.log(data);

        data.entries.forEach(
          (entry) =>
            (entry.companyGroup = companyGroupFrom(
              data.filter.company_groups,
              entry.company_group_id
            ))
        );

        setEntries(data.entries);
        setSelectedQuizzes(data.selected_quizzes);
        setFilterOptions({
          years: data.filter.years,
          quizzes: data.filter.quizzes,
          companyGroups: data.filter.company_groups,
          supportsSpouses: data.filter.supports_spouses,
        });
        setShowRoster(data.show_roster);
        setShowRosterUpdateAt(data.last_roster_update_at);
        setTotalCount(data.pagination.total_count);
        setLoading(false);
      });
  }, [companyId, page, pageSize, filter]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleExportClick = () => {
    setExporting(true);
    api.reports
      .exportParticipation({ companyId, filter })
      .then(() => {
        setExporting(false);
        setAlert({
          severity: "info",
          message:
            "Export started. We will email you the report once it's ready.",
        });
      })
      .catch((error) => {
        setAlert({ severity: "info", message: "Can't start exporting data." });
      });
  };

  return (
    <Card>
      <CardHeader title="Participation Report" />
      <Divider />
      <CardContent>
        {alert ? (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.message}
          </Alert>
        ) : null}
        <Filters
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
          onExportClick={handleExportClick}
          exporting={exporting}
        />

        {showRoster && lastRosterUpdateAt && filter?.userType == "default" ? (
          <Alert severity="info">
            Showing Only Users from Latest Roster Update on{" "}
            {moment(lastRosterUpdateAt).calendar()}. The Excel Export will also
            be limited to these values.
          </Alert>
        ) : null}

        {loading ? (
          <Loader />
        ) : (
          <ReportTable
            entries={entries}
            quizzes={selectedQuizzes}
            totalCount={totalCount}
            hasCompanyGroups={filterOptions.companyGroups.length > 0}
            supportsSpouses={filterOptions.supportsSpouses}
            hasEmployeeId={entries.some((entry) => entry.employee_id)}
            page={page}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            showRoster={showRoster}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Report;
