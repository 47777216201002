import React from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";

const payloadLabel = (selector) => {
  return selector === 'email' ? 'Emails separated by commas or in new lines' : 'Employee IDs separated by commas or in new lines'
}

const FormStep = ({ control, watch, register }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="selector"
          render={({ field: { onChange, value, ref } }) => (
            <FormControl fullWidth>
              <InputLabel id="selector">Employee selector</InputLabel>
              <Select
                inputRef={ref}
                id="selector"
                label="Employee selector"
                variant="outlined"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                fullWidth
              >
                <MenuItem value="email" key="email">
                  Email
                </MenuItem>
                <MenuItem value="employee_id" key="employee_id">
                  Employee ID
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="payload"
          label={payloadLabel(watch(['selector'])[0])}
          variant="outlined"
          aria-label={payloadLabel(watch(['selector'])[0])}
          rows={24}
          {...register("payload")}
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="shouldExcludeAll"
          render={({ field: { onChange, value, ref } }) => (
            <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label="Exclude from all-time data?" />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {
          !watch("shouldExcludeAll") ? (
            <Controller
              control={control}
              name="excludeAfter"
              render={({ field: { onChange, value, ref } }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDateTimePicker
                    label="Exclude from"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          ) : null
        }
      </Grid>
    </Grid>
  )
};

export default FormStep;
