import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import DetailDrawer from "../DetailDrawer";
import AddQuizConfirm from "./AddQuizConfirm";
import api from "../../../api/endpoints";

const AddQuiz = ({ handleClose, onAddQuiz }) => {
  const [data, setData] = useState();
  const [showAddQuiz, setShowAddQuiz] = useState();

  useEffect(() => {
    api.quizzes.alternates().then((res) => {
      setData(res.data.alternates);
    });
  }, []);

  const handleAddQuiz = (quiz) => {
    setShowAddQuiz(quiz);
  };

  if (showAddQuiz) {
    return (
      <AddQuizConfirm
        quiz={showAddQuiz}
        handleClose={() => setShowAddQuiz()}
        onAdd={onAddQuiz}
      />
    );
  }

  return (
    <DetailDrawer open handleClose={handleClose} title="Add Quiz">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Quiz Name</TableCell>
            <TableCell>Quiz Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((quiz) => (
            <TableRow key={quiz.id}>
              <TableCell>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddQuiz(quiz)}
                >
                  Add
                </Button>
              </TableCell>

              <TableCell>{quiz.name}</TableCell>
              <TableCell>{quiz.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DetailDrawer>
  );
};

export default AddQuiz;
