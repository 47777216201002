import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: {
      md: "2em 2em",
      sm: "1em 1em",
      xs: 0,
    },
    height: "calc(100% - 56px)",
    marginBottom: theme.spacing(1),
    maxWidth: 1750,
  },
  fullScreenContainer: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
  },
}));

const PageContainer = ({
  children,
  fullScreen,
  title,
  subtitle,
  style = {},
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.fullScreenContainer]: fullScreen,
      })}
      style={style}
    >
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="subtitle1" gutterBottom>
          {subtitle}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default PageContainer;
