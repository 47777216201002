import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import PageContainer from "../PageContainer";
import Navigation from "./Navigation";
import { Loader } from "../Loader";

const Layout = ({ isLoading, children }) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Email Manager" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Navigation />
                </Grid>
                <Grid item xs={12}>
                  { children }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  )
};

export default Layout;
