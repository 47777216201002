import React, { useEffect, useState } from "react";
import {
  IconButton,
  CardHeader,
  CardContent,
  Divider,
  Drawer,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import api from "../../api/endpoints";

const Profile = ({ profile, onClose }) => {
  const [quizzes, setQuizzes] = useState();

  useEffect(() => {
    if (profile) {
      api.users
        .profile({ id: profile.id })
        .then((res) => {
          setQuizzes(res.data.profile.history);
        })
        .catch((err) => window.alert(err));
    }
  }, [profile]);

  if (!profile) return null;

  return (
    <Drawer anchor="right" open={!!profile} onClose={onClose}>
      <CardHeader
        avatar={<AccountCircleIcon />}
        title="Profile"
        action={
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Typography>
          {profile.firstName} {profile.lastName}
        </Typography>
        <Typography>{profile.email}</Typography>
        <Typography>{profile.employeeId}</Typography>
        <Typography>{profile.companyName}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Typography sx={{ fontWeight: "bold" }}>Quiz History</Typography>
        {!quizzes && <CircularProgress size="large" />}

        {quizzes && quizzes.length > 0 ? (
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Round</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Completed?</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableHead>
            <TableBody>
              {quizzes.map((q) => (
                <TableRow key={q.id}>
                  <TableCell>{q.name}</TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {q.number}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {q.points_earned}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {q.complete ? <CheckIcon color="success" /> : ""}
                  </TableCell>
                  <TableCell>
                    {q.completed_at
                      ? moment(q.completed_at).calendar()
                      : moment(q.updated_at).calendar()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Activity</Typography>
        )}
      </CardContent>
    </Drawer>
  );
};

export default Profile;
