import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { Controller } from "react-hook-form";

import ControlledDatePicker from "../ControlledDatePicker";
import ControlledTimePicker from "../ControlledTimePicker";

const Time = ({ control, hasEndDate }) => (
  <Card>
    <CardHeader
      title="Set Quiz Times"
      subheader="By default, quizzes should start at 3:00 AM ET and end at 2:59 AM ET to make sure everyone in the US has the most time to play."
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledDatePicker
            name="startDate"
            label="Start Date"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTimePicker
            name="startTime"
            label="Start Time"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledDatePicker
            name="endDate"
            label="End Date"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTimePicker
            name="endTime"
            label="End Time"
            control={control}
          />
        </Grid>
      </Grid>
      <Accordion sx={{ marginTop: 2 }} expanded={false}>
        <AccordionSummary>
          <div>
            {!hasEndDate && (
              <Typography>
                Set an end date first to use this setting:
              </Typography>
            )}
            <Controller
              control={control}
              name="closedAfterEndTime"
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        inputRef={ref}
                        disabled={!hasEndDate}
                        color="primary"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                    label="Close Quiz After It Ends (Users Can't Play It Anymore)"
                  />
                );
              }}
            />
          </div>
        </AccordionSummary>
      </Accordion>
    </CardContent>
  </Card>
);

export default Time;
