import React from "react";
import { Controller } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Slider,
  TextField,
  Typography,
  Alert,
} from "@mui/material";

const getValueLabel = (value) => {
  if (value === 1000) return "1000+";

  return value;
};

const Employees = ({ control, companyName }) => (
  <Controller
    control={control}
    name={"numEmployees"}
    rules={{
      required: "Required",
    }}
    render={({ field: { onChange, value } }) => (
      <>
        <Card>
          <CardHeader
            title={`How Many Employees and Spouses Does ${companyName} Have?`}
            subheader="Input your total or move the slider to answer"
          />
          <Divider />
          <CardContent>
            <TextField
              inputProps={{ style: { textAlign: "center", fontSize: 30 } }}
              label="Number of Employees and Spouses"
              fullWidth
              autoFocus
              value={value}
              onChange={(event) => onChange(event.target.value)}
            />
            {value == 1000 && (
              <Alert severity="info">
                <Typography>
                  If it's over 1,000, please enter the number above
                </Typography>
              </Alert>
            )}
            {value <= 1000 && (
              <Slider
                defaultValue={50}
                value={value}
                onChange={(event, value) => onChange(value)}
                step={1}
                min={1}
                max={1000}
                valueLabelDisplay="off"
              />
            )}
          </CardContent>
        </Card>
      </>
    )}
  />
);

export default Employees;
