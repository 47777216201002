import React from "react";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";

const CompanyName = ({ register, errors }) => (
  <Card>
    <CardHeader title="What is the Company's Name?" />
    <Divider />
    <CardContent>
      <TextField
        autoFocus
        label="Company Name"
        variant="outlined"
        {...register("companyName", {
          required: "Required",
        })}
        aria-label={"Company Name"}
        fullWidth
        error={!!errors.companyName}
        helperText={errors.companyName?.message}
      />
    </CardContent>
  </Card>
);

export default CompanyName;
