import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuestionEditor from "../../Questions/QuestionEditor";
import api from "../../../api/endpoints";

const QuestionEditorInQuiz = ({ questionToEdit, onCancelEditing, ...rest }) => {
  const [questionToEditData, setQuestionToEditData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (questionToEdit) {
      setLoading(true);
      api.questionsSearch
        .searchById({
          id: questionToEdit.id,
        })
        .then((response) => {
          setQuestionToEditData(response.data.question);
          setLoading(false);
        })
        .catch((error) => {
          window.alert("Could not load question: ", error.message);
          setLoading(false);
        });
    }
  }, [questionToEdit]);

  if (loading) {
    return (
      <Card sx={{ minWidth: "100vw", height: "100%" }}>
        <CardHeader
          title={`Edit Question ${questionToEdit?.id}`}
          action={
            <IconButton onClick={onCancelEditing}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  return (
    <QuestionEditor
      onCancelEditing={onCancelEditing}
      {...rest}
      question={questionToEditData}
    />
  );
};

export default QuestionEditorInQuiz;
