import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import { Controller } from "react-hook-form";
import CompaniesAutocomplete from "../CompaniesAutocomplete";

const Companies = ({ allCompanies, control, editingMode }) => (
  <>
    {allCompanies && allCompanies.length ? (
      <Card>
        <CardHeader
          title="Companies"
          subheader="Which companies is this quiz for?"
        />
        <Divider />
        <CardContent>
          <Controller
            control={control}
            name="companies"
            rules={{
              required: editingMode
                ? false
                : "You must select at least one company",
            }}
            render={({
              field: { onChange, value, ref },
              formState: { errors },
            }) => (
              <CompaniesAutocomplete
                parentRef={ref}
                errors={errors}
                value={value}
                allCompanies={allCompanies}
                onChange={(_, value) => onChange(value)}
              />
            )}
          />
        </CardContent>
      </Card>
    ) : null}
  </>
);

export default Companies;
