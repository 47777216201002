import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";

import AppRouter from "./AppRouter";
import muiTheme from "./mui-theme";
import { AppStateProvider } from "./AppContext";

const theme = createTheme(muiTheme);

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppStateProvider>
          <AppRouter />
        </AppStateProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
