import { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
} from "@mui/material";
import moment from "moment";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import QuizDetail from "./QuizDetail";
import api from "../../../api/endpoints";
import AddQuiz from "./AddQuiz";

const Quizzes = () => {
  const [loading, setLoading] = useState(true);
  const [quizzes, setQuizzes] = useState();
  const [companies, setCompanies] = useState();
  const [selectedQuiz, setSelectedQuiz] = useState();
  const [showAddQuiz, setShowAddQuiz] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [completionsByQuiz, setCompletionsByQuiz] = useState();

  useEffect(() => {
    api.companies
      .list()
      .then(({ data }) => {
        setCompanies(data.companies);
      })
      .catch((error) => {
        window.alert(error);
      });

    api.quizzes
      .index()
      .then((res) => {
        const { quizzes } = res.data;
        setQuizzes(quizzes);
        setLoading(false);
      })
      .catch((err) => {
        window.alert(err);
        setLoading(false);
      });

    api.quizzes.completions().then((res) => {
      setCompletionsByQuiz(res.data.completions_by_quiz);
    });
  }, []);

  const filterQuizzes = (quizzes, companyId) => {
    if (!companyId) return quizzes;

    return quizzes.filter((quiz) => quiz.company_id === companyId);
  };

  const filteredQuizzes = filterQuizzes(quizzes, selectedCompany?.id);

  const quizzesBySection = filteredQuizzes?.reduce(
    (acc, cur) => {
      if (!cur.start_time) {
        acc.notScheduled.push(cur);
      } else if (moment(cur.start_time).isBefore(moment())) {
        acc.current.push(cur);
      } else {
        acc.upcoming.push(cur);
      }

      return acc;
    },
    {
      current: [],
      upcoming: [],
      notScheduled: [],
    }
  );

  const handleDeleteQuiz = (quiz) => {
    if (window.confirm(`Are you sure you want to delete ${quiz.name}`)) {
      api.quizzes
        .delete(quiz.id)
        .then((res) => {
          setQuizzes(quizzes.filter((quiz) => quiz.id !== res.data.deleted.id));
        })
        .catch((err) => {
          window.alert(err);
        });
    }
  };

  const QuizTable = ({ quizzes, label }) => {
    if (quizzes.length === 0) return null;

    return (
      <Table sx={{ backgroundColor: "white" }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>{label} QUIZZES</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Times Played</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {quizzes?.map((quiz) => (
            <TableRow
              key={quiz.id}
              hover
              selected={selectedQuiz?.id === quiz.id}
              onClick={() => setSelectedQuiz(quiz)}
            >
              <TableCell>{quiz.id}</TableCell>
              <TableCell>{quiz.name}</TableCell>
              <TableCell>
                {quiz.start_time
                  ? moment(quiz.start_time).calendar()
                  : "Unscheduled"}
              </TableCell>
              <TableCell>
                {
                  companies.find((company) => company.id === quiz.company_id)
                    ?.name
                }
              </TableCell>
              <TableCell>
                {completionsByQuiz ? completionsByQuiz[quiz.id] : "N/A"}
              </TableCell>
              <TableCell>
                <IconButton
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteQuiz(quiz);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      {showAddQuiz && (
        <AddQuiz
          handleClose={() => setShowAddQuiz(false)}
          onAddQuiz={(quiz) => {
            setQuizzes([quiz, ...quizzes]);
            setShowAddQuiz(false);
          }}
        />
      )}
      <Button
        startIcon={<AddIcon />}
        onClick={() => setShowAddQuiz(true)}
        variant="outlined"
        sx={{ mb: 2 }}
      >
        Add Quiz
      </Button>
      <Box sx={{ maxWidth: 250, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="company-select-label">Filter by Company</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            onChange={(event) => {
              setSelectedCompany(event.target.value);
            }}
            value={selectedCompany}
            label="Filter by Company"
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", width: "100%" }}>
        <Stack spacing={2}>
          <QuizTable
            quizzes={quizzesBySection.current.sort((a, b) => {
              if (a.start_time < b.start_time) {
                return 1;
              }
              if (a.start_time > b.start_time) {
                return -1;
              }
              return 0;
            })}
            label="CURRENT"
          />
          <QuizTable quizzes={quizzesBySection.upcoming} label="UPCOMING" />
          <QuizTable
            quizzes={quizzesBySection.notScheduled}
            label="UNSCHEDULED"
          />
        </Stack>

        {selectedQuiz && (
          <QuizDetail
            quiz={selectedQuiz}
            handleClose={() => setSelectedQuiz()}
          />
        )}
      </Box>
    </>
  );
};

export default Quizzes;
