import { CircularProgress, Button, Box, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const LoadingButton = ({
  loading,
  children,
  type = "button",
  size = "medium",
  variant = "contained",
  startIcon = <SaveIcon />,
  color = "secondary",
  disabled,
  onClick,
  sx = {},
  fullWidth = true,
}) => (
  <Button
    sx={sx}
    disabled={disabled || loading}
    type={type}
    startIcon={loading ? <CircularProgress size={20} /> : startIcon}
    variant={variant}
    color={color}
    size={size}
    onClick={onClick}
    fullWidth={fullWidth}
  >
    {children}
  </Button>
);

export default LoadingButton;
