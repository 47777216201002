import { useState, useEffect } from "react";
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import TemplateLoader from "../TemplateLoader";
import { default as api } from "../../../api/endpoints";

const Templates = ({
  templateSelected,
  setTemplateSelected,
  templates,
  setTemplates,
  setQuestionsValue,
}) => {
  const [fetchTemplatesRan, setFetchTemplatesRan] = useState();
  const [showQuestionsLoadingMessage, setShowQuestionsLoadingMessage] =
    useState();
  const [hideSection, setHideSection] = useState(false);

  useEffect(() => {
    if (!fetchTemplatesRan && !templates) {
      setFetchTemplatesRan(true);
      api.templates
        .get()
        .then((res) => {
          setTemplates(res.data?.templates);
        })
        .catch((error) => {
          setHideSection(true);
          console.log(error);
        });
    }
  }, [templates, fetchTemplatesRan, setTemplates]);

  const renderTemplates = () => {
    if (!templates) return <CircularProgress />;

    return (
      <>
        <TemplateLoader
          onSelect={(selection) => {
            setTemplateSelected(selection);
            setShowQuestionsLoadingMessage(true);

            api.questionsSearch
              .fromTemplate({
                id: selection.id,
              })
              .then((response) => {
                setQuestionsValue(response.data.questions);
                setShowQuestionsLoadingMessage(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowQuestionsLoadingMessage(false);
              });
          }}
          templateSelected={templateSelected}
          templates={templates}
        />
      </>
    );
  };

  if (hideSection) return null;

  return (
    <Card>
      <CardHeader
        title="Load Questions from Template"
        subheader="Selecting a template will override any questions selected so far"
      />
      <Divider />
      <CardContent>{renderTemplates()}</CardContent>
      {showQuestionsLoadingMessage && (
        <>
          <Divider />
          <CardContent>
            <Stack spacing={2} direction="row">
              <Typography>Updating Questions</Typography>
              <CircularProgress size={20} />
            </Stack>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default Templates;
