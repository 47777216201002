import { v4 as uuidv4 } from "uuid";

const NEW_ANSWER = "newAnswer";
const UPDATE_ANSWER = "updateAnswer";
const DELETE_ANSWER = "deleteAnswer";

export const initialState = [];
export const init = (answers) =>
  answers.map((answer) => ({ ...answer, isValid: validate(answer) }));

export const newAnswer = () => ({ type: NEW_ANSWER });
export const updateAnswer = (answer, field, value) => ({
  type: UPDATE_ANSWER,
  answer,
  field,
  value,
});
export const deleteAnswer = (answer) => ({
  type: DELETE_ANSWER,
  answer: answer,
});

const validate = ({ mainText, feedbackText }) =>
  mainText?.trim().length > 0 && feedbackText?.trim().length > 0;

const updateAndRevalidate = (answer, field, value) => {
  const newAnswer = { ...answer, [field]: value };
  const isValid = validate(newAnswer);

  return { ...newAnswer, isValid };
};

const reducer = (state, action) => {
  switch (action.type) {
    case NEW_ANSWER:
      return state.concat({
        id: uuidv4(),
        mainText: "",
        feedbackText: "",
        isCorrect: false,
        isValid: false,
      });
    case UPDATE_ANSWER:
      const { answer, field, value } = action;

      return state.map((a) =>
        a.id === answer.id ? updateAndRevalidate(a, field, value) : a
      );
    case DELETE_ANSWER:
      return state.filter((a) => a.id !== action.answer.id);
    default:
      return state;
  }
};

export default reducer;
