import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "en-US": {
        translation: {
          Quizzify: "Quizzify",
          Admin: "Admin",
          Language: "Language",
          Quizzes: "Quizzes",
          "Admin Home": "Admin Home",
          Email: "Email",
          "Not logged in": "Not logged in",
          "Create Quiz": "Create Quiz",
          "Create New Quiz": "Create New Quiz",
          "Question Manager": "Question Manager",
          "Quiz Manager": "Quiz Manager",
          "You must select at least one company":
            "You must select at least one company.",
          "Included Companies": "Included Companies",
          "Select Companies Eligible for Quiz":
            "Select Companies Eligible for Quiz",
          "Select a Go Live Date": "Select a Go Live Date",
          "Select an End Date": "Select an End Date",
          "Quiz Name": "Quiz Name",
          "You must name your quiz": "You must name your quiz.",
          "Describe this Quiz": "Describe this Quiz",
          "You must describe your quiz": "You must describe your quiz.",
          "Login Failed: Invalid Credentials":
            "Login Failed: Invalid Credentials.",
          "You must enter a valid email address":
            "You must enter a valid email address.",
          "You must enter an email": "You must enter an email.",
          "You must enter a password": "You must enter a password.",
          "Email Address": "Email Address",
          Password: "Password",
          Submitting: "Submitting...",
          "Login Failed": "Login Failed: Invalid Credentials",
        },
      },
      es: {
        translation: {
          Quizzify: "Quizzify",
          Admin: "Admin",
          Language: "Idioma",
          Quizzes: "Cuestionarios",
          "Admin Home": "Inicio del admin",
          Email: "Correo electrónico",
          "Not logged in": "Sin iniciar sesión",
          "Create Quiz": "Crear Cuestionario",
          "Create New Quiz": "Crear Nuevo Cuestionario",
          "Question Manager": "Gerente de Preguntas",
          "Quiz Manager": "Gerente de Cuestionarios",
          "You must select at least one company":
            "Debes seleccionar al menos una empresa.",
          "Included Companies": "Compañías incluidas",
          "Select Companies Eligible for Quiz":
            "Seleccione Empresas Elegibles Para Cuestionario",
          "Select a Go Live Date": "Seleccione Ir a la Fecha de Activación",
          "Select an End Date": "Selecciona Una Fecha de Finalización",
          "Quiz Name": "Nombre del Cuestionario",
          "You must name your quiz": "Debes nombrar tu cuestionario.",
          "Describe this Quiz": "Describe este Cuestionario.",
          "You must describe your quiz": "Debes describir tu cuestionario.",
          "Login Failed: Invalid Credentials":
            "Error de inicio de sesión: credenciales no válidas.",
          "You must enter a valid email address":
            "Debes ingresar una dirección de correo electrónico válida",
          "You must enter an email": "Debe ingresar un correo electrónico.",
          "You must enter a password": "Debes ingresar una contraseña.",
          "Email Address": "Dirección de Correo Electrónico",
          Password: "Contraseña",
          Submitting: "Sumisión...",
          "Login Failed": "Error de inicio de sesión: credenciales no válidas",
        },
      },
    },
  });

export default i18n;
