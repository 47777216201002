import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";

const Errors = ({ serverError }) => (
  <>
    {serverError && (
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Errors" />
          <Divider />
          <CardContent>
            {serverError.map((e) => (
              <div key={e.company}>
                <Typography color="error">{e.company}</Typography>
                <ul>
                  {e.errors.map((msg) => (
                    <li key={msg}>
                      <Typography color="error">{msg}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
    )}
  </>
);

export default Errors;
