import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Alert,
  IconButton
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import QuizRemoveDialog from "./SwapDrawer/QuizRemoveDialog";
import QuizSwapDialog from "./SwapDrawer/QuizSwapDialog";
import { default as api } from "../../../api/endpoints";

const SwapDrawer = ({ open, question, onClose }) => {
  const [activeSublist, setActiveSublist] = useState('swap');
  const [quizzes, setQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (!open) {
      return;
    }

    api.questionSwap.get({ id: question.id }).then(({ data }) => {
      setQuizzes(data.quizzes);
      setIsLoading(false);
    });
  }, [open, question]);

  if (!open) {
    return null;
  }

  const handleRemove = ({ selectedQuizzes }) => {
    setIsLoading(true);
    const quizIds = selectedQuizzes.map(({ id }) => id);

    api.questionSwap.remove({ id: question.id, quizIds }).then(
      () => {
        setAlert(`Question ID ${question.id} removed from ${quizIds.length} quizzes.`);
        return api.questionSwap.get({ id: question.id });
      }
    ).then(({ data }) => {
      setQuizzes(data.quizzes);
      setIsLoading(false);
    });
  }

  const handleSwap = ({ selectedQuestion, selectedQuizzes }) => {
    setIsLoading(true);
    const quizIds = selectedQuizzes.map(({ id }) => id);
    const replacementQuestionId = selectedQuestion.id;

    api.questionSwap.swap({ id: question.id, replacementQuestionId, quizIds }).then(
      () => {
        setAlert(`Question ID ${question.id} was swapped with ID ${replacementQuestionId} in ${quizIds.length} quizzes.`);
        return api.questionSwap.get({ id: question.id });
      }
    ).then(({ data }) => {
      setQuizzes(data.quizzes);
      setIsLoading(false);
    });
  }

  const handleAccordionToggle = (action) => {
    setActiveSublist(activeSublist !== action ? action : null);
  }

  return (
    <Drawer open={true} anchor="right" onClose={onClose}>
      <Box sx={{ width: 350 }}>
        { !isLoading ? (
          <List>
            <ListItem
              secondaryAction={
                <IconButton edge="end" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <ListItem>
              <ListItemText>
                <Typography sx={{ fontWeight: 'bold' }}>Question {question.id}:</Typography> {question.prompt}
              </ListItemText>
            </ListItem>
            {
              alert ? (
                <Alert severity="success" onClose={() => setAlert(null)}>
                  { alert }
                </Alert>
              ) : null
            }
            {
              quizzes.length > 0 ? (
                <>
                  <QuizSwapDialog opened={activeSublist === "swap"} question={question} quizzes={quizzes} onAction={handleSwap} onToggle={handleAccordionToggle} />
                  <QuizRemoveDialog opened={activeSublist === "remove"} question={question} quizzes={quizzes} onAction={handleRemove} onToggle={handleAccordionToggle} />
                </>
              ) : (
                <Alert severity="info">
                  Question has no related quizzes. Nothing to do here.
                </Alert>
              )
            }
          </List>
        ) : <CircularProgress sx={{ position: 'absolute', top: '50%', bottom: '50%', left: '50%', right: '50%' }} /> }
      </Box>
    </Drawer>
  )
}

export default SwapDrawer;
