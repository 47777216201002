import { TextField, Switch, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

import CompanySelect from "../CompanySelect";
import CompanyGroupSelect from "./CompanyGroupSelect";

const CommonUserForm = ({ register, errors, control, watch }) => (
  <>
    <TextField
      name="firstName"
      label="First name"
      variant="outlined"
      aria-label={"First name"}
      {...register("firstName")}
      error={!!errors.firstName}
      helperText={errors.firstName?.message}
      fullWidth
    />

    <TextField
      name="lastName"
      label="Last name"
      variant="outlined"
      aria-label={"Last name"}
      {...register("lastName")}
      error={!!errors.lastName}
      helperText={errors.lastName?.message}
      fullWidth
    />

    <TextField
      name="email"
      label="Email"
      variant="outlined"
      aria-label={"Email"}
      {...register("email")}
      error={!!errors.email}
      helperText={errors.email?.message}
      fullWidth
    />

    <TextField
      name="employeeId"
      label="Employee ID"
      variant="outlined"
      aria-label={"Employee id"}
      {...register("employeeId")}
      error={!!errors.employeeId}
      helperText={errors.employeeId?.message}
      fullWidth
    />

    <Controller
      control={control}
      name="company"
      render={({ field: { onChange, value } }) => (
        <CompanySelect onChange={onChange} value={value} />
      )}
    />

    <CompanyGroupSelect watch={watch} control={control} />

    <Controller
      control={control}
      name="subscribed"
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label="Subscribed to Emails"
        />
      )}
    />
  </>
);

export default CommonUserForm;
