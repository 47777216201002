import React from "react";
import { Grid, FormControl, TextField, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import RichTextField from "./RichTextField";

const inputProps = {
  step: 0.1,
};

const SliderAnswerEditor = ({ register, control, watch, errors }) => {
  const watchMin = watch("min");
  const watchMax = watch("max");
  const watchSolution = watch("solution");

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            inputProps={inputProps}
            fullWidth
            {...register("startingValue", {
              required: "Required",
              validate: {
                betweenMinAndMax: (value) =>
                  (parseFloat(value) >= parseFloat(watchMin) &&
                    parseFloat(value) <= parseFloat(watchMax)) ||
                  "Value must be between min and max",
              },
            })}
            type="number"
            variant="outlined"
            label="Starting Value"
            error={errors.startingValue}
            helperText={errors.startingValue?.message}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            inputProps={inputProps}
            fullWidth
            {...register("min", {
              required: "Required",
              validate: {
                belowMax: (value) =>
                  parseFloat(value) < parseFloat(watchMax) ||
                  "Value must be below max",
              },
            })}
            type="number"
            variant="outlined"
            label="Minimum Value"
            error={errors.min}
            helperText={errors.min?.message}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            inputProps={inputProps}
            fullWidth
            {...register("max", {
              required: "Required",
              validate: {
                aboveMin: (value) =>
                  parseFloat(value) > parseFloat(watchMin) ||
                  "Value must be above min",
              },
            })}
            type="number"
            variant="outlined"
            label="Maximum Value"
            error={errors.max}
            helperText={errors.max?.message}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            inputProps={inputProps}
            fullWidth
            {...register("solution", {
              required: "Required",
              validate: {
                betweenMinAndMax: (value) =>
                  (parseFloat(value) >= parseFloat(watchMin) &&
                    parseFloat(value) <= parseFloat(watchMax)) ||
                  "Value must be between min and max",
              },
            })}
            type="number"
            variant="outlined"
            label="Solution"
            error={errors.solution}
            helperText={errors.solution?.message}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            inputProps={{ ...inputProps, min: 0.0 }}
            fullWidth
            {...register("tolerance", {
              required: "Required",
              validate: {
                withinMinAndMax: (value) => {
                  const tolerance = parseFloat(value);
                  const solution = parseFloat(watchSolution);
                  const min = parseFloat(watchMin);
                  const max = parseFloat(watchMax);
                  const sliderRange = Math.abs(max - min);
                  const toleranceMinWithinRange = Math.max(
                    solution - tolerance,
                    min
                  );
                  const toleranceMaxWithinRange = Math.min(
                    solution + tolerance,
                    max
                  );
                  const toleranceRange = Math.abs(
                    toleranceMaxWithinRange - toleranceMinWithinRange
                  );

                  return (
                    toleranceRange / sliderRange <= 0.5 ||
                    "Tolerance must not exceed 50% of available range"
                  );
                },
              },
            })}
            type="number"
            variant="outlined"
            label="Tolerance"
            error={errors.tolerance}
            helperText={errors.tolerance?.message}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          inputProps={{ step: "any", min: 0.0 }}
          fullWidth
          {...register("interval")}
          type="number"
          min={0}
          variant="outlined"
          label="Interval"
          error={errors.interval}
          helperText={
            errors.interval?.message ||
            "The decimals you'd like the slider to use, for example: 0.01"
          }
        />
      </Grid>

      <Grid item>
        <Controller
          control={control}
          name="unit"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="units-autocomplete"
              value={value}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              onInputChange={(_, newValue) => {
                onChange(newValue);
              }}
              options={["$", "%"]}
              freeSolo
              filterSelectedOptions
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Units"
                  placeholder="$, %, etc."
                  helperText="For values like year, just leave it blank"
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name="feedbackText"
          required={true}
          render={({ field: { onChange, value, defaultValue, ref } }) => (
            <RichTextField
              label="Feedback Text (Shows Up Once Question is Complete)"
              onChange={onChange}
              defaultValue={defaultValue}
              value={value}
              error={errors.feedbackText}
              helperText={errors.feedbackText?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SliderAnswerEditor;
