import React from "react";
import {
  Grid,
  Alert
} from "@mui/material";
import UserTable from "./UserTable";

const ReviewStep = ({ finalResult }) => {
  let alert = null;

  if (finalResult.archived.length > 0) {
    alert = {
      severity: 'success',
      message: `${finalResult.archived.length} users were archived. `
    }
  } else {
    alert = {
      severity: 'error',
      message: "No users were archived."
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert severity={alert.severity}>
          { alert.message }
        </Alert>
      </Grid>
      <UserTable label="Archived" entries={finalResult.archived} />
      { finalResult.unauthorized.length > 0 ? <UserTable label="Unauthorized" entries={finalResult.unauthorized} /> : null}
    </Grid>
  )
};

export default ReviewStep;
