import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const FilterSelect = ({
  label,
  name,
  optionsToSelect,
  selected,
  sx,
  onSelect,
}) => (
  <FormControl sx={sx}>
    <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
    <Select
      labelId={`${name}-select-label`}
      id={`${name}-select`}
      multiple
      value={selected}
      onChange={onSelect}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) =>
        selected
          .map(
            (selectedOption) =>
              optionsToSelect.find(
                (optionToSelect) => optionToSelect.id === selectedOption
              ).name
          )
          .join(", ")
      }
    >
      {optionsToSelect.map((optionToSelect) => (
        <MenuItem key={optionToSelect.id} value={optionToSelect.id}>
          <Checkbox checked={selected.includes(optionToSelect.id)} />
          <ListItemText primary={optionToSelect.name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default FilterSelect;
