import {
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";

const TargetEmails = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  return (
    <Stack spacing={2} sx={{ width: "100%", alignItems: "flex-start" }}>
      <Typography variant="h6">Target Emails:</Typography>
      <Typography>
        (The administrators you want to send this consent to)
      </Typography>

      {fields.map((field, index) => (
        <div key={field.id} style={{ display: "flex", alignItems: "center" }}>
          <TextField
            key={field.id}
            label="Target Email"
            variant="outlined"
            fullWidth
            {...register(`emails.${index}.value`, {
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
            placeholder="administrator@hospital.com"
            error={Boolean(_.get(errors, `emails.${index}.value`))}
            helperText={_.get(errors, `emails.${index}.value.message`)}
          />
          <Tooltip title="Delete">
            <IconButton onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}

      <Button
        startIcon={<AddIcon />}
        type="button"
        onClick={() => {
          append({ value: "" });
        }}
      >
        Add A Target Email
      </Button>
    </Stack>
  );
};

export default TargetEmails;
