import { useState, useEffect } from "react";
import {
  Card,
  Divider,
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Stack,
  CircularProgress,
  CardActions,
  Tooltip,
  Chip,
} from "@mui/material";
import moment from "moment";
import api from "../../api/endpoints";

const addRedirectHomeLink = (url) => {
  let urlObj = new URL(url);

  urlObj.searchParams.set("redirect_home_url", window.location.href);

  let updatedUrl = urlObj.toString();

  console.log(updatedUrl);

  return updatedUrl;
};

const Lesson = ({ quiz }) => (
  <Grid item md={4} sm={6} xs={12} key={quiz.id}>
    <Card
      raised
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {quiz.banner_image && (
        <img
          width="100%"
          height="175"
          style={{ objectFit: "cover" }}
          src={quiz.banner_image.url}
          alt={quiz.banner_image.alt}
        />
      )}

      <CardContent sx={{ flex: 1 }}>
        <Stack spacing={1}>
          {quiz.audience && (
            <Chip
              key={quiz.audience.id}
              label={`For ${quiz.audience.name}`}
              color="warning"
            />
          )}
          <Typography variant="h6">{quiz.name}</Typography>
          <Typography>{quiz.description}</Typography>
          <Tooltip title="This is when most users get access. Admins get to play anytime">
            <Typography variant="caption">
              Starts: {moment(quiz.start_time).calendar()}
            </Typography>
          </Tooltip>
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          href={addRedirectHomeLink(quiz.play_quiz_url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Start Lesson
        </Button>
      </CardActions>
    </Card>
  </Grid>
);

const Detail = ({ quizzes }) => {
  if (!quizzes) return <CircularProgress />;

  const pinnedQuizzes = quizzes.filter((quiz) => quiz.pinned);
  const rest = quizzes.filter((quiz) => !quiz.pinned);

  return (
    <Stack spacing={2}>
      {quizzes.length === 0 ? (
        <Typography>No Lessons Yet</Typography>
      ) : (
        <Grid container spacing={4}>
          {pinnedQuizzes?.map((quiz) => (
            <Lesson key={quiz.id} quiz={quiz} />
          ))}
          {rest?.map((quiz) => (
            <Lesson key={quiz.id} quiz={quiz} />
          ))}
        </Grid>
      )}
    </Stack>
  );
};

const Lessons = () => {
  const [quizzes, setQuizzes] = useState();

  useEffect(() => {
    api.pilot.getQuizzes().then(({ data: { quizzes } }) => {
      setQuizzes(quizzes);
    });
  }, []);

  const title = quizzes
    ? `Your Health Literacy Lessons (${quizzes.length})`
    : "Loading Lessons";

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader title={title} />
      <Divider />
      <CardContent sx={{ flex: 1, overflowY: "auto" }}>
        <Detail quizzes={quizzes} />
      </CardContent>
    </Card>
  );
};

export default Lessons;
