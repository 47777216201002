import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import PageContainer from "./PageContainer";
import { Loader } from "./Loader";
import api from "../api/endpoints";

const Report = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    api.reports.togo
      .index({ companyId })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        window.alert(error);
        setLoading(false);
      });
  }, [companyId]);

  return (
    <PageContainer>
      <Card>
        <CardHeader
          title="Quizzify2Go Usage Report (Beta)"
          subheader="Users who have visited Quizzify2Go"
        />
        <Divider />
        <CardContent>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {data.emails.map((email) => (
                <Typography key={email} gutterBottom>
                  {email}
                </Typography>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Report;
