import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import Layout from "./Layout";
import {
  Breadcrumbs,
  Link as BreadcrumbLink,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Alert
} from "@mui/material";
import { Link } from "react-router-dom";
import { default as api } from "../../api/endpoints";

const formatTime = (time) => time && moment(time).format('MMMM Do YYYY, h:mm:ss a');

const DeliveryField = ({ label, value }) => (
  <>
    <Grid item xs={3} sx={{ fontWeight: "bold" }}>
      { label }
    </Grid>
    <Grid item xs={9}>
      { value }
    </Grid>
  </>
)

const EmailRecipientRow = ({ recipient: { email, status } }) => (
  <TableRow>
    <TableCell>{ email }</TableCell>
    <TableCell>{ status }</TableCell>
  </TableRow>
);

const DeliveryDetails = ({ apiData }) => {
  if (apiData === null) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{marginBottom: 4}}>
      <DeliveryField label="Type" value={apiData.email_type} />
      <DeliveryField label="Created at" value={formatTime(apiData.created_at)} />
      <DeliveryField label="Started at" value={formatTime(apiData.started_at)} />
      <DeliveryField label="Status" value={apiData.status} />
    </Grid>
  );
}

const EmailRecipientsTable = ({ recipients }) => {
  const ROWS_PER_PAGE = 100;
  const [page, setPage] = useState(0);

  const handleChangePage = (e, newPage) => setPage(newPage);

  if (!recipients) {
    return null;
  }

  const visibleRecipients = recipients.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE);

  if (visibleRecipients.length === 0) {
    return (
      <Alert severity="warning">
        No recipients.
      </Alert>
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              visibleRecipients.map((recipient) => <EmailRecipientRow recipient={recipient} key={recipient.email} />)
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[ROWS_PER_PAGE]}
          count={recipients.length}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </>
  );
};

const EmailDelivery = () => {
  const params = useParams();
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    api.emails.delivery({ companyId: params.companyId, deliveryId: params.id }).then(({ data }) => {
      setApiData(data);
    });
  }, [params]);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs xs={{ marginBottom: 2 }}>
            <BreadcrumbLink component={Link} to="/email-manager/deliveries">Back</BreadcrumbLink>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={`Delivery ID ${params.id}`} />
            <CardContent>
              <DeliveryDetails apiData={apiData} />

              <EmailRecipientsTable recipients={apiData?.recipients} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EmailDelivery;
