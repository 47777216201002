import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination
} from "@mui/material";
import PageContainer from "../PageContainer";
import BaseQuestionRow from "./BaseQuestionRow";
import SimilarQuestionsModal from "./Modal";
import Navigation from "../Questions/Navigation";
import { Loader } from "../Loader";

import { default as api } from "../../api/endpoints";

const SimilarQuestions = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isSelectedQuestionReloading, setIsSelectedQuestionReloading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10, total: 0 });

  const loadQuestions = (page = 1) => {
    setLoading(true);

    return api.similarQuestions.get({ page }).then(({ data }) => {
      setQuestions(data.questions);
      setPagination(data.pagination);
      if (selectedQuestion) {
        setSelectedQuestion(data.questions.find((question) => question.id === selectedQuestion.id));
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    loadQuestions();
  }, []);

  const handleShowSimilarsClick = (e, question) => {
    setSelectedQuestion(question);
  };

  const handleArchive = (id) => {
    setIsSelectedQuestionReloading(true);
    api.questions.archive(({ id })).then(() => {
      loadQuestions().then(() => setIsSelectedQuestionReloading(false));
    });
  }

  const handleDelete = (id) => {
    setIsSelectedQuestionReloading(true);
    api.questions.delete(({ id })).then(() => {
      loadQuestions().then(() => setIsSelectedQuestionReloading(false));
    });
  }

  const handleClose = () => setSelectedQuestion(null);

  const handlePageChange = (e, newPage) => loadQuestions(newPage + 1);

  return (
    <PageContainer>
      <Navigation />
      <SimilarQuestionsModal
        selectedQuestion={selectedQuestion}
        onArchive={handleArchive}
        onDelete={handleDelete}
        onClose={handleClose}
        isReloading={isSelectedQuestionReloading}
      />
      <Card>
        <CardContent>
          {
            loading ? <Loader /> : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow style={{ fontWeight: "bold" }}>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Last updated</TableCell>
                        <TableCell>Games no</TableCell>
                        <TableCell>Similars no</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        questions.map(
                          (question) => (
                            <BaseQuestionRow
                              question={question}
                              onShowSimilarsClick={handleShowSimilarsClick}
                              key={question.id}
                            />
                          )
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[pagination.perPage]}
                  component="div"
                  count={pagination.total}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page - 1}
                  onPageChange={handlePageChange}
                />
              </>
            )
          }
        </CardContent>
      </Card>
    </PageContainer>
  )
};

export default SimilarQuestions;
