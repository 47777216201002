import { useForm, useFieldArray } from "react-hook-form";
import {
  Stack,
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
} from "@mui/material";
import Prompt from "./Prompt";
import StandardAnswer from "./StandardAnswer";
import api from "../../../../../api/endpoints";

const ConditionalApprovalEditor = ({ question, history, onUpdate }) => {
  const conditionalApproval = history
    ?.filter((item) => item.type === "approved_conditionally")
    .pop();

  if (!conditionalApproval)
    return "Error in conditional approval, contact support";

  const approvedQuestion = conditionalApproval.approved_question;
  const questionAtLastReview = conditionalApproval.prior_question;

  const formattedApprovedQuestion = {
    ...approvedQuestion,
    answers: approvedQuestion.answers.map((answer) => ({
      ...answer,
      answerId: answer.id,
    })),
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      ...formattedApprovedQuestion,
      comment: null,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "answers",
  });

  const onSubmit = handleSubmit((values) => {
    const question_id = values.question_id;
    const changes = [];

    if (values.prompt !== question.prompt) {
      changes.push({
        type: "question",
        id: question_id,
        key: "prompt",
        value: values.prompt,
      });
    }

    values.answers.forEach((answer) => {
      const currentAnswer = question.answers.find((a) => a.id === answer.id);

      if (currentAnswer) {
        if (currentAnswer.mainText !== answer.name) {
          changes.push({
            type: "answer",
            id: currentAnswer.id,
            key: "name",
            value: answer.name,
          });
        }

        if (currentAnswer.feedbackText !== answer.explanation) {
          changes.push({
            type: "answer",
            id: currentAnswer.id,
            key: "explanation",
            value: answer.explanation,
          });
        }
      }
    });

    api.questionReview.actions
      .acceptEdits(question_id, changes)
      .then(({ data }) => {
        onUpdate(data.updates.id);
      })
      .catch(() => {
        window.alert(
          "Something went wrong. Please contact support and note the question involved."
        );
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <input {...register("id")} style={{ display: "none" }} />

      <Stack spacing={2} sx={{ pb: 10 }}>
        <Prompt
          control={control}
          current={question.prompt}
          approved={approvedQuestion.prompt}
          reviewed={questionAtLastReview.prompt}
          setValue={setValue}
        />

        {fields.map((field, index) => {
          const approvedAnswer = {
            ...field,
            id: field.answerId,
          };

          let currentAnswer = question.answers.find(
            (answer) => answer.id === approvedAnswer.id
          );

          if (currentAnswer) {
            currentAnswer = {
              ...currentAnswer,
              name: currentAnswer?.mainText,
              explanation: currentAnswer?.feedbackText,
            };
          }

          const priorAnswer = questionAtLastReview.answers.find(
            (answer) => answer.id === approvedAnswer.id
          );

          return (
            <StandardAnswer
              key={field.id}
              index={index}
              control={control}
              current={currentAnswer}
              approved={approvedAnswer}
              prior={priorAnswer}
            />
          );
        })}
      </Stack>

      <AppBar
        position="absolute"
        sx={{ top: "auto", bottom: 0, width: "100%", backgroundColor: "white" }}
      >
        <Toolbar>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
              endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              Approve With Edits
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </form>
  );
};

export default ConditionalApprovalEditor;
