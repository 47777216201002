import React, { useState } from "react";
import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Slider,
  Box,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Controller } from "react-hook-form";
import EmailIcon from "@mui/icons-material/Email";

const getValueLabel = (value) => `${value}%`;
const getParticipationComment = (value) => `${value}%`;

const ROI = ({ monthlyCost, emailBody }) => {
  const maxCost = monthlyCost >= 1000;

  return (
    <Card>
      <CardHeader
        title="Let's Talk Pricing!"
        subheader="It's a flat rate of $1 PEPM with a $500 minimum"
      />
      <Divider />
      {/* <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6">Monthly Usage Rate</Typography>
            <Typography variant="body1">
              (Number of Quizzes Completed Monthly as a % of Total Employees and
              Spouses)
            </Typography>
          </Box>

          <Controller
            control={control}
            name="participationRate"
            rules={{
              required: "Required",
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Typography variant="h5" textAlign="center">
                  {getValueLabel(value)}
                </Typography>
                <Slider
                  value={value}
                  onChange={(event, value) => onChange(value)}
                  step={1}
                  max={100}
                  valueLabelDisplay="off"
                />
              </>
            )}
          />
        </Stack>
      </CardContent> */}
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6" textAlign="center">
            Estimated Monthly Cost
          </Typography>

          <Typography variant="h4" textAlign="center">
            ${monthlyCost.toFixed(0)}
          </Typography>

          {monthlyCost < 500 && (
            <Alert severity="info">
              <AlertTitle>
                There's an upfront minimum of $500, but don't worry, this will
                credit future months.
              </AlertTitle>
            </Alert>
          )}
        </Stack>
      </CardContent>
      <Divider />

      {maxCost && (
        <>
          <CardContent>
            <Stack spacing={2}>
              <Alert severity="info">
                <AlertTitle>Wow, you've got a lot of people!</AlertTitle>

                <Button
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  href={`mailto:hello@quizzify.com?subject=Inquiry from AutoQuiz setup&body=${emailBody}`}
                >
                  Talk to us to get a volume discount
                </Button>
              </Alert>
            </Stack>
          </CardContent>
          <Divider />
        </>
      )}

      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6" textAlign="center">
            Estimated ROI
          </Typography>
          <Typography variant="h4" textAlign="center">
            5.3X
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ROI;
