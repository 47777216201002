import React from "react";
import { Button, Divider, Typography, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import Comment from "../../../RequesterDashboard/ReviewHistory/Comment";
import StatusBanner from "./StatusBanner";

const ReviewForm = ({
  status,
  onSubmit,
  wasApproved,
  setShowEditor,
  showEditor,
  questionFormat,
  onBackToList,
}) => {
  if (showEditor) return null;

  const approved = ["approved", "approved_conditionally"].includes(status);

  return (
    <form>
      <Stack spacing={2}>
        <Divider />
        <Comment onSubmit={onSubmit} />

        {wasApproved ? (
          <Stack spacing={2}>
            <Divider />
            <Typography>This question is approved!</Typography>
            <Button
              variant="outlined"
              onClick={onBackToList}
              sx={{ mb: 2 }}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
          </Stack>
        ) : (
          <>
            <Divider />

            {approved ? (
              <StatusBanner status={status} />
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DoneIcon />}
                  onClick={() => {
                    const action = {
                      label: "Approve",
                      handler: "approve",
                    };

                    onSubmit({ action });
                  }}
                >
                  Approve
                </Button>

                <Divider />

                <Button
                  startIcon={<EditIcon />}
                  variant="outlined"
                  onClick={() => setShowEditor(true)}
                  disabled={questionFormat === "slider"}
                >
                  Edit
                </Button>
                {questionFormat === "slider" && (
                  <Typography sx={{ mt: 2 }}>
                    Slider Editing Is Not Yet Available. For now please use
                    comments if updates are needed for approval.
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </form>
  );
};

export default ReviewForm;
