import { Stack, Button } from "@mui/material";
import Result from "./Result";

const Results = ({ results, onClose }) => (
  <Stack spacing={2}>
    {results.sent.length > 0 && <Result emails={results.sent} type="sent" />}
    {results.failed.length > 0 && (
      <Result emails={results.failed} type="failed" />
    )}
    {results.already_accepted.length > 0 && (
      <Result emails={results.already_accepted} type="already_accepted" />
    )}

    <Button variant="outlined" onClick={onClose} sx={{ mt: 2 }}>
      Close
    </Button>
  </Stack>
);

export default Results;
