import React from "react";
import { Controller } from "react-hook-form";
import {
  Autocomplete,
  TextField
} from "@mui/material";

const ControlledAutocomplete = ({ control, name, options, label, rules, error, helperText }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={options}
          onChange={(_, val) => onChange(val)}
          value={value}
          renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} />}
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
