import { useState } from "react";
import {
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import Html from "../Html";

import api from "../../api/endpoints";
import NoteForm from "./NoteForm";

const Note = ({
  id,
  body,
  legacy,
  user,
  updated_at,
  variant,
  todo,
  onDeleteNote,
  onSaveNote,
}) => {
  const [editNote, setEditNote] = useState();

  const getTitle = () => {
    if (user) return user.display_name;
    if (legacy) return "Legacy Note";
  };

  const getSubtitle = () => {
    if (updated_at)
      return (
        <Stack direction="row" spacing={1}>
          <Chip variant="outlined" label={moment(updated_at).calendar()} />
          <Chip variant="outlined" label={variant} />
          {todo && (
            <Chip color="error" variant="outlined" label="Needs Action" />
          )}
        </Stack>
      );
    if (legacy) return "This note was created using an older note system";
  };

  if (editNote) {
    return (
      <NoteForm
        handleClose={() => setEditNote(false)}
        note={{
          id,
          body,
          variant,
          todo,
        }}
        onSaveNote={onSaveNote}
      />
    );
  }

  return (
    <Card raised>
      <CardHeader
        title={<Typography gutterBottom>{getTitle()}</Typography>}
        subheader={getSubtitle()}
        action={
          <>
            <IconButton
              onClick={() => {
                setEditNote(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete: " + body)
                ) {
                  api.notes
                    .destroy(id)
                    .then(() => {
                      onDeleteNote(id);
                    })
                    .catch((err) => window.alert(err));
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        }
      />
      <Divider />
      <CardContent>
        <Typography>
          <Html>{body}</Html>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Note;
