import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  IconButton,
  Stack,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useForm, Controller } from "react-hook-form";

import RichTextField from "../Questions/RichTextField";
import LoadingButton from "../LoadingButton";
import api from "../../api/endpoints";

const NoteForm = ({ companyId, handleClose, onSaveNote, note }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState();

  const [variants, setVariants] = useState();

  useEffect(() => {
    api.notes.variants().then((res) => {
      setVariants(res?.data?.variants);
      setLoading(false);
    });
  }, []);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: note
      ? note
      : {
          body: "",
          todo: false,
          variant: "standard",
        },
  });

  const onSubmit = handleSubmit((values) => {
    setSaving(true);

    note
      ? api.notes
          .update({ ...values })
          .then((res) => {
            setSaving(false);
            reset();
            handleClose();
            onSaveNote(res.data.note, "edit");
          })
          .catch((err) => {
            window.alert(err);
            setSaving(false);
          })
      : api.notes
          .create({ ...values, company_id: companyId })
          .then((res) => {
            setSaving(false);
            reset();
            handleClose();
            onSaveNote(res.data.note, "create");
          })
          .catch((err) => {
            window.alert(err);
            setSaving(false);
          });
  });

  if (loading) return <CircularProgress />;

  return (
    <form onSubmit={onSubmit}>
      <Card raised>
        <CardHeader
          title={note ? "Edit Note" : "Create New Note"}
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <input name="id" {...register("id")} hidden />
            <Controller
              control={control}
              name="body"
              rules={{ required: "Cannot be blank" }}
              render={({ field: { onChange, value, defaultValue } }) => {
                return (
                  <RichTextField
                    label="Body"
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                    error={!!errors.body}
                    helperText={errors.body?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="variant"
              render={({ field: { onChange, value, defaultValue, ref } }) => (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="variant-label">Type</InputLabel>
                  <Select
                    inputRef={ref}
                    labelId="variant-label"
                    defaultValue={defaultValue}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    label="Variant"
                  >
                    {variants?.map((variant) => (
                      <MenuItem key={variant} value={variant}>
                        {variant}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="todo"
              render={({ field: { onChange, value, ref } }) => (
                <FormControlLabel
                  label="Is a Todo (Requires Followup)"
                  control={
                    <Checkbox
                      inputRef={ref}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                />
              )}
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton loading={saving} type="submit">
            Save
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default NoteForm;
