import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Link,
  Divider,
  CircularProgress,
} from "@mui/material";
import Editor from "./Editor";
import Prompt from "./Prompt";
import AnswerItem from "./AnswerItem";
import SliderAnswer from "./SliderAnswer";

const Question = ({
  question,
  showEditor,
  setShowEditor,
  handleEditSubmit,
  history,
}) => {
  const lastConditionalApproval = history
    ?.filter((item) => item.type === "approved_conditionally")
    .pop();

  const lastConditionalApprovalEdits =
    lastConditionalApproval?.approved_question;

  if (showEditor) {
    return (
      <Editor
        question={question}
        onClose={() => setShowEditor(false)}
        handleEditSubmit={handleEditSubmit}
      />
    );
  }

  if (!question) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Prompt currentQuestion={question} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {question.format === "slider" ? (
            <>
              <Typography variant="h6">Slider Answer</Typography>

              <SliderAnswer {...question} />
            </>
          ) : (
            <>
              <Typography variant="h6">Answers</Typography>
              {question?.answers?.map((answer) => (
                <AnswerItem
                  key={answer.id}
                  answer={answer}
                  lastApproved={lastConditionalApprovalEdits}
                />
              ))}
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {question.learnMoreUrl && (
          <>
            <Typography variant="h6">Learn More Link:</Typography>
            <Link
              href={question.learnMoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {question.learnMoreUrl}
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Question;
