import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Tooltip,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  TablePagination,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
  CircularProgress,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import BallotIcon from "@mui/icons-material/Ballot";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UploadIcon from "@mui/icons-material/Upload";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";

import ActionDialog from "./ActionDialog";
import Profile from "./Profile";
import UsersUpload from "./UsersUpload";
import { default as api } from "../../api/endpoints";
import Alert from "../Alert";

const permissionsSummary = (permissions) => {
  const roles = [];
  if (permissions.isAdmin) {
    roles.push("admin");
  }
  if (permissions.isQuizzifyAdmin) {
    roles.push("quizzify admin");
  }

  permissions.roles.forEach(({ roleType }) => roles.push(roleType));

  return roles.join(", ");
};

const UserRow = ({
  user,
  onEditClick,
  onPermissionsClick,
  onArchiveClick,
  onUnarchiveClick,
  onDeleteClick,
  setProfile,
}) => {
  const {
    id,
    firstName,
    lastName,
    email,
    employeeId,
    companyId,
    companyName,
    permissions,
    createdAt,
    canEdit,
    canEditPermissions,
    canArchive,
    canUnarchive,
    canDelete,
  } = user;
  const fullName = [firstName, lastName].join(" ");

  const actionButtons = [
    <Tooltip title="Profile" aria-label="profile" key="profile">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setProfile(user);
        }}
      >
        <AccountCircleIcon />
      </IconButton>
    </Tooltip>,
  ];

  if (canEdit) {
    actionButtons.push(
      <Tooltip title="Edit" aria-label="edit" key="edit">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEditClick(user);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (canEditPermissions) {
    actionButtons.push(
      <Tooltip
        title="Manage permissions"
        aria-label="manage permissions"
        key="permissions"
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onPermissionsClick(user);
          }}
        >
          <BallotIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (canArchive) {
    actionButtons.push(
      <Tooltip title="Archive user" aria-label="archive user" key="archive">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onArchiveClick(user);
          }}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (canUnarchive) {
    actionButtons.push(
      <Tooltip
        title="Unarchive user"
        aria-label="unarchive user"
        key="unarchive"
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onUnarchiveClick(user);
          }}
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (canDelete) {
    actionButtons.push(
      <Tooltip title="Delete user" aria-label="delete user" key="delete">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick(user);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{fullName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{employeeId}</TableCell>
      <TableCell>
        {companyName} ({companyId})
      </TableCell>
      <TableCell>{permissionsSummary(permissions)}</TableCell>
      <TableCell>{createdAt ? moment(createdAt).calendar() : ""}</TableCell>
      <TableCell>{actionButtons}</TableCell>
    </TableRow>
  );
};

const AddUserButton = ({ capabilities, onClick }) => {
  if (capabilities.indexOf("add_user") === -1) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="outlined"
        onClick={onClick}
      >
        New User
      </Button>
    </>
  );
};

const AddUsersButton = ({ capabilities, onClick }) => {
  if (capabilities.indexOf("add_user") === -1) {
    return null;
  }

  return (
    <Button
      startIcon={<UploadIcon />}
      color="primary"
      variant="outlined"
      onClick={onClick}
    >
      Upload User Roster
    </Button>
  );
};

const SearchBar = ({ onSearchSubmitted }) => {
  const [companyOptions, setCompanyOptions] = useState(null);

  useEffect(() => {
    api.users.availableCompanies().then(({ data }) => {
      setCompanyOptions(
        data.companies.map(({ id, name }) => ({ id, label: name }))
      );
    });
  }, []);

  const { register, handleSubmit, setValue, control } = useForm();

  const onSubmit = handleSubmit((values) => {
    const { query, companyId } = values;

    onSearchSubmitted({ query, companyId });
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid
          item
          style={{
            visibility: companyOptions?.length > 1 ? "visible" : "hidden",
          }}
        >
          <Controller
            control={control}
            name="companyId"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={companyOptions}
                size="small"
                sx={{ width: 200 }}
                onChange={(_, newValue) => {
                  newValue ? onChange(newValue.id) : onChange("");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Company" />
                )}
              />
            )}
          />
        </Grid>

        <Grid item>
          <FormControl>
            <OutlinedInput
              name="query"
              size="small"
              placeholder="type email or last name"
              {...register("query")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setValue("query", "")} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" endIcon={<SearchIcon />} type="submit">
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const List = ({ setCurrentView }) => {
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [capabilities, setCapabilities] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalCount: 1,
    perPage: 25,
  });
  const [searchQuery, setSearchQuery] = useState({ query: "", companyId: "" });
  const [currentAction, setCurrentAction] = useState(null);
  const [showSidebar, setShowSidebar] = useState();
  const [alert, setAlert] = useState();

  const fetchSearchResults = ({ currentPage, perPage, searchQuery }) => {
    setLoading(true);
    api.users
      .list({
        page: currentPage + 1,
        perPage: perPage,
        searchQuery: searchQuery,
      })
      .then((response) => {
        const {
          data: {
            users,
            capabilities,
            pagination: { currentPage, perPage, totalCount },
          },
        } = response;

        setUsers(users);
        setCapabilities(capabilities);
        setPagination({ currentPage: currentPage - 1, perPage, totalCount });
        setLoading(false);
      })
      .catch((err) => window.alert(err));
  };

  useEffect(() => {
    fetchSearchResults({
      currentPage: pagination.currentPage,
      perPage: pagination.perPage,
      searchQuery,
    });
  }, [pagination.currentPage, pagination.perPage, searchQuery]);

  const handleEditClick = (user) =>
    setCurrentView({ view: "edit", payload: { user } });
  const handleNewClick = () => setCurrentView({ view: "new" });
  const handleUploadClick = () => setShowSidebar("upload");
  const handlePermissionsClick = (user) =>
    setCurrentView({ view: "permissions", payload: { user } });
  const handleArchiveClick = (user) => {
    setCurrentAction({ user, type: "archive" });
  };
  const handleUnarchiveClick = (user) => {
    setCurrentAction({ user, type: "unarchive" });
  };
  const handleDeleteClick = (user) => {
    setCurrentAction({ user, type: "softDelete" });
  };
  const handleActionClose = (shouldRefresh) => {
    setCurrentAction(null);

    if (shouldRefresh) {
      fetchSearchResults({
        currentPage: pagination.currentPage,
        perPage: pagination.perPage,
        searchQuery,
      });
    }
  };
  const onRowsPerPageChange = (e) => {
    setPagination({ ...pagination, currentPage: 0, perPage: e.target.value });
  };
  const onPageChange = (e, currentPage) => {
    setPagination({ ...pagination, currentPage });
  };
  const onSearchSubmitted = ({ query, companyId }) => {
    setSearchQuery({ query, companyId });
  };

  return (
    <div>
      <Alert handleClose={() => setAlert()} message={alert} />
      {showSidebar === "upload" ? (
        <UsersUpload
          handleClose={() => setShowSidebar()}
          onUpload={(usersCreatedCount) => {
            setAlert(`${usersCreatedCount} users created`);
            setShowSidebar(false);

            fetchSearchResults({
              currentPage: pagination.currentPage,
              perPage: pagination.perPage,
              searchQuery,
            });
          }}
        />
      ) : null}
      <Profile profile={profile} onClose={() => setProfile()} />

      <ActionDialog action={currentAction} onClose={handleActionClose} />
      <Card>
        <CardHeader title="User Manager" />
        <Divider />
        <CardContent>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <Stack spacing={2} direction="row">
                <AddUserButton
                  capabilities={capabilities}
                  onClick={handleNewClick}
                />
                <AddUsersButton
                  capabilities={capabilities}
                  onClick={handleUploadClick}
                />
                {capabilities.find(
                  (capability) => capability === "mass_archive"
                ) ? (
                  <Link
                    to="/user-manager/archive"
                    component={Button}
                    startIcon={<ArchiveIcon />}
                    variant="outlined"
                  >
                    Archive users
                  </Link>
                ) : null}
              </Stack>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={8}>
                        <SearchBar onSearchSubmitted={onSearchSubmitted} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>User name</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Employee ID</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Permissions</TableCell>
                      <TableCell>Date Registered</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <UserRow
                        user={user}
                        key={user.id}
                        onEditClick={handleEditClick}
                        onPermissionsClick={handlePermissionsClick}
                        onArchiveClick={handleArchiveClick}
                        onUnarchiveClick={handleUnarchiveClick}
                        onDeleteClick={handleDeleteClick}
                        setProfile={setProfile}
                      />
                    ))}
                    <TableRow>
                      <TablePagination
                        page={pagination.currentPage}
                        rowsPerPage={pagination.perPage}
                        count={pagination.totalCount}
                        onRowsPerPageChange={onRowsPerPageChange}
                        onPageChange={onPageChange}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default List;
