import React, { useState } from "react";
import SelectionList from "./SelectionList";
import ConfirmationDialog from "./ConfirmationDialog";
import Accordion from "./Accordion";

const QuizRemoveDialog = ({ opened, question, quizzes, onAction, onToggle }) => {
  const [selectedQuizzes, setSelectedQuizzes] = useState(null);

  const handleSelect = (selectedQuizzes) => {
    setSelectedQuizzes(selectedQuizzes);
  };

  const handleCancel = () => {
    setSelectedQuizzes(null);
  }

  return (
    <Accordion opened={opened} label="Remove from quizzes" action="remove" onToggle={onToggle}>
      {
        selectedQuizzes === null ? (
          <SelectionList
            quizzes={quizzes}
            buttonLabel={"Remove"}
            onSelect={handleSelect}
          />
        ) : (
          <ConfirmationDialog
            question={question}
            quizzes={selectedQuizzes}
            buttonLabel="Remove"
            confirmationText={`Do you really want to remove question ID ${question.id} from following ${selectedQuizzes.length} quizzes?`}
            onConfirm={onAction}
            onCancel={handleCancel}
          />
        )
      }
    </Accordion>
  )
}

export default QuizRemoveDialog;
