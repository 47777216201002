import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Stack,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import _ from "lodash";
import RichTextField from "../../Questions/RichTextField";

const Editor = ({ question, onClose, handleEditSubmit }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, isDirty, errors },
  } = useForm({
    defaultValues: {
      ...question,
      comment: null,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "answers",
  });

  const onSubmit = handleSubmit(handleEditSubmit);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography variant="h6">Question Editor</Typography>
        <Divider />

        <Controller
          control={control}
          name="prompt"
          render={({ field: { onChange, value, defaultValue } }) => (
            <RichTextField
              label="Prompt"
              onChange={onChange}
              defaultValue={defaultValue}
              value={value}
              error={errors?.prompt}
              helperText={errors?.prompt && "Required"}
              preventAddingHtml
            />
          )}
        />

        <TextField {...register("id")} sx={{ display: "none" }} />

        <TextField
          label="Learn More Link"
          variant="outlined"
          {...register("learnMoreUrl")}
          aria-label="Learn More Link"
          fullWidth={true}
          multiline
        />

        <Stack spacing={2}>
          {fields.map((field, index) => (
            <Card key={field.id}>
              <CardHeader
                title={
                  <Typography variant="subtitle1">
                    Answer {index + 1}
                  </Typography>
                }
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    {...register(`answers.${index}.id`)}
                    sx={{ display: "none" }}
                  />

                  <Controller
                    control={control}
                    name={`answers.${index}.mainText`}
                    render={({
                      field: { onChange, value, defaultValue, ref },
                    }) => (
                      <RichTextField
                        label="Header"
                        onChange={onChange}
                        defaultValue={defaultValue}
                        value={value}
                        error={Boolean(
                          _.get(errors, `answers.[${index}].mainText`)
                        )}
                        helperText={
                          Boolean(
                            _.get(errors, `answers.[${index}].mainText`)
                          ) && "Required"
                        }
                        preventAddingHtml
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`answers.${index}.feedbackText`}
                    render={({
                      field: { onChange, value, defaultValue, ref },
                    }) => (
                      <RichTextField
                        label="Feedback / Explanation"
                        onChange={onChange}
                        defaultValue={defaultValue}
                        value={value}
                        error={Boolean(
                          _.get(errors, `answers.[${index}].feedbackText`)
                        )}
                        helperText={
                          Boolean(
                            _.get(errors, `answers.[${index}].feedbackText`)
                          )
                            ? "Required"
                            : "Answer must remain correct or incorrect as originally specified"
                        }
                        preventAddingHtml
                      />
                    )}
                  />
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>

        <TextField
          label="Reviewer Comment (Optional)"
          variant="outlined"
          {...register("comment")}
          aria-label="Reviewer Comment"
          fullWidth={true}
          multiline
          placeholder="Approved with changes to clarify XYZ"
        />
      </Stack>

      <AppBar
        position="absolute"
        sx={{ top: "auto", bottom: 0, width: "100%", backgroundColor: "white" }}
      >
        <Toolbar>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting || !isDirty}
              endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              Approve With Edits{!isDirty && "(No Changes Made Yet)"}
            </Button>
            <Button
              onClick={onClose}
              startIcon={<CancelIcon />}
              variant="outlined"
              type="button"
            >
              Exit Editor Without Saving
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </form>
  );
};

export default Editor;
