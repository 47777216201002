import React, { useEffect, useRef } from "react";

const Preview = ({ link }) => {
  const iframe = useRef();

  useEffect(() => {
    // @TODO maybe this isn't needed
    if (iframe.current.src.indexOf("timestamp") > -1) {
      // Check if we still have a timestamp in the URL from a previous refresh
      iframe.current.src = iframe.current.src.replace(
        /timestamp=[^&]+/,
        "timestamp=" + Date.now()
      ); // And if so, replace it instead of appending so the URL doesn't grow too long.
    } else {
      // Else we will append the timestamp
      iframe.current.src +=
        (iframe.current.src.indexOf("?") > -1 ? "&" : "?") +
        "timestamp=" +
        Date.now(); // If the URL contains a ?, append &timestamp=...; otherwise, append ?timestamp=...
    }
  }, []);

  return (
    <iframe
      ref={iframe}
      title="Quiz Preview"
      src={link}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 750,
        border: "none",
        display: "block",
      }}
    />
  );
};

export default Preview;
