import { useFieldArray } from "react-hook-form";
import { TextField, Stack, Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const SourcesEditor = ({ control, register }) => {
  const { fields, append } = useFieldArray({
    control,
    name: "sources",
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2} sx={{ width: "100%", alignItems: "flex-start" }}>
        <Typography variant="h6">Sources:</Typography>

        {fields.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={2}>
            <TextField
              label="Source Name"
              variant="outlined"
              {...register(`sources.${index}.title`)}
              placeholder="NY Times, Washington Post, Mayo Clinic, etc."
            />
            <TextField
              label="url"
              variant="outlined"
              {...register(`sources.${index}.url`, {
                pattern:
                  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
              })}
            />
            {/* <Button type="button" onClick={() => remove(index)} color="error">
                Delete
              </Button> */}
          </Stack>
        ))}

        <Button
          startIcon={<AddIcon />}
          type="button"
          onClick={() => append("")}
        >
          Add Source
        </Button>
      </Stack>
    </Box>
  );
};

export default SourcesEditor;
