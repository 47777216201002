import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import getLastCommentStatus, { hasComments } from "./getLastCommentStatus";

const ProcessButton = ({ questionReview, currentUserId, onClickProcess }) => {
  const lastCommentStatus = getLastCommentStatus(questionReview, currentUserId);

  let { status } = questionReview;

  if (status === "requested" && hasComments(questionReview)) {
    let color = "grey";
    let text = "Revisit Your Last Comment";

    if (lastCommentStatus === "theirs") {
      color = "warning";
      text = "Review Comments";
    }

    return (
      <Button
        color={color}
        variant="outlined"
        startIcon={<EditIcon />}
        onClick={onClickProcess}
      >
        {text}
      </Button>
    );
  }

  if (status === "requested") {
    return (
      <Button
        variant="outlined"
        startIcon={<TouchAppIcon />}
        onClick={onClickProcess}
      >
        Process
      </Button>
    );
  }

  return (
    <Button
      color="grey"
      variant="outlined"
      startIcon={<EditIcon />}
      onClick={onClickProcess}
    >
      Revisit
    </Button>
  );
};

export default ProcessButton;
