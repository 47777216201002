import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";

const sortCategories = (categories) => {
  const keyed = categories.reduce((acc, cur) => {
    if (acc[cur]) {
      acc[cur] += 1;
    } else {
      acc[cur] = 1;
    }

    return acc;
  }, {});

  return Object.keys(keyed)
    .map((key) => ({
      name: key,
      number: parseFloat(keyed[key]),
    }))
    .sort((a, b) => b.number - a.number);
};

const QuestionsOverview = ({ numQuestions, categories }) => {
  const sortedCategories = sortCategories(categories);

  return (
    <Card>
      <CardHeader title="Questions" />
      <CardContent>
        <Typography variant="h4" align="center" gutterBottom>
          {numQuestions}
        </Typography>
        <Box mt={5}>
          <Typography variant="subtitle2" gutterBottom>
            Question Categories
          </Typography>
          <Table>
            <TableBody>
              {sortedCategories.map((category) => (
                <TableRow key={category.name}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuestionsOverview;
