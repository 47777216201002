import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  // Button,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import sanitizeHtml from "../../utils/sanitizeHtml";

// import SaveAltIcon from "@mui/icons-material/SaveAlt";
// import moment from "moment";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: "calc(100vh - 212px)",
  },
}));

// const packageData = (data) =>
//   data
//     .map((e) =>
//       e
//         .map((content) =>
//           typeof content === "string" && content.includes(",")
//             ? `"${content}"`
//             : content
//         )
//         .join(",")
//     )
//     .join("\n");

// const packageCsv = (data, id) => {
//   const csv = packageData(data);

//   const hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = `quizzify-quiz-${id}-user-data-${moment().format(
//     "YYYY-MM-DD"
//   )}.csv`;
//   hiddenElement.click();
// };

const formatPercentage = (num) => {
  if (isNaN(num)) return "";

  return Number(num).toLocaleString(undefined, {
    style: "percent",
  });
};

const QuestionDetails = ({ data, description, id }) => {
  const classes = useStyles();

  const headers = [
    "Question Prompt",
    "Format",
    "Total Answers",
    "Total Correct Answers",
    "Total Wrong Answers",
    "User Average Points",
    "Average Wrong Answers Per User",
    "Times Completed",
    "% Perfect",
    "% One Wrong",
    "% Two Wrong",
    "% Three Wrong",
    "% Four Wrong",
  ];

  return (
    <Paper>
      <Box p={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          {/* <Grid item>
            <Button
              startIcon={<SaveAltIcon />}
              onClick={() => {
                const csvData = [headers].concat(
                  data.map((d) => [
                    d.employee_id,
                    d.email,
                    d.first_name,
                    d.last_name,
                    d.gamesCompleted,
                    d.gameOne,
                    d.gameTwo,
                    d.otherGames,
                  ])
                );

                packageCsv(csvData, id);
              }}
            >
              Download Data
            </Button>
          </Grid> */}
          <Grid item>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.prompt}>
                <TableCell dangerouslySetInnerHTML={{ __html: sanitizeHtml(row.prompt) }} />
                <TableCell>{row.format}</TableCell>
                <TableCell>{row.numAnswers}</TableCell>
                <TableCell>{row.totalCorrect}</TableCell>
                <TableCell>{row.totalWrong}</TableCell>
                <TableCell>{row.averagePoints}</TableCell>
                <TableCell>{row.averageWrongAnswersPerUser}</TableCell>
                <TableCell>{row.timesCompleted}</TableCell>
                <TableCell>
                  {formatPercentage(
                    row.resultsDistribution.perfect / row.timesCompleted
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    row.resultsDistribution.oneWrong / row.timesCompleted
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    row.resultsDistribution.twoWrong / row.timesCompleted
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    row.resultsDistribution.threeWrong / row.timesCompleted
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    row.resultsDistribution.fourWrong / row.timesCompleted
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default QuestionDetails;
