import {
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Divider,
  Box,
  CardHeader,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller } from "react-hook-form";

const Settings = ({ register, control, isPublic }) => (
  <Card>
    <CardHeader title="Settings" />
    <Divider />
    <CardContent>
      <Accordion expanded={false}>
        <AccordionSummary>
          <Controller
            control={control}
            name="eligible"
            render={({ field: { onChange, value, ref } }) => (
              <FormControlLabel
                control={
                  <Switch
                    inputRef={ref}
                    color="primary"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label={
                  <div>
                    <Typography>Eligible for Incentive or Program </Typography>
                    <Typography variant="caption">
                      (Otherwise it's a 'bonus' quiz)
                    </Typography>
                  </div>
                }
              />
            )}
          />
        </AccordionSummary>
      </Accordion>

      <Accordion expanded={isPublic}>
        <AccordionSummary style={{ cursor: "default" }}>
          <Controller
            control={control}
            name="isPublic"
            render={({ field: { onChange, value, ref } }) => (
              <FormControlLabel
                control={
                  <Switch
                    inputRef={ref}
                    color="primary"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label={
                  <div>
                    <Typography>Is Public</Typography>
                    <Typography variant="caption">
                      (Anyone can access it without logging in, but reporting is
                      much more basic)
                    </Typography>
                  </div>
                }
              />
            )}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <TextField
            name="publicResultEmailRecipients"
            label="Comma Separated Emails to Receive Contact Form Submission (If Applicable)"
            variant="outlined"
            aria-label="Comma Separated Emails to Receive Contact Form Submission (If Applicable)"
            placeholder="email@example.com, otherEmail@example.com"
            fullWidth={true}
            {...register("publicResultEmailRecipients")}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography>Customize Quiz Start Email</Typography>
            <Typography variant="caption">
              (This applies only to automated emails)
            </Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="emailSubject"
                  label="Email Subject"
                  variant="outlined"
                  aria-label="Email Subject"
                  fullWidth={true}
                  {...register("emailSubject")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  name="emailIntro"
                  label="Email Intro (First Paragraph of Email Body)"
                  variant="outlined"
                  aria-label="Email Intro"
                  fullWidth={true}
                  {...register("emailIntro")}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </CardContent>
  </Card>
);

export default Settings;
