import DOMPurify from "dompurify";

const sanitizeHtml = (text, options) => {
  if (options?.formatLinks) {
    DOMPurify.addHook("afterSanitizeAttributes", function (node) {
      // set all elements owning target to target=_blank
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
      }
      if (node.nodeName === "P") {
        node.setAttribute("style", "margin:0");
      }
    });
  }

  return DOMPurify.sanitize(text);
};

export default sanitizeHtml;
