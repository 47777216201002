export const initialState = {
  pageTitle: "Admin",
  authenticated: null,
};

export const searchState = {
  loading: false,
  searched: false,
  term: "",
  module: "",
  withImagesOnly: false,
  harvardApproved: false,
};

export const dashboardState = {};
export const questionsState = {
  questions: [],
  modules: [],
  questionFormats: [],
};
export const quizzesState = {};
export const emailState = {};
