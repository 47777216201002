const formatPercentage = (num, den, change) => {
  if (!den) return "-";

  const factor = change ? 100 : 0;
  const percent = parseFloat((num * 100) / den - factor).toFixed(1);

  return `${percent}%`;
};

export default formatPercentage;
