import React, { useState } from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Tooltip,
  Grid,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DownloadIcon from "@mui/icons-material/Download";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import FileDownload from "js-file-download";
import endpoints from "../../api/endpoints";

const ConfirmDelete = ({ open, onConfirmClick, onCancelClick }) => (
  <Dialog open={open}>
    <DialogTitle onClose={onCancelClick}>Confirm deletion</DialogTitle>
    <Divider />
    <DialogContent>Do you want to remove the section?</DialogContent>
    <DialogActions>
      <Button onClick={onConfirmClick} color="primary" variant="outlined">
        Confirm
      </Button>
      <Button onClick={onCancelClick} color="primary" variant="contained">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

const Row = ({
  index,
  id,
  title,
  subtitle,
  walletCard,
  questions,
  onDeleteClick,
  onEditClick,
  sources,
  needsAction,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Draggable
      draggableId={id.toString()}
      key={`doctor-crib-${id}`}
      index={index}
    >
      {(provided) => (
        <React.Fragment>
          <TableRow
            key={id.toString()}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <TableCell {...provided.dragHandleProps}>
              <DragHandleIcon />
            </TableCell>
            <TableCell>
              {needsAction ? <ReportProblemIcon color="error" /> : null}
            </TableCell>
            <TableCell>{title}</TableCell>
            <TableCell>{subtitle}</TableCell>

            <TableCell>{walletCard?.walletCardUrl}</TableCell>
            <TableCell>{questions.length}</TableCell>
            <TableCell>{sources.length}</TableCell>

            <TableCell>
              <Tooltip title="Edit">
                <IconButton type="button" onClick={() => onEditClick(id)}>
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton type="button" onClick={() => onDeleteClick(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography variant="h6" gutterBottom component="div">
                  Questions
                </Typography>

                <Table size="small">
                  <TableBody>
                    {questions.map(({ content }, index) => (
                      <TableRow key={index}>
                        <TableCell>{content}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </Draggable>
  );
};

const DoctorCribsList = ({
  doctorCribs: doctorCribsInitial,
  onNewClick,
  onEditClick,
  onDeleteSuccess,
  onReorder,
  loading,
}) => {
  const [toRemoveId, setToRemoveId] = useState(null);
  const [showNeedsActionOnly, setShowNeedsActionOnly] = useState(false);

  const doctorCribs = doctorCribsInitial.filter(
    (crib) => !showNeedsActionOnly || crib.needsAction
  );

  const handleDelectClick = (id) => setToRemoveId(id);
  const handleDeleteConfirm = () => {
    endpoints.doctorCribs
      .delete({ id: toRemoveId })
      .then(() => {
        onDeleteSuccess(toRemoveId);
        setToRemoveId(null);
      })
      .catch(() => {});
  };
  const handleEditClick = (id) => onEditClick(id);
  const handleDeleteCancel = () => setToRemoveId(null);

  const onDragEnd = ({
    draggableId,
    source: { index: sourceIndex },
    destination: { index: destinationIndex },
  }) => {
    const doctorCrib = doctorCribs[sourceIndex];
    doctorCribs.splice(sourceIndex, 1);
    doctorCribs.splice(destinationIndex, 0, doctorCrib);

    endpoints.doctorCribs
      .reorder({ ordered: doctorCribs.map(({ id }) => id) })
      .then((res) => {
        onReorder(res.data.doctorCribs);
      });
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Doctor Cribs Manager
      </Typography>
      <Divider />

      <Box sx={{ mt: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={onNewClick}
            >
              New Crib
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              onClick={() => {
                endpoints.doctorCribs.download().then((response) => {
                  FileDownload(response.data, "doctor-cribs.txt");
                });
              }}
            >
              Download Cribs
            </Button>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) => {
                      setShowNeedsActionOnly(event.target.checked);
                    }}
                    checked={showNeedsActionOnly}
                  />
                }
                label="Needs Action Only"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <TableContainer component={Paper}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list" style={{ display: "table" }}>
              {(provided) => (
                <Table ref={provided.innerRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <ImportExportIcon />
                      </TableCell>
                      <TableCell>Needs Action</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Subtitle</TableCell>
                      <TableCell>Wallet Pass URL</TableCell>
                      <TableCell>Number of Questions</TableCell>
                      <TableCell>Number of Sources</TableCell>
                      <TableCell style={{ minWidth: 80 }}>Actions</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {doctorCribs.map((doctorCrib, index) => (
                      <Row
                        key={doctorCrib.id}
                        index={index}
                        onDeleteClick={handleDelectClick}
                        onEditClick={handleEditClick}
                        {...doctorCrib}
                      />
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </TableContainer>
      )}

      <ConfirmDelete
        open={!!toRemoveId}
        onConfirmClick={handleDeleteConfirm}
        onCancelClick={handleDeleteCancel}
      />
    </>
  );
};

export default DoctorCribsList;
