import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Loader } from "../Loader";
import CompanyEditor from "./CompanyEditor";
import endpoints from "../../api/endpoints";

const CompanyEditorController = ({ id, onSave }) => {
  const [company, setCompany] = useState();

  useEffect(() => {
    if (id) {
      endpoints.companies
        .show({ id })
        .then((res) => {
          setCompany(_.get(res, "data.company", {}));
        })
        .catch((err) => window.alert(err));
    }
  }, [id]);

  if (!company) {
    return <Loader />;
  }
  return <CompanyEditor company={company} onSave={onSave} />;
};

export default CompanyEditorController;
