import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  CardActions,
  Box,
  Typography,
  CardHeader,
  Link,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import moment from "moment";
import _ from "lodash";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QrCodeWithLogo from "qrcode-with-logos";

import PageContainer from "../PageContainer";
import { AppHeader } from "../AppHeader";
import DetailDrawer from "../DetailDrawer";
import NewConsent from "./New";
import Html from "../Html";
import api from "../../api/endpoints";

const StatusIcon = ({ status }) => {
  if (status === "delivered") return <CheckCircleIcon color="success" />;
  if (status === "sent") return <HourglassTopIcon color="warning" />;

  return null;
};

const Consent = () => {
  const [groups, setGroups] = useState();
  const [openNewConsentEmail, setOpenNewConsentEmail] = useState();
  const [viewEmail, setViewEmail] = useState();

  const loadEmails = () =>
    api.consentEmails
      .index()
      .then(({ data }) => {
        setGroups(data.groups);
      })
      .catch((err) => window.alert(err));

  useEffect(() => {
    loadEmails();
  }, []);

  return (
    <>
      {viewEmail && (
        <DetailDrawer
          title="Email Body"
          open={true}
          handleClose={() => setViewEmail()}
        >
          <Card>
            <CardContent>
              <Html>
                {decodeURIComponent(viewEmail.email_body).replace(
                  /\n/g,
                  "<br>"
                )}
              </Html>
            </CardContent>
            {viewEmail.user_facing_url && (
              <>
                <Divider />
                <CardActions>
                  <Button
                    href={viewEmail.user_facing_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Save PDF
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        </DetailDrawer>
      )}
      {openNewConsentEmail && (
        <DetailDrawer
          title="Create New Consent Email"
          open={true}
          handleClose={() => setOpenNewConsentEmail(false)}
        >
          <NewConsent onCreateEmail={() => {}} />
        </DetailDrawer>
      )}
      <PageContainer>
        <Container maxWidth="xl">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setOpenNewConsentEmail(true)}
          >
            New Consent Email
          </Button>
          {groups?.length > 0 && (
            <>
              {groups.map((group) => (
                <Card
                  key={group.id}
                  sx={{ mt: 3, backgroundColor: "#EEEEEE" }}
                  raised
                >
                  <CardHeader
                    title={group.name}
                    subheader="All of the following consents are grouped together so that users would see the entire list of hospitals on their designated Consent page (which would appear as a QR code on their insurance card or elsewhere). If you'd like some of these to be in separate groups, with separate QR code, please contact Quizzify Support."
                  />
                  <Divider />
                  <CardContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography>
                        User Facing URL:{" "}
                        <Link
                          href={group.user_facing_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {group.user_facing_url}
                        </Link>
                      </Typography>
                      <Button
                        startIcon={<QrCode2Icon />}
                        variant="outlined"
                        onClick={() => {
                          new QrCodeWithLogo({
                            canvas: document.createElement("canvas"),
                            content: group.user_facing_url,
                            width: 500,
                            download: true,
                            image: document.createElement("img"),
                            downloadName: `${group.name} - Consent QRCode`,
                            nodeQrCodeOptions: {
                              color: {
                                dark: "#2962FF",
                              },
                              margin: 0,
                            },
                          }).toImage();
                        }}
                      >
                        Save QR Code
                      </Button>
                    </Stack>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Card>
                      <CardContent>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Carrier</TableCell>
                              <TableCell>Group Number</TableCell>
                              <TableCell>Account Number(s)</TableCell>
                              <TableCell>Recipients</TableCell>
                              <TableCell>Hospitals</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>Email Body</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {group.consents.map((email) => (
                              <TableRow key={email.id}>
                                <TableCell>{email.carrier}</TableCell>
                                <TableCell>{email.group_number}</TableCell>
                                <TableCell>
                                  {email.account_numbers.map((number) => (
                                    <div key={number}>{number}</div>
                                  ))}
                                </TableCell>
                                <TableCell>
                                  {email.recipient_emails.map((recipient) => (
                                    <div key={recipient}>{recipient}</div>
                                  ))}
                                </TableCell>
                                <TableCell>
                                  {email.hospitals.map((hospital) => (
                                    <div key={hospital.id}>
                                      {hospital.name} - {hospital.address}
                                    </div>
                                  ))}
                                </TableCell>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StatusIcon status={email.status} />
                                    <Typography sx={{ pl: 1 }}>
                                      {_.capitalize(email.status)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  {moment(email.created_at).calendar()}
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => setViewEmail(email)}>
                                    View Email
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Container>
      </PageContainer>
    </>
  );
};

export default Consent;
