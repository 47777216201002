import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Tab
} from "@mui/material";

const Navigation = () => {
  const history = useHistory();

  const links = [
    {
      path: "/questions/",
      label: "Questions"
    },
    {
      path: "/similar-questions/",
      label: "Similars"
    }
  ]

  const linkIndex = links.findIndex((link) => history.location.pathname === link.path)
  const [currentTab, setCurrentTab] = useState(linkIndex > -1 ? linkIndex : 0);

  return (
    <Tabs value={currentTab} onChange={(e, newTab) => setCurrentTab(newTab)}>
      {
        links.map(
          (link) => (
            <Tab label={link.label} onClick={() => history.push(link.path)} key={link.path} />
          )
        )
      }
    </Tabs>
  )
}

export default Navigation;
