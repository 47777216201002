import NumberFormat from "react-number-format";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import QuizIcon from "@mui/icons-material/Quiz";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import _ from "lodash";
import QrCodeWithLogo from "qrcode-with-logos";
import { Tooltip, IconButton, TableRow, TableCell, Link } from "@mui/material";

const CompanyRow = ({ d }) => {
  const links = [
    {
      href: `quizzes/${d.id}`,
      icon: <QuizIcon />,
      tooltip: "Quizzes",
    },
  ];

  if (d.home_page_url) {
    links.push({
      onClick: () => {
        new QrCodeWithLogo({
          canvas: document.createElement("canvas"),
          content: `${d.home_page_url}/?utm_medium=qrcode`,
          width: 500,
          download: true,
          image: document.createElement("img"),
          downloadName: `${d.name} - qrcode`,
          nodeQrCodeOptions: {
            color: {
              dark: "#2962FF",
            },
            margin: 0,
          },
        }).toImage();
      },
      keyName: "qr",
      icon: <QrCode2Icon />,
      tooltip: "Save QR Code",
    });
  }

  return (
    <TableRow>
      <TableCell>
        {d.logo ? <img src={d.logo} alt="logo" width="60" /> : ""}
      </TableCell>
      <TableCell sx={{ wordBreak: "break-word" }}>{d.id}</TableCell>
      <TableCell sx={{ wordBreak: "break-word" }}>{d.name}</TableCell>
      <TableCell>
        <NumberFormat
          thousandSeparator
          value={d.num_users}
          displayType={"text"}
        />
      </TableCell>
      <TableCell>{d.account_type}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {d.deactivated_at ? "" : <CheckCircleIcon />}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {d.auto_emails && <MarkEmailReadIcon />}
      </TableCell>

      <TableCell sx={{ wordBreak: "break-word" }}>
        {d.home_page_url && (
          <>
            <Link
              href={d.home_page_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {d.home_page_url}
            </Link>
          </>
        )}
      </TableCell>
      {/* <TableCell sx={{ minWidth: 200 }}>
        {links.map(({ tooltip, href, icon, onClick, keyName }) => (
          <Tooltip title={tooltip} key={href || keyName}>
            <IconButton
              href={href}
              onClick={onClick}
              target="_top"
              color="primary"
            >
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </TableCell> */}
    </TableRow>
  );
};

export default CompanyRow;
