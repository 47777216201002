import { useState, useEffect, Fragment } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { red, green, yellow } from "@mui/material/colors";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { htmlToText } from "html-to-text";
import { default as api } from "../../../api/endpoints";
import TemplateModal from "./TemplateModal";

const EditTemplateModal = ({
  template,
  selectedQuestions,
  handleSave,
  ...rest
}) => {
  const [allQuestions, setAllQuestions] = useState();

  const templateIds = template?.questionIds;
  const selectedQuestionIds = selectedQuestions?.map((q) => q.id);

  const combinedIds = _.union(templateIds, selectedQuestionIds);

  const reorderedIds = [];

  const matchingTemplateIds = _.intersection(templateIds, selectedQuestionIds);
  const matchingQuestionIds = _.intersection(selectedQuestionIds, templateIds);

  for (let tempId of matchingTemplateIds) {
    if (
      matchingTemplateIds.indexOf(tempId) !==
      matchingQuestionIds.indexOf(tempId)
    ) {
      reorderedIds.push(tempId);
    }
  }

  useEffect(() => {
    if (!allQuestions) {
      api.questionsSearch
        .searchByIds({
          question_ids: combinedIds,
        })
        .then(({ data }) => {
          setAllQuestions(data.questions);
        })
        .catch((error) => {
          console.error(error);
          setAllQuestions([]);
        });
    }
  }, [combinedIds, allQuestions]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...template,
      questionIds: selectedQuestionIds,
    },
  });

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit(handleSave);

  const getPromptById = (id) => {
    const question = allQuestions.find((q) => q.id === id);

    return question.prompt;
  };

  const renderQuestions = () => (
    <>
      {!allQuestions ? (
        <CircularProgress />
      ) : (
        <>
          {[...Array(combinedIds.length)].map((id, index) => {
            const templateId = _.get(template, `questionIds[${index}]`);
            const questionId = _.get(selectedQuestions, `[${index}].id`);

            const renderTemplateNode = () => {
              if (!templateId) return null;

              // If the templateId is gone it was deleted; show this first
              if (!selectedQuestionIds.includes(templateId)) {
                return (
                  <Typography
                    key={templateId}
                    sx={{
                      backgroundColor: red[50],
                      color: red[600],
                    }}
                  >
                    -{" "}
                    {htmlToText(`${templateId}: ${getPromptById(templateId)}`)}
                  </Typography>
                );
              }

              return null;
            };

            const renderQuestionNode = () => {
              if (!questionId) return null;

              const text = htmlToText(
                `${questionId}: ${getPromptById(questionId)}`
              );

              // If the question is new add it
              if (!templateIds.includes(questionId)) {
                return (
                  <Typography
                    key={questionId}
                    sx={{
                      backgroundColor: green[50],
                      color: green[600],
                    }}
                  >
                    + {text}
                  </Typography>
                );
              }

              if (reorderedIds.includes(questionId)) {
                return (
                  <Typography
                    key={questionId}
                    sx={{
                      backgroundColor: yellow[50],
                    }}
                  >
                    {text}
                  </Typography>
                );
              }

              return <Typography key={questionId}>{text}</Typography>;
            };

            return (
              <Fragment key={templateId || questionId}>
                {renderTemplateNode()}
                {renderQuestionNode()}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );

  return (
    <TemplateModal
      {...rest}
      errors={errors}
      register={register}
      onSubmit={onSubmit}
      questions={renderQuestions()}
      title="Edit Template"
    />
  );
};

export default EditTemplateModal;
