import React, { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { default as api } from "../../api/endpoints";

const CompanyGroupSelect = ({ watch, control }) => {
  const [companyGroups, setCompanyGroups] = useState(null);

  const company = watch("company");

  useEffect(() => {
    if (company?.id) {
      api.users.availableCompanyGroups(company.id).then((response) => {
        setCompanyGroups(response.data.companyGroups);
      });
    } else {
      setCompanyGroups([]);
    }
  }, [company?.id]);

  if (!companyGroups) {
    return <CircularProgress />;
  } else if (companyGroups.length === 0) {
    return null;
  }

  return (
    <Controller
      control={control}
      name="companyGroupId"
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <InputLabel id="company-group-select">Company Group</InputLabel>
          <Select
            id="company-group-select"
            label="Company Group"
            variant="outlined"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          >
            <MenuItem value="" key={0}>
              (None)
            </MenuItem>
            {companyGroups.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default CompanyGroupSelect;
