import React, { useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  TableContainer,
  Box,
} from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import QuestionRow from "./QuestionRow";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed", minWidth: 1500 }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function FixedHeaderContent({ order, orderBy, createSortHandler }) {
  const columns = [
    { id: "actions", label: "Actions", noSort: true, width: 150 },
    { id: "id", label: "ID" },
    { id: "prompt", label: "Prompt", width: 500, noSort: true },
    { id: "lastUpdated", label: "Last Updated" },
    { id: "numCompanies", label: "Companies Using" },
    { id: "customFor", label: "Custom For" },
    { id: "published", label: "Published" },
    { id: "harvardApproved", label: "Harvard Approved" },
    { id: "module", label: "Module" },
    { id: "format", label: "Format" },
    // { id: "length", label: "Length", noSort: true },
    // { id: "grade", label: "Grade Lvl", noSort: true },
  ];

  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          variant="head"
          align="left"
          sx={{ width: column.width || "auto", backgroundColor: "#FFFFFF" }}
          sortDirection={orderBy === column.id ? order : false}
        >
          {column.noSort ? (
            column.label
          ) : (
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
            </TableSortLabel>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row, refresh, setEditQuestion) {
  return (
    <QuestionRow
      key={row.id}
      q={row}
      {...row}
      refresh={refresh}
      setEditQuestion={setEditQuestion}
    />
  );
}

const QuestionsTable = ({ questions, state, refresh, setEditQuestion }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const { headline } = state;

  const rows = stableSort(questions, getComparator(order, orderBy));

  return (
    <>
      <Box sx={{ p: 1.5 }}>
        <Typography gutterBottom variant="subtitle1">
          {headline}
        </Typography>
      </Box>

      <Paper style={{ height: "70vh", width: "100%" }}>
        <TableVirtuoso
          data={rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => (
            <FixedHeaderContent
              order={order}
              orderBy={orderBy}
              createSortHandler={createSortHandler}
            />
          )}
          itemContent={(_index, row) =>
            rowContent(_index, row, refresh, setEditQuestion)
          }
        />
      </Paper>
    </>
  );
};

export default QuestionsTable;
