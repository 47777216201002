import React, { useState, useContext } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import { context, actions } from "../AppContext";
import { clearAuthToken } from "../api/api-core";

const UserProfile = () => {
  const [anchorEl, setAnchorEl] = useState();

  const { state, dispatch } = useContext(context);

  const { userProfile } = state;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch({
      type: actions.logout,
    });
    clearAuthToken();
    handleClose();
  };

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <Tooltip title="Profile" sx={{ ml: "auto" }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle color="primary" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ pl: 2, pr: 2, pb: 2, pt: 1 }}>
          <Typography>
            You&apos;re logged in as {userProfile.display_name} (
            {userProfile.email})
          </Typography>
        </Box>

        <Divider />
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserProfile;
