import React, { useState } from "react";
import Editor from "./Editor";
import QuizzesChanged from "./QuizzesChanged";

const CreateOrEditQuiz = ({ id, ...rest }) => {
  const [quizzesChanged, setQuizzesChanged] = useState();
  const editingMode = !!id;

  if (quizzesChanged) {
    return (
      <QuizzesChanged
        title={editingMode ? "Quizzes Updated" : "Quizzes Created"}
        quizzes={quizzesChanged}
        editAgain={() => setQuizzesChanged()}
        {...rest}
      />
    );
  }

  return <Editor id={id} setQuizzesChanged={setQuizzesChanged} {...rest} />;
};

export default CreateOrEditQuiz;
