import React, { memo, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import api from "../../../api/endpoints";
import Map from "./Map";
import cities from "./cities";
import states from "./states";

function Hospitals({ hospitals, setHospitals }) {
  const [state, setState] = useState("");
  const [mainLocation, setMainLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setCenter] = useState({ lat: 35.2271, lng: -80.8431 });
  const [searchResults, setSearchResults] = useState("");

  const searchByLocation = async (center) => {
    const { data } = await api.googlePlaces.searchByLocation({
      latitude: center.lat,
      longitude: center.lng,
      searchTerm: searchTerm + " " + state,
    });

    setSearchResults(data.results);
  };

  const addAllLocations = () => {
    const newResults = searchResults.filter(
      (result) =>
        !hospitals.some((hospital) => hospital.place_id === result.place_id)
    );

    const newLocations = hospitals.concat(newResults);

    setHospitals(newLocations);
  };

  const add = (hospital) => {
    const newLocations = hospitals.concat(hospital);
    setHospitals(newLocations);
  };

  const remove = (hospital) => {
    const newLocations = hospitals.filter(
      (selected) => selected.place_id !== hospital.place_id
    );

    setHospitals(newLocations);
  };

  const selected = (hospital) =>
    hospitals.map((selected) => selected.place_id).includes(hospital.place_id);

  useEffect(() => {
    if (mainLocation) {
      api.googlePlaces
        .findPlace({
          searchTerm: mainLocation,
        })
        .then((result) => {
          const center = _.get(result, "data[0].geometry.location", {
            lat: 35.2271,
            lng: -80.8431,
          });
          setCenter(center);
        });
    }
  }, [mainLocation]);

  useEffect(() => {
    if (state) {
      api.googlePlaces
        .findPlace({
          searchTerm: state,
        })
        .then((result) => {
          const center = _.get(result, "data[0].geometry.location", {
            lat: 35.2271,
            lng: -80.8431,
          });
          setCenter(center);
        });
    }
  }, [state]);

  const allLocations = [...hospitals, ...searchResults].reduce((acc, cur) => {
    const allPlaceIds = acc.map((item) => item.place_id);

    if (!allPlaceIds.includes(cur.place_id)) {
      acc.push(cur);
    }

    return acc;
  }, []);

  return (
    <Stack spacing={2}>
      <Autocomplete
        value={state}
        onChange={(_, newValue) => {
          setState(newValue);
        }}
        options={_.uniq(states)}
        freeSolo
        autoHighlight
        openOnFocus
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="State" />
        )}
      />

      <Autocomplete
        value={mainLocation}
        onChange={(_, newValue) => {
          setMainLocation(newValue);
        }}
        options={_.uniq(cities).filter((city) =>
          state ? city.includes(state) : city
        )}
        freeSolo
        autoHighlight
        openOnFocus
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="City or Town" />
        )}
      />

      <TextField
        type="text"
        label="Hospital Name"
        placeholder="Search by name or address"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Button
        onClick={() => {
          searchByLocation(center);
        }}
        type="button"
        variant="outlined"
      >
        Search
      </Button>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Map
            locations={allLocations.map((result) => ({
              ...result,
              isSelected: selected(result),
            }))}
            center={center}
            setCenter={setCenter}
          />
        </Grid>
        <Grid item sm={6}>
          {allLocations.length > 0 && (
            <>
              <Button
                onClick={(event) => {
                  addAllLocations();
                }}
                type="button"
              >
                Add All
              </Button>
              <Table size="small">
                <TableBody>
                  {allLocations.map((hospital) => {
                    const isSelected = selected(hospital);

                    return (
                      <TableRow key={hospital.place_id}>
                        <TableCell>
                          <Tooltip title={isSelected ? "Remove" : "Add"}>
                            <IconButton
                              onClick={() => {
                                isSelected ? remove(hospital) : add(hospital);
                              }}
                              type="button"
                            >
                              {isSelected ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <AddIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{hospital.name}</TableCell>
                        <TableCell>{hospital.vicinity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default memo(Hospitals);
