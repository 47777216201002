import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import commaNumber from "comma-number";

const UserBreakdown = ({ usersBreakdown }) => {
  const {
    all,
    admins,
    nonAdminExcludedFromData,
    reportedUsers,
    didNotComplete,
    completedOnce,
    completedTwice,
  } = usersBreakdown;

  return (
    <Card>
      <CardHeader title="User Data Breakdown" />
      <CardContent>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell># Unique Users</TableCell>
              <TableCell>{commaNumber(all)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Admins Excluded</TableCell>
              <TableCell>{commaNumber(admins)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Non-Admins Excluded</TableCell>
              <TableCell>{commaNumber(nonAdminExcludedFromData)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reported Users</TableCell>
              <TableCell>{commaNumber(reportedUsers)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Did not Complete</TableCell>
              <TableCell>{commaNumber(didNotComplete)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed One+</TableCell>
              <TableCell>{commaNumber(completedOnce)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed Two+</TableCell>
              <TableCell>{commaNumber(completedTwice)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserBreakdown;
