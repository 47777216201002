import React, { useState } from "react";
import PageContainer from "../PageContainer";
import List from "./List";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import Permissions from "./Permissions";

const UserManager = () => {
  const [currentView, setCurrentView] = useState({ view: "list", payload: {} });

  const renderPage = () => {
    switch (currentView.view) {
      case "edit":
        return (
          <EditUser setCurrentView={setCurrentView} {...currentView.payload} />
        );
      case "new":
        return (
          <AddUser setCurrentView={setCurrentView} {...currentView.payload} />
        );
      case "permissions":
        return (
          <Permissions
            setCurrentView={setCurrentView}
            {...currentView.payload}
          />
        );
      default:
        return (
          <List setCurrentView={setCurrentView} {...currentView.payload} />
        );
    }
  };

  return <PageContainer>{renderPage()}</PageContainer>;
};

export default UserManager;
