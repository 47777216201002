import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, CircularProgress, Box } from "@mui/material";
import { context, actions } from "../../AppContext";
import { default as api } from "../../api/endpoints";
import TabPanel from "../TabPanel";
import Summary from "./Summary";
import Links from "./Links";
import QuestionDetails from "./QuestionDetails";
import Table from "./Table";
import Preview from "./Preview";
import PreviewEndScreen from "./PreviewEndScreen";
import EditQuiz from "./EditQuiz";
import PageContainer from "../PageContainer";

const makeLocalStorageKey = (id = "") => `quiz-report-tab-${id}`;

const QuizReport = () => {
  const { dispatch } = useContext(context);

  const { id } = useParams();

  const LOCAL_STORAGE_TAB_KEY = makeLocalStorageKey(id);

  const myStorage = window.localStorage;
  const startingTabString = myStorage.getItem(LOCAL_STORAGE_TAB_KEY);
  const startTabValue = startingTabString ? parseInt(startingTabString) : 0;

  const [tab, setTab] = useState(startTabValue);

  const [summary, setSummary] = useState();
  const [links, setLinks] = useState();
  const [questionDetails, setQuestionDetails] = useState();
  const [table, setTable] = useState();
  const [preview, setPreview] = useState();
  const [updatePreview, setUpdatePreview] = useState();

  useEffect(() => {
    api.reports.quiz.summary.get({ id }).then(({ data }) => {
      setSummary(data);

      dispatch({
        type: actions.loadState,
        data: { pageTitle: `Admin > Quiz > ${data.name} (#${id})` },
      });
    });
    api.reports.quiz.links.get({ id }).then(({ data }) => {
      setLinks(data);
    });
    api.reports.quiz.questionDetails.get({ id }).then(({ data }) => {
      setQuestionDetails(data);
    });
    api.reports.quiz.table.get({ id }).then(({ data }) => {
      setTable(data);
    });
    api.reports.quiz.preview.get({ id }).then(({ data }) => {
      setPreview(data);
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (updatePreview) {
      api.reports.quiz.preview.get({ id }).then(({ data }) => {
        setPreview(data);
        setUpdatePreview(false);
      });
    }
  }, [id, dispatch, updatePreview]);

  return (
    <PageContainer fullScreen>
      <Box position="static">
        <Tabs
          value={tab}
          onChange={(ev, newValue) => {
            setTab(newValue);
            myStorage.setItem(LOCAL_STORAGE_TAB_KEY, newValue);
          }}
          aria-label="report tabs"
        >
          <Tab label="Summary" />
          <Tab label="Link Clicks" />
          <Tab label="Question Data" />
          <Tab label="Full Table" />
          <Tab label="Preview Quiz" />
          <Tab label="Preview End Screen" />
          <Tab label="Edit Quiz" />
        </Tabs>
      </Box>

      <div style={{ padding: 24 }}>
        <TabPanel value={tab} index={0}>
          {summary ? <Summary {...summary} /> : <CircularProgress />}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {links ? <Links {...links} id={id} /> : <CircularProgress />}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          {questionDetails ? (
            <QuestionDetails {...questionDetails} id={id} />
          ) : (
            <CircularProgress />
          )}
        </TabPanel>
        <TabPanel value={tab} index={3}>
          {table ? <Table {...table} id={id} /> : <CircularProgress />}
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <Preview {...preview} />
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <PreviewEndScreen {...preview} />
        </TabPanel>
        <TabPanel value={tab} index={6}>
          <EditQuiz id={id} onFinishEditing={() => setUpdatePreview(true)} />
        </TabPanel>
      </div>
    </PageContainer>
  );
};

export default QuizReport;
