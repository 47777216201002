import React from "react";
import QuizEditor from "../QuizEditor";

const EditQuiz = ({ id, ...rest }) => (
  <div style={{ flex: 1, minHeight: 1000, width: "100%" }}>
    <QuizEditor quizId={id} inQuizManager {...rest} />
  </div>
);

export default EditQuiz;
