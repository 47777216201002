import { useState } from "react";
import { Button } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import NoteForm from "./NoteForm";

const AddNote = (props) => {
  const [addNote, setAddNote] = useState();

  if (addNote) {
    return <NoteForm {...props} handleClose={() => setAddNote()} />;
  }

  return (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      color="secondary"
      onClick={() => setAddNote(true)}
    >
      Add New Note
    </Button>
  );
};

export default AddNote;
