import { Chip } from "@mui/material";

const CorrectIndicator = ({ isCorrect }) => (
  <Chip
    label={isCorrect ? "Correct" : "Incorrect"}
    color={isCorrect ? "success" : "error"}
  />
);

export default CorrectIndicator;
