import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  FormControlLabel
} from "@mui/material";

const generateDefaultValues = ({ quizzes }) => ({
  quizzes: quizzes.map((quiz) => ({ quiz, checked: false }))
});

const SelectionList = ({ question, quizzes, buttonLabel, onSelect }) => {
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: generateDefaultValues({ quizzes })
  });

  const { fields } = useFieldArray({
    control,
    name: "quizzes"
  });

  const toggleSelectAll = (value) => {
    setAreAllSelected(value);

    quizzes.forEach((_, index) => {
      setValue(`quizzes.${index}.checked`, value);
    });
  }

  const onSubmit = handleSubmit((values) => {
    const selectedQuizzes = values.quizzes.filter(({ checked }) => checked).map(({ quiz }) => quiz);

    if (selectedQuizzes.length === 0) {
      return ;
    }

    onSelect(selectedQuizzes);
  });

  return (
    <form onSubmit={onSubmit}>
      <ListItem>
        <ListItemButton>
          <FormControlLabel
            label="Select all"
            control={
              <Checkbox
                checked={areAllSelected}
                onChange={(e) => toggleSelectAll(e.target.checked)}
                color="primary"
              />
            }
          />
        </ListItemButton>
      </ListItem>
      {
        fields.map(
          (field, index) => (
            <ListItem key={field.quiz.id}>
              <Controller
                control={control}
                name={`quizzes.${index}.checked`}
                render={
                  ({ field: { onChange, value, ref } }) => (
                    <>
                      <ListItemButton onClick={
                        (e) => {
                          e.stopPropagation();
                          onChange(!value);
                        }
                      }>
                        <ListItemIcon>
                          <Checkbox
                            inputRef={ref}
                            value={field.id}
                            checked={value}
                            onChange={
                              (e) => {
                                e.stopPropagation();
                                onChange(e.target.checked)
                              }
                            }
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          ID: {field.quiz.id} {field.quiz.name} (company ID: {field.quiz.company.id} {field.quiz.company.name})
                        </ListItemText>
                      </ListItemButton>
                    </>
                  )
                }
              />
            </ListItem>
          )
        )
      }
      <ListItem>
        <ListItemButton>
          <FormControlLabel
            label="Select all"
            control={
              <Checkbox
                checked={areAllSelected}
                onChange={(e) => toggleSelectAll(e.target.checked)}
                color="primary"
              />
            }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key="button">
        <Button variant="contained" onClick={
          (e) => {
            e.stopPropagation();
            onSubmit();
          }
        }>
          {buttonLabel}
        </Button>
      </ListItem>
    </form>
  );
}

export default SelectionList;
