import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";

import sanitizeHtml from "../../../../utils/sanitizeHtml";
import LastCommentStatus from "./LastCommentStatus";
import ProcessButton from "./ProcessButton";
import Status from "./Status";

const QuestionReviewRow = ({ questionReview, ...rest }) => (
  <TableRow>
    <TableCell>
      <Status review={questionReview} />
    </TableCell>
    <TableCell
      sx={{ maxWidth: 300 }}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(questionReview.question.prompt),
      }}
    />
    <TableCell>{moment(questionReview.last_change_at).calendar()}</TableCell>
    <TableCell>
      <LastCommentStatus questionReview={questionReview} />
    </TableCell>
    <TableCell>
      <ProcessButton questionReview={questionReview} {...rest} />
    </TableCell>
  </TableRow>
);

export default QuestionReviewRow;
