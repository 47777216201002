import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const ControlledDatePicker = ({ name, control, label }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <DesktopDatePicker
          inputRef={ref}
          label={label}
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              // id={`${name}-date-picker`}
              // margin="normal"
              // variant="outlined"
              fullWidth
              // error={invalid}
            />
            // <MobileDatePicker
            //   label="Date mobile"
            //   inputFormat="MM/dd/yyyy"
            //   value={value}
            //   onChange={handleChange}
            //   renderInput={(params) => <TextField {...params} />}
            // />
          )}
        />
      )}
    />
  </LocalizationProvider>
);

export default ControlledDatePicker;
