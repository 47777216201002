import { Typography } from "@mui/material";

const TemplateQuestionEditingMessage = () => (
  <Typography variant="caption">
    Note: if you've edited and saved questions themselves, they are already
    updated in whatever template they appear in. Templates merely organize a
    list of questions. They don't rely on or modify the content of the questions
    themselves.
  </Typography>
);

export default TemplateQuestionEditingMessage;
