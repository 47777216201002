import { TextField } from "@mui/material";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailInput = ({ register, errors, label = "Email Address", ...rest }) => (
  <TextField
    {...rest}
    label={label}
    variant="outlined"
    type="email"
    {...register("email", {
      required: "You must enter an email",
      validate: {
        isEmail: (value) =>
          EMAIL_REGEX.test(value)
            ? true
            : "You must enter a valid email address",
      },
    })}
    aria-label={label}
    fullWidth={true}
    error={!!errors.email}
    helperText={errors.email && errors.email.message}
  />
);

export default EmailInput;
