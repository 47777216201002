import React, { createContext, useReducer } from "react";
import keyMirror from "keymirror";
import { questionsState } from "../../stateModel";
import reducer from "../../utils/curriedReducer";
import * as R from "ramda";

const actions = keyMirror({
  loadState: null,
  updateQuestion: null,
  updateAnswer: null,
});

const { loadState, updateQuestion, updateAnswer } = actions;

const context = createContext(questionsState);
const { Provider } = context;

const mergeById = R.curry((updated, prev) =>
  prev.id === updated.id ? R.mergeDeepLeft(updated, prev) : prev
);

const handlers = {
  [loadState]: R.pipe(R.prop("data"), R.mergeDeepLeft),
  [updateQuestion]: (action) => {
    const updated = action.question;
    return R.over(R.lensProp("questions"), R.map(mergeById(updated)));
  },
  [updateAnswer]: (action) => {
    const { answer: updated, questionId } = action;
    return R.over(
      R.lensProp("questions"),
      R.map(
        R.ifElse(
          R.propEq("id", questionId),
          R.over(R.lensProp("answers"), R.map(mergeById(updated))),
          R.identity
        )
      )
    );
  },
};

const QuestionsStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer(handlers), questionsState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { context, QuestionsStateProvider, actions };
