import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import TaskIcon from "@mui/icons-material/Task";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import moment from "moment";
import _ from "lodash";
import CommentIcon from "@mui/icons-material/Comment";
import sanitizeHtml from "../../../utils/sanitizeHtml";

const ReviewHistoryButton = ({ onClick }) => (
  <Tooltip title="History and review">
    <IconButton onClick={onClick}>
      <ManageSearchIcon />
    </IconButton>
  </Tooltip>
);

const ReviewRequestButton = ({ questionReviewId, onReviewRequest }) => (
  <Tooltip title="Request review">
    <IconButton onClick={() => onReviewRequest(questionReviewId)}>
      <TaskIcon />
    </IconButton>
  </Tooltip>
);

const MarkAsNotApplicableButton = ({
  questionReviewId,
  onMarkAsNotApplicable,
}) => (
  <Tooltip title="Not applicable">
    <IconButton onClick={() => onMarkAsNotApplicable(questionReviewId)}>
      <PlaylistRemoveIcon />
    </IconButton>
  </Tooltip>
);

const getComments = (questionReview) =>
  questionReview.history?.filter((item) => item.type === "commented") || [];

const getLastCommentStatus = (lastComment, currentUserId) => {
  if (!lastComment) return null;

  return lastComment.user?.id === currentUserId ? "yours" : "theirs";
};

const renderLastCommentStatus = (lastCommentStatus) => {
  if (!lastCommentStatus) return null;

  if (lastCommentStatus === "yours")
    return (
      <Stack direction="row" spacing={1}>
        <CommentIcon />
        <Typography>You left comments</Typography>
      </Stack>
    );

  return (
    <Stack direction="row" spacing={1}>
      <CommentIcon color="warning" />
      <Typography>You received comments</Typography>
    </Stack>
  );
};

const QuestionReviewRow = ({
  questionReview,
  onReviewRequest,
  onMarkAsNotApplicable,
  currentFilter,
  currentUserId,
  handleReviewHistoryClick,
}) => {
  const comments = getComments(questionReview);

  const lastComment = comments.length > 0 ? comments.slice(-1)[0] : null;

  const lastCommentStatus = getLastCommentStatus(lastComment, currentUserId);

  const canBeReviewed =
    questionReview.status === "pending" ||
    questionReview.status === "not_applicable";
  const canBeNotApplicable =
    questionReview.status === "pending" ||
    questionReview.status === "requested";

  return (
    <TableRow>
      <TableCell
        sx={{ maxWidth: "50%", width: 500 }}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(questionReview.question.prompt),
        }}
      />
      <TableCell sx={{ width: 75 }}>{questionReview.status}</TableCell>
      <TableCell sx={{ width: 90 }}>
        {moment(questionReview.question.last_change_at).calendar()}
      </TableCell>
      <TableCell sx={{ width: 75 }}>
        {questionReview.question.published ? "True" : "False"}
      </TableCell>
      <TableCell sx={{ width: 75 }}>
        {questionReview.question.harvard_approved ? "True" : "False"}
      </TableCell>
      <TableCell sx={{ width: 75 }}>{questionReview.question.module}</TableCell>
      <TableCell sx={{ width: 75 }}>
        {_.get(questionReview, "question.custom_for.name")}
      </TableCell>
      <TableCell>{renderLastCommentStatus(lastCommentStatus)}</TableCell>
      <TableCell sx={{ width: 125, minWidth: 125 }}>
        <ReviewHistoryButton
          questionReviewId={questionReview.id}
          currentFilter={currentFilter}
          onClick={() => handleReviewHistoryClick(questionReview.id)}
        />
        {canBeReviewed ? (
          <ReviewRequestButton
            questionReviewId={questionReview.id}
            onReviewRequest={onReviewRequest}
          />
        ) : null}
        {canBeNotApplicable ? (
          <MarkAsNotApplicableButton
            questionReviewId={questionReview.id}
            onMarkAsNotApplicable={onMarkAsNotApplicable}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default QuestionReviewRow;
