import sanitizeHtml from "../utils/sanitizeHtml";

const Html = ({ style, children }) => (
  <span
    style={{
      whiteSpace: "pre-line",
      ...style,
    }}
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(children, { formatLinks: true }),
    }}
  />
);

export default Html;
