import { Grid, Container, Hidden } from "@mui/material";
import AppContainer from "../AppContainer";
import Users from "./Users";
import Lessons from "./Lessons";
import MobileHome from "./MobileHome";

const Home = () => (
  <AppContainer>
    <Hidden mdUp>
      <MobileHome />
    </Hidden>
    <Hidden mdDown>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item md={8} xs={12} sx={{ height: "100%" }}>
            <Lessons />
          </Grid>

          <Grid item md={4} xs={12}>
            <Users />
          </Grid>
        </Grid>
      </Container>
    </Hidden>
  </AppContainer>
);

export default Home;
