import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  IconButton
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import PageContainer from "../PageContainer";
import { default as api } from "../../api/endpoints";
import FormStep from "./MassArchive/FormStep";
import ReviewStep from "./MassArchive/ReviewStep";
import ResultStep from "./MassArchive/ResultStep";

const steps = [
  { label: 'Select users', submitLabel: 'Submit for review' },
  { label: 'Review' , submitLabel: 'Archive'},
  { label: 'Done', submitLabel: null },
];

const CurrentStep = ({ activeStep, ...stepProps }) => {
  switch (activeStep) {
    case 1:
      return <ReviewStep {...stepProps} />
    case 2:
      return <ResultStep {...stepProps} />
    default:
      return <FormStep {...stepProps} />
  }
}

const MassArchive = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [review, setReview] = useState(null);
  const [finalResult, setFinalResult] = useState(null);
  const history = useHistory();

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues
  } = useForm({
    defaultValues: {
      selector: 'email',
      shouldExcludeAll: true,
      excludeAfter: moment()
    },
  });

  const handleReviewSubmit = handleSubmit(({ selector, payload, shouldExcludeAll, excludeAfter }) => {
    setIsSubmitting(true);

    api.users.reviewMassArchive({ selector, payload }).then(({ data }) => {
      setReview(data);
      setActiveStep(1);
      setIsSubmitting(false);
    });
  })

  const handleConfirmSubmit = () => {
    setIsSubmitting(true);

    const { shouldExcludeAll, excludeAfter } = getValues()

    const userIds = review.to_archive.map(({ id }) => id);
    api.users.massArchive({ userIds, shouldExcludeAll, excludeAfter }).then(({ data }) => {
      setFinalResult(data);
      setActiveStep(2);
      setIsSubmitting(false);
    });
  }

  const onSubmit = (step) => {
    switch (step) {
      case 1:
        return handleConfirmSubmit;
      default:
        return handleReviewSubmit;
    }
  }

  const onCancel = (step) => {
    switch (step) {
      case 1:
        return (() => {
          setReview(null);
          setActiveStep(0);
        });
      default:
        return (() => {
          history.push('/user-manager');
        })
    }
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to="/user-manager" component={IconButton}>
            <ArrowBackIcon /> Users Manager
          </Link>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={onSubmit(activeStep)}>
            <Card>
              <CardHeader title="Archive multiple users" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stepper activeStep={activeStep}>
                      { steps.map(
                        (step, index) => (
                          <Step key={index}>
                            <StepLabel>{step.label}</StepLabel>
                          </Step>
                        )
                      )}
                    </Stepper>
                  </Grid>
                  <Grid item xs={12}>
                    <CurrentStep activeStep={activeStep} register={register} control={control} watch={watch} review={review} finalResult={finalResult} />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                {
                  steps[activeStep].submitLabel ? (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={onSubmit(activeStep)} disabled={isSubmitting}>
                          { steps[activeStep].submitLabel }
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="secondary" onClick={onCancel(activeStep)}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null
                }
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default MassArchive;
