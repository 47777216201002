import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
} from "@mui/material";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { default as api } from "../../api/endpoints";

const actionHandlerMap = {
  archive: {
    title: (user) => `Archive user ${user.email}?`,
    cta: "Archive",
    handle: ({ user, shouldExcludeAll, excludeAfter }) => api.users.archive({ userId: user.id, shouldExcludeAll, excludeAfter })
  },
  unarchive: {
    title: (user) => `Unarchive user ${user.email}?`,
    cta: "Unarchive",
    handle: ({ user }) => api.users.unarchive({ userId: user.id })
  },
  softDelete: {
    title: (user) => `Delete user ${user.email}?`,
    cta: "Delete",
    handle: ({ user, shouldExcludeAll, excludeAfter }) => api.users.softDelete({ userId: user.id, shouldExcludeAll, excludeAfter })
  }
}

const ExcludeFromDataSelection = ({ shouldExcludeAll, excludeAfter, onShouldExcludeAllChange, onExcludeFromChange }) => {
  const handle = (e) => {
    onShouldExcludeAllChange(e.target.checked);
  }

  return (
    <div>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={shouldExcludeAll} onChange={handle} />} label="Exclude from all-time data?" />
          </Grid>
          <Grid item xs={12}>
            {
              !shouldExcludeAll ? (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDateTimePicker
                    label="Exclude from"
                    value={excludeAfter}
                    onChange={onExcludeFromChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : null
            }
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
}

const ActionDialogContent = ({ action, isSubmitting, shouldExcludeAll, excludeAfter, onShouldExcludeAllChange, onExcludeFromChange }) => {
  if (isSubmitting) {
    return <CircularProgress sx={{ alignSelf: "center" }} />;
  }

  if (action.type === 'unarchive') {
    return null
  }

  return (
    <ExcludeFromDataSelection
      shouldExcludeAll={shouldExcludeAll}
      excludeAfter={excludeAfter}
      onShouldExcludeAllChange={onShouldExcludeAllChange}
      onExcludeFromChange={onExcludeFromChange}
    />
  );
};

const ActionDialog = ({ action, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldExcludeAll, setShouldExcludeAll] = useState(true);
  const [excludeAfter, setExcludeAfter] = useState(moment());

  if (!action) {
    return null;
  }

  const { user, type } = action;
  const actionHandler = actionHandlerMap[type];

  const handleConfirmClick = () => {
    setIsSubmitting(true);
    actionHandler.handle({ user, shouldExcludeAll, excludeAfter }).then(() => {
      setIsSubmitting(false);
      setShouldExcludeAll(true);
      setExcludeAfter(moment());
      onClose(true);
    });
  }

  const handleShouldExcludeChange = (newValue) => {
    setShouldExcludeAll(newValue);
  }

  const handleExcludeFromChange = (newValue) => {
    setExcludeAfter(newValue);
  }

  const handleClose = () => {
    setShouldExcludeAll(true);
    setExcludeAfter(moment());
    onClose();
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        { actionHandler.title(user) }
      </DialogTitle>
      <DialogContent>
        <ActionDialogContent
          action={action}
          isSubmitting={isSubmitting}
          shouldExcludeAll={shouldExcludeAll}
          excludeAfter={excludeAfter}
          onShouldExcludeAllChange={handleShouldExcludeChange}
          onExcludeFromChange={handleExcludeFromChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={handleConfirmClick} disabled={isSubmitting}>
          { actionHandler.cta }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionDialog;
