import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import EmailInput from "../EmailInput";

const LoginForm = ({ onLogin }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    setError,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit((values) => {
    onLogin(values).catch(() =>
      setError("login", {
        type: "manual",
        message: t("Login Failed"),
      })
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <EmailInput register={register} errors={errors} />
        <TextField
          label={t("Password")}
          variant="outlined"
          type="password"
          {...register("password", {
            required: t("You must enter a password"),
          })}
          aria-label={t("Password")}
          fullWidth={true}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          Submit
        </Button>
        {errors.login && (
          <Typography color="error">{errors.login.message}</Typography>
        )}
      </Stack>
    </form>
  );
};

export default LoginForm;
