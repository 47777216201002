import React from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";

import UserProfile from "../UserProfile";

const AppHeader = ({ title }) => (
  <AppBar depth={1} sx={{ flexShrink: 0, position: "static" }}>
    <Toolbar sx={{ backgroundColor: "white" }}>
      <Typography
        variant="h6"
        color="primary"
        sx={{ fontFamily: "Didot", fontWeight: "bold", fontSize: 25 }}
      >
        Quizzify
      </Typography>
      <Typography variant="h6" sx={{ pl: 2, color: "#AAAAAA" }}>
        {title}
      </Typography>
      <UserProfile />
    </Toolbar>
  </AppBar>
);

export default AppHeader;
