import React from "react";
import ImageUploader from "./ImageUploader";

const ImagePicker = ({ onSelect, path = "/" }) => {
  const handleUpload = ({ id, url }) => onSelect({ id, url });

  return (
    <ImageUploader onUpload={handleUpload} path={path} />
  )
}

export default ImagePicker;
