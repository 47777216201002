import { Stack, Button, Divider } from "@mui/material";
import Comment from "./Comment";

const actionsFor = (status) => {
  if (!status) {
    return [];
  }

  const actionsMapper = {
    pending: [
      {
        label: "Request review",
        handler: "requestReview",
      },
      {
        label: "Mark as not applicable",
        handler: "markAsNotApplicable",
      },
    ],
    requested: [
      {
        label: "Approve",
        handler: "approve",
      },
      {
        label: "Mark as not applicable",
        handler: "markAsNotApplicable",
      },
    ],
    approved_conditionally: [],
    approved: [
      {
        label: "Request review",
        handler: "requestReview",
      },
      {
        label: "Mark as not applicable",
        handler: "markAsNotApplicable",
      },
    ],
    not_applicable: [
      {
        label: "Request review",
        handler: "requestReview",
      },
    ],
  };

  return actionsMapper[status];
};

const ActionsForm = ({ status, onSubmit }) => {
  const actions = actionsFor(status);

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Comment onSubmit={onSubmit} />
      <Divider />
      {actions.map((action) => (
        <Button
          key={action.label}
          onClick={() => onSubmit({ action })}
          variant="outlined"
        >
          {action.label}
        </Button>
      ))}
    </Stack>
  );
};

export default ActionsForm;
