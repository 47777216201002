import { useState } from "react";
import { TextField, Button } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";

const Comment = ({ onSubmit }) => {
  const [comment, setComment] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleClick = (comment) => {
    const action = {
      label: "Comment only",
      handler: "comment",
    };

    onSubmit({ action, comment });
    setComment("");
  };

  return (
    <>
      <TextField
        label="New Comment"
        value={comment}
        onChange={handleCommentChange}
        multiline
        fullWidth
        placeholder="The reference to x is not accurate"
      />
      <Button
        disabled={!comment}
        variant="contained"
        startIcon={<AddCommentIcon />}
        onClick={() => handleClick(comment)}
      >
        Save Comment
      </Button>
    </>
  );
};

export default Comment;
