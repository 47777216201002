import React from "react";
import {
  Grid,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import UserTable from "./UserTable";

const NotFoundRow = ({ entry }) => (
  <TableRow>
    <TableCell>{ entry }</TableCell>
  </TableRow>
)

const EmptyNotFoundRow = () => (
  <TableRow>
    <TableCell>--</TableCell>
  </TableRow>
)

const NotFoundTable = ({ entries }) => (
  <Grid item xs={12}>
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        component="div"
      >
        Not found
      </Typography>
    </Toolbar>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            entries.length > 0 ? (
              entries.map((entry) => (
                <NotFoundRow entry={entry} />
              ))
            ) : <EmptyNotFoundRow />
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
)

const ReviewStep = ({ review }) => {
  return (
    <Grid container>
      <UserTable label={`Found - to archive (${review.to_archive.length} users)`} entries={review.to_archive} />
      <UserTable label={`Unauthorized (${review.unauthorized.length} users)`} entries={review.unauthorized} />
      <NotFoundTable entries={review.not_found} />
    </Grid>
  )
};

export default ReviewStep;
