import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  Divider,
  CircularProgress,
  Autocomplete,
  Chip,
  Typography,
  Stack,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ContentEditor from "./ContentEditor";
import SourcesEditor from "./SourcesEditor";
import endpoints from "../../api/endpoints";

const emptyDoctorCrib = () => ({
  title: "",
  subtitle: "",
  hasWalletCard: false,
  quizUrl: "",
  walletCard: {
    walletCardUrl: "",
    previewImageUrl: "",
    alt: "",
  },
  tags: [],
  incoming: [],
  outgoing: [],
});

const walletCardPresent = (walletCard) =>
  walletCard &&
  (walletCard.walletCardUrl.length > 0 ||
    walletCard.previewImageUrl.length > 0 ||
    walletCard.alt.length);

const DoctorCribEditor = ({
  doctorCrib,
  onCancelClick,
  onSuccess,
  allTags,
}) => {
  const newCrib = !doctorCrib;
  const defaultValues = newCrib
    ? emptyDoctorCrib()
    : {
        ...doctorCrib,
        tags: doctorCrib.tags || [],
        hasWalletCard: walletCardPresent(doctorCrib.walletCard),
        incoming: doctorCrib.incomingQuestions,
        outgoing: doctorCrib.questions,
      };

  const [questionsError, setQuestionsError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const watchHasWalletCard = watch("hasWalletCard");

  const onSubmit = handleSubmit((formData) => {
    const { incoming, outgoing, ratings } = formData;

    if (outgoing.length === 0 && incoming.length === 0) {
      setQuestionsError(true);
      return;
    }

    setIsSubmitting(true);

    const mergedQuestions = outgoing.concat(incoming, ratings);

    if (newCrib) {
      endpoints.doctorCribs
        .create({ ...formData, questions: mergedQuestions })
        .then((response) => {
          setIsSubmitting(false);
          onSuccess(response.data.doctorCrib);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    } else {
      endpoints.doctorCribs
        .update({ ...formData, questions: mergedQuestions, id: doctorCrib.id })
        .then((response) => {
          setIsSubmitting(false);
          onSuccess(response.data.doctorCrib);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  });

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Card sx={{ mb: 10 }}>
        <CardHeader
          title={newCrib ? "Create New Doctor Crib" : "Edit Doctor Crib"}
        />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <TextField
              name="title"
              label="Title"
              variant="outlined"
              aria-label={"Title"}
              {...register("title")}
              error={!!errors.title}
              helperText={errors.title?.message}
              fullWidth
              multiline
            />
            <TextField
              name="subtitle"
              multiline
              label="Subtitle"
              variant="outlined"
              aria-label={"Subtitle"}
              {...register("subtitle")}
              error={!!errors.subtitle}
              helperText={errors.subtitle?.message}
              fullWidth
            />

            <TextField
              name="preparations"
              multiline
              label="What You Need to Prepare"
              variant="outlined"
              aria-label={"What You Need to Prepare"}
              {...register("preparations")}
              error={!!errors.preparations}
              helperText={errors.preparations?.message}
              fullWidth
            />
            <TextField
              name="quizUrl"
              label="Quiz Url"
              variant="outlined"
              aria-label={"Quiz Url"}
              {...register("quizUrl")}
              error={!!errors.quizUrl}
              helperText={errors.quizUrl?.message}
              fullWidth
            />
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  id="tags-autocomplete"
                  value={value}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  options={allTags}
                  freeSolo
                  filterSelectedOptions
                  autoHighlight
                  openOnFocus
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Tags"
                      placeholder="E.g., COVID, Heart Disease"
                    />
                  )}
                />
              )}
            />
            <Accordion expanded={watchHasWalletCard}>
              <AccordionSummary>
                <Controller
                  control={control}
                  name="hasWalletCard"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label="Has Wallet Card"
                    />
                  )}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="walletCard.walletCardUrl"
                      label="Wallet Card URL"
                      variant="outlined"
                      aria-label={"Wallet Card URL"}
                      {...register("walletCard.walletCardUrl")}
                      error={!!errors.walletCardUrl}
                      helperText={errors.walletCardUrl?.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="walletCard.previewImageUrl"
                      label="Preview Image URL"
                      variant="outlined"
                      aria-label={"Preview image URL"}
                      {...register("walletCard.previewImageUrl")}
                      error={!!errors.previewImageUrl}
                      helperText={errors.previewImageUrl?.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="walletCard.alt"
                      label="Alt"
                      variant="outlined"
                      aria-label={"Image alt"}
                      {...register("walletCard.alt")}
                      error={!!errors.walletCardAlt}
                      helperText={errors.walletCardAlt?.message}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Divider />

            <SourcesEditor control={control} register={register} />

            <Divider />

            <Typography variant="h6" gutterBottom>
              Questions to Ask Your Doctor:
            </Typography>
            <ContentEditor
              register={register}
              control={control}
              error={questionsError ? "Please add at least one question" : null}
              name="outgoing"
              errors={errors}
            />

            <Divider />

            <Typography variant="h6" gutterBottom>
              Questions Your Doctor May Ask You:
            </Typography>
            <ContentEditor
              register={register}
              control={control}
              error={questionsError ? "Please add at least one question" : null}
              name="incoming"
              errors={errors}
            />

            <Divider />

            <Typography variant="h6" gutterBottom>
              User Ratings and Reviews for Likely Prescriptions:
            </Typography>
            <ContentEditor
              register={register}
              control={control}
              name="ratings"
              errors={errors}
            />
          </Stack>
        </CardContent>
      </Card>
      <AppBar
        position="fixed"
        color="secondary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              {newCrib ? "Create Crib" : "Update Crib"}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              type="submit"
              size="large"
              onClick={onCancelClick}
            >
              Cancel
            </Button>

            {isSubmitting && <CircularProgress size={30} />}
          </Stack>
        </Toolbar>
      </AppBar>
    </form>
  );
};

export default DoctorCribEditor;
