import {
  Button,
  CircularProgress,
  Typography,
  Box,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TemplateForm from "./TemplateForm";
import TemplateQuestionEditingMessage from "./TemplateQuestionEditingMessage";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100%",
  maxHeight: "100%",
  overflowX: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TemplateModal = ({
  handleClose,
  loading,
  errors,
  register,
  onSubmit,
  questions,
  title,
}) => (
  <Modal open onClose={handleClose}>
    <Box sx={modalStyle}>
      <Card>
        <CardHeader
          title={title}
          action={
            <IconButton onClick={handleClose} disabled={loading}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <TemplateForm errors={errors} register={register} />
        </CardContent>
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle2" gutterBottom>
              Template questions to be saved:
            </Typography>
            {questions}
          </Stack>
        </CardContent>
        <Divider />
        <Divider />
        <CardContent>
          <TemplateQuestionEditingMessage />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            variant="contained"
            disabled={loading}
            endIcon={loading && <CircularProgress size={20} />}
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </CardActions>
      </Card>
    </Box>
  </Modal>
);

export default TemplateModal;
