import React from "react";
import {
  Autocomplete,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import pluralize from "pluralize";
import moment from "moment";

const TemplateLoader = ({ onSelect, templateSelected, templates }) => {
  return templates ? (
    <Autocomplete
      id="template-select"
      getOptionLabel={(opt) => (opt.id ? `${opt.id} - ${opt.name}` : opt)}
      options={templates}
      value={templateSelected || ""}
      autoHighlight
      openOnFocus
      onChange={(e, template) => {
        if (template) {
          onSelect(template);
        }
      }}
      renderOption={(props, template) =>
        template.id ? (
          <MenuItem key={template.id} value={template} {...props}>
            <div>
              <div>
                <strong>
                  {template.id}: {template.name}
                  {template.description ? ` (${template.description})` : null}
                </strong>
              </div>
              <div>
                {pluralize("Question", template.questionIds.length, true)}
                <Typography style={{ color: "grey" }}>
                  Last updated: {moment(template.updatedAt).calendar()}
                </Typography>
              </div>
            </div>
          </MenuItem>
        ) : (
          <MenuItem key="default" value={template} {...props}>
            <em>{template}</em>
          </MenuItem>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Template"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  ) : (
    <Typography style={{ color: "grey" }}>
      Loading Templates... <CircularProgress size={24} />
    </Typography>
  );
};

export default TemplateLoader;
