import React, { useEffect, useState } from "react";
import {
  TextField,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import endpoints from "../../api/endpoints";
import LoadingButton from "../LoadingButton";

const PilotCreateCompany = ({ company = {} }) => {
  const [isSaving, setIsSaving] = useState();
  const history = useHistory();

  const { id, name, subdomain, logoUrl } = company;

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      id,
      name,
      subdomain,
      logoUrl,
    },
  });

  const editedName = watch("name");
  const isSubdomainDirty = dirtyFields.subdomain;
  const alreadyHasSubdomain = !!subdomain;

  useEffect(() => {
    if (editedName && !isSubdomainDirty && !alreadyHasSubdomain) {
      const hyphenatedName = editedName.replaceAll(" ", "-").toLowerCase();

      setValue("subdomain", hyphenatedName);
    }
  }, [editedName, isSubdomainDirty, alreadyHasSubdomain]);

  const onSubmit = handleSubmit((values) => {
    setIsSaving(true);
    endpoints.pilot
      .createCompany(values)
      .then(() => {
        setIsSaving(false);

        history.push(`/pilot`);
      })
      .catch((err) => {
        setIsSaving(false);
        const errors = _.get(err, "response.data.errors");

        if (errors) {
          window.alert(
            `Could not create company:\n\n${Object.keys(errors).map(
              (key) => `${key}: ${errors[key]}\n\n`
            )}`
          );
        } else if (err.response?.data?.message) {
          window.alert(err.response.data.message);
        } else {
          window.alert(err);
        }
      });
  });

  return (
    <Card raised>
      <CardHeader
        title={<Typography variant="h6">Now Let's Name Your Team!</Typography>}
      />
      <Divider />
      <form onSubmit={onSubmit} style={{ maxWidth: 750, height: "100%" }}>
        <CardContent>
          <Stack spacing={3}>
            <TextField
              autoFocus
              name="name"
              label="Organization Name"
              variant="outlined"
              {...register("name", {
                required: "Organization Name is Required",
              })}
              aria-label={"Organization Name"}
              fullWidth={true}
              error={!!errors.name}
              helperText={errors.name?.message}
            />

            <LoadingButton type="submit" loading={isSaving}>
              Submit
            </LoadingButton>
          </Stack>
        </CardContent>
      </form>
    </Card>
  );
};

export default PilotCreateCompany;
