import React, { useState, useContext } from "react";
import { Stack, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import _ from "lodash";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "../../LoadingButton";
import CompanyName from "./CompanyName";
import Employees from "./Employees";
import ROI from "./ROI";
import OwnerRegistration from "./OwnerRegistration";
import api from "../../../api/endpoints";
import { context } from "../../../AppContext";

const Autopilot = ({ onCreate }) => {
  const {
    state: { authenticated: isAuthenticated },
  } = useContext(context);

  const [step, setStep] = useState(0);
  const [isSaving, setIsSaving] = useState();

  const getValidations = (step) => {
    const stepsFields = [
      ["companyName"],
      ["numEmployees"],
      ["participationRate"],
      ["email", "password", "passwordConfirmation"],
    ];

    const fields = [];

    for (let i = 0; i <= step && i < stepsFields.length; i++) {
      fields.push(...stepsFields[i]);
    }

    return fields;
  };

  const {
    handleSubmit,
    register,
    trigger,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numEmployees: 50,
      participationRate: 15,
    },
  });

  const companyName = watch("companyName");
  const employees = watch("numEmployees");

  const getEmailBody = () => `
    ${companyName} has ${employees} employees and spouses and wants to learn more about volume discounts.
  `;

  const getMonthlyCost = () => {
    // const participationRate = watch("participationRate");

    // const PRICE_PER_QUIZ = 3;
    const PEPM = 1;

    return employees * PEPM;
  };

  const goToNextStep = () => {
    const fields = getValidations(step);

    trigger(fields).then((isValid) => {
      if (isValid) {
        setStep(step + 1);
      }
    });
  };

  const isLastStep = step === (isAuthenticated ? 2 : 3);

  function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
  }

  const onSubmit = handleSubmit((values) => {
    if (isLastStep) {
      setIsSaving(true);

      const keys = Object.keys(values);
      const formattedValues = {};

      keys.forEach((key) => {
        formattedValues[camelToSnakeCase(key)] = values[key];
      });

      api.companies
        .createAutopilot(formattedValues)
        .then((response) => {
          setIsSaving(false);

          onCreate(response.data);
        })
        .catch((error) => {
          setIsSaving(false);

          if (error.response?.data?.errors) {
            // Display errors to the user or handle them as needed
            window.alert(
              error.response?.data?.errors
                ?.map((error) => {
                  return `${error.record}\n${error.messages.join("\n")}`;
                })
                .join("\n\n")
            );
          } else {
            window.alert(
              `An error occurred: ${error}. Please try again later.`
            );
          }
        });
    } else {
      goToNextStep();
    }
  });

  return (
    <Box>
      <form onSubmit={onSubmit} style={{ width: "100%" }}>
        <Stack spacing={2}>
          <CompanyName register={register} errors={errors} />
          {step > 0 && (
            <Employees control={control} companyName={companyName} />
          )}
          {step > 1 && (
            <ROI monthlyCost={getMonthlyCost()} emailBody={getEmailBody()} />
          )}
          {step > 2 && !isAuthenticated ? (
            <OwnerRegistration
              register={register}
              errors={errors}
              watch={watch}
            />
          ) : null}

          <LoadingButton
            loading={isSaving}
            startIcon={<NavigateNextIcon />}
            type="submit"
          >
            {isLastStep ? "Let's Get Going!" : `Next Step`}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default Autopilot;
