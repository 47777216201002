import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import { move } from "ramda";
import { Controller } from "react-hook-form";

import QuestionsAutocomplete from "../QuestionsAutocomplete";
import DnDQuestionsList from "../DnDQuestionsList";

const Questions = ({
  setEditQuestion,
  control,
  repeatQuestionsWarning,
  ...rest
}) => {
  function handleDragEnd(result, onChange, currentQuestions) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const questions = move(
      result.source.index,
      result.destination.index,
      currentQuestions
    );

    return onChange(questions);
  }

  return (
    <Card>
      <CardHeader
        title="Quiz Questions"
        subheader="Add quiz questions by ID or name one at a time, or paste in a comma separated string of Question IDs"
      />
      <Divider />
      <CardContent>
        <Controller
          control={control}
          name="questions"
          rules={{
            required: "Select at least one question",
          }}
          render={({
            field: { onChange, value, ref },
            formState: { errors },
          }) => (
            <>
              <QuestionsAutocomplete
                parentRef={ref}
                value={value}
                errors={errors}
                onChange={(_, value) => onChange(value)}
                {...rest}
              />
              {value?.length > 0 && (
                <>
                  <Divider />
                  <CardContent>
                    <DnDQuestionsList
                      repeatQuestionsWarning={repeatQuestionsWarning}
                      questions={value}
                      onRemove={(qToRemove) =>
                        onChange(value.filter((q) => q !== qToRemove))
                      }
                      onDragEnd={(result) => {
                        handleDragEnd(result, onChange, value);
                      }}
                      setEditQuestion={setEditQuestion}
                    />
                  </CardContent>
                </>
              )}
            </>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default Questions;
