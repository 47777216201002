import React from "react";
import { Controller, useFieldArray } from "react-hook-form";

import {
  Button,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import _ from "lodash";

const PlanAndGroupIds = ({ register, control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "accountNumbers",
  });

  return (
    <>
      <Stack spacing={2}>
        <TextField
          label="Plan Carrier"
          variant="outlined"
          fullWidth
          {...register("carrier", {
            required: "Required",
          })}
          placeholder="ABCD1234"
          error={Boolean(_.get(errors, `carrier`))}
          helperText={_.get(errors, `carrier.message`)}
        />

        <TextField
          label="Group #"
          variant="outlined"
          fullWidth
          {...register("groupNumber", {
            required: "Required",
          })}
          placeholder="ABCD1234"
          error={Boolean(_.get(errors, `groupNumber`))}
          helperText={_.get(errors, `groupNumber.message`)}
        />

        <Typography variant="h6">Applicable Account Numbers:</Typography>

        {fields.map((field, index) => (
          <div key={field.id} style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Account #"
              variant="outlined"
              fullWidth
              placeholder="ABCD1234"
              {...register(`accountNumbers.${index}.value`, {
                required: "Required",
              })}
            />
            <Tooltip title="Delete">
              <IconButton type="button" onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ))}

        <Button
          startIcon={<AddIcon />}
          type="button"
          onClick={() => append({ value: "" })}
        >
          Add an Account #
        </Button>
      </Stack>
    </>
  );
};

export default PlanAndGroupIds;
