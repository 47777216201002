import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    rigth: 0,
    bottom: 0,
  },
  "@keyframes spinner": {
    from: {
      transform: "rotateY(0deg)",
    },
    to: {
      transform: "rotateY(-360deg)",
    },
  },
  brand: {
    marginTop: "-2em",
    fontWeight: "700 !important",
    fontFamily: "Didot !important",
    "animation-name": "$spinner",
    "animation-timing-function": "linear",
    "animation-iteration-count": "infinite",
    "animation-duration": "4s",
    "transform-style": "preserve-3d",
    "text-align": "center",
  },
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      classes={{ container: classes.container }}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <Typography color="primary" variant="h1" className={classes.brand}>
          Q
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Loader;
