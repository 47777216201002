import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import _ from "lodash";
import queryString from "query-string";

import PageContainer from "../../PageContainer";
import { default as api } from "../../../api/endpoints";
import { Loader } from "../../Loader";
import ReviewHistory from "./ReviewHistory";
import QuestionReviewRow from "./QuestionReviewRow";

const filters = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Requested",
    value: "requested",
  },
  {
    label: "Approved Conditionally",
    value: "approved_conditionally",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Not applicable",
    value: "not_applicable",
  },
];

const RequesterDashboard = () => {
  const { filter } = queryString.parse(window.location.search);

  const [loading, setLoading] = useState(true);
  const [questionReviews, setQuestionReviews] = useState([]);
  const [currentUserId, setCurrentUserId] = useState();
  const [currentFilter, setCurrentFilter] = useState(
    filter || filters[0].value
  );
  const [reviewHistoryId, setReviewHistoryId] = useState();

  useEffect(() => {
    setLoading(true);
    api.questionReview.all({ currentFilter }).then(({ data }) => {
      setQuestionReviews(data.questions);
      setCurrentUserId(data.current_user.id);
      setLoading(false);
    });
  }, [currentFilter]);

  const handleFilterChange = (e) => {
    setCurrentFilter(e.target.value);

    window.history.replaceState(
      {},
      document.title,
      `${window.location.origin}${window.location.pathname}?filter=${e.target.value}`
    );
  };

  const filteredQuestionReviews = questionReviews.filter(
    (questionReview) => questionReview.status === currentFilter
  );

  const saveUpdates = (updates) => {
    const updatedReviews = questionReviews.map((review) =>
      review.id === updates.id
        ? {
            ...review,
            ...updates,
          }
        : review
    );

    setQuestionReviews(updatedReviews);
  };

  const handleReviewRequest = (questionReviewId) => {
    api.questionReview.actions
      .requestReview({ id: questionReviewId, comment: null })
      .then(({ data }) => saveUpdates(data.updates));
  };

  const handleMarkAsNotApplicable = (questionReviewId) => {
    api.questionReview.actions
      .markAsNotApplicable({ id: questionReviewId, comment: null })
      .then(({ data }) => saveUpdates(data.updates));
  };

  return (
    <PageContainer>
      {reviewHistoryId && (
        <ReviewHistory
          id={reviewHistoryId}
          handleClose={() => setReviewHistoryId()}
          onUpdate={(data) => saveUpdates(data.updates)}
        />
      )}
      <Box sx={{ height: "calc(100vh - 120px)" }}>
        <ToggleButtonGroup
          color="primary"
          value={currentFilter}
          exclusive
          onChange={handleFilterChange}
        >
          {filters.map(({ label, value }) => (
            <ToggleButton value={value} key={value}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <TableContainer
          component={Paper}
          sx={{ height: "100%", overflowY: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Review Status</TableCell>
                <TableCell>Last Modified At</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Harvard Approved</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>Custom For</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Loader />
            ) : (
              <TableBody>
                {filteredQuestionReviews.map((questionReview) => (
                  <QuestionReviewRow
                    key={questionReview.id}
                    questionReview={questionReview}
                    onReviewRequest={handleReviewRequest}
                    onMarkAsNotApplicable={handleMarkAsNotApplicable}
                    currentFilter={currentFilter}
                    currentUserId={currentUserId}
                    handleReviewHistoryClick={setReviewHistoryId}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </PageContainer>
  );
};

export default RequesterDashboard;
