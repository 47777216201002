import React, { useState, useEffect } from "react";
import PageContainer from "../PageContainer";
import CompanySelect from "../CompanySelect";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Breadcrumbs,
  Link as BreadcrumbLink,
  Typography,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from "@mui/material";
import { Link } from "react-router-dom";
import { default as api } from "../../api/endpoints";
import Layout from "./Layout";

const EmailDeliveryRow = ({ emailDelivery, company }) => {
  const stats = emailDelivery.stats;

  return (
    <TableRow>
      <TableCell>
        <Link to={`/email-manager/deliveries/${company.id}/${emailDelivery.id}`}>
          { emailDelivery.id }
        </Link>
      </TableCell>
      <TableCell>{ emailDelivery.email_type }</TableCell>
      <TableCell>{ emailDelivery.started_at }</TableCell>
      <TableCell>{ emailDelivery.status }</TableCell>
      <TableCell>{ stats.all }</TableCell>
      <TableCell>{ stats.delivered }</TableCell>
      <TableCell>{ stats.opened }</TableCell>
      <TableCell>{ stats.clicked }</TableCell>
      <TableCell>{ stats.failed }</TableCell>
    </TableRow>
  );
};

const EmailDeliveriesTable = ({ emailDeliveries, company }) => {
  const ROWS_PER_PAGE = 20;
  const [page, setPage] = useState(0);

  const handleChangePage = (e, newPage) => setPage(newPage);

  if (company === null) {
    return <Alert severity="info">Please select company</Alert>;
  }

  if (emailDeliveries.length === 0) {
    return <Alert severity="warning">No email delivery yet</Alert>;
  }

  const visibleEmailDeliveries = emailDeliveries.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Started at</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>All</TableCell>
              <TableCell>Delivered</TableCell>
              <TableCell>Opened</TableCell>
              <TableCell>Clicked</TableCell>
              <TableCell>Failed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              visibleEmailDeliveries.map((emailDelivery) => <EmailDeliveryRow emailDelivery={emailDelivery} company={company} key={emailDelivery.id} />)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        count={emailDeliveries.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
};

const EmailDeliveries = () => {
  const [company, setCompany] = useState(null);
  const [emailDeliveries, setEmailDeliveries] = useState([]);

  useEffect(() => {
    if (company) {
      api.emails.deliveries({ companyId: company.id }).then(({ data }) => {
        setEmailDeliveries(data.email_deliveries);
      })
    }
  }, [company]);

  const handleCompanySelect = (company) => setCompany(company);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Deliveries" />
            <CardContent>
              <CompanySelect onChange={handleCompanySelect} value={company} />

              <EmailDeliveriesTable company={company} emailDeliveries={emailDeliveries} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EmailDeliveries;
