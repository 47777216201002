import React, { useEffect, useState } from "react";
import _ from "lodash";

import PageContainer from "../PageContainer";
import DoctorCribsList from "./DoctorCribsList";
import DoctorCribEditor from "./DoctorCribEditor";
import { default as api } from "../../api/endpoints";

const DoctorCribsManager = () => {
  const [doctorCribs, setDoctorCribs] = useState([]);
  const [pageType, setPageType] = useState("list");
  const [toEditId, setToEditId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.doctorCribs
      .list()
      .then((response) => {
        setDoctorCribs(response.data.doctorCribs);
        setLoading(false);
      })
      .catch((err) => {
        window.alert(err);
        setLoading(false);
      });
  }, []);

  const allTags = doctorCribs.reduce((acc, cur) => _.union(acc, cur.tags), []);

  const handleCancelClick = () => setPageType("list");
  const handleNewClick = () => setPageType("new");
  const handleEditClick = (id) => {
    setPageType("edit");
    setToEditId(id);
  };
  const handleSuccess = (doctorCrib) => {
    if (toEditId) {
      const index = doctorCribs.findIndex(
        (doctorCrib) => doctorCrib.id === toEditId
      );
      doctorCribs[index] = doctorCrib;
      setDoctorCribs([...doctorCribs]);
    } else {
      setDoctorCribs([...doctorCribs, doctorCrib]);
    }
    setToEditId(null);
    setPageType("list");
  };
  const handleDeleteSuccess = (id) => {
    setDoctorCribs(doctorCribs.filter((doctorCrib) => doctorCrib.id !== id));
  };
  const handleReorder = (doctorCribs) => {
    setDoctorCribs(doctorCribs);
  };

  const renderPage = () => {
    switch (pageType) {
      case "new":
        return (
          <DoctorCribEditor
            allTags={allTags}
            doctorCrib={null}
            onCancelClick={handleCancelClick}
            onSuccess={handleSuccess}
          />
        );

      case "edit":
        return (
          <DoctorCribEditor
            allTags={allTags}
            doctorCrib={doctorCribs.find((dc) => dc.id === toEditId)}
            onCancelClick={handleCancelClick}
            onSuccess={handleSuccess}
          />
        );

      default:
        return (
          <DoctorCribsList
            doctorCribs={doctorCribs.map((c) => ({
              ...c,
              needsAction: c.sources.length < 1,
            }))}
            onNewClick={handleNewClick}
            onEditClick={handleEditClick}
            onDeleteSuccess={handleDeleteSuccess}
            onReorder={handleReorder}
            loading={loading}
          />
        );
    }
  };

  return <PageContainer>{renderPage()}</PageContainer>;
};

export default DoctorCribsManager;
