import { useContext } from "react";
import {
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Container,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import api from "../../api/endpoints";
import { setAuthToken } from "../../api/api-core";

import { context, actions } from "../../AppContext";
import AppContainer from "../AppContainer";
import EmailInput from "../EmailInput";
import LoadingButton from "../LoadingButton";

const PilotSignup = () => {
  const { dispatch } = useContext(context);
  const history = useHistory();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit((values) => {
    api.pilot
      .selfSignupAdmin(values)
      .then(({ data: { user_profile, pilot_admin_auth_token } }) => {
        setAuthToken(pilot_admin_auth_token);
        dispatch({
          type: actions.loginPilotOwner,
          data: {
            authenticated: true,
            pilotOwner: true,
            userProfile: user_profile,
          },
        });
        history.push("/pilot/create-company");
      })
      .catch((error) => window.alert(error));
  });

  return (
    <AppContainer>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: {
            xs: 0,
          },
        }}
      >
        <Card raised>
          <CardHeader
            title="Create Your Account"
            subheader="As the Pilot leader you'll decide who else participates and see the reports"
          />
          <Divider />
          <CardContent>
            <form onSubmit={onSubmit}>
              <Stack spacing={2}>
                <TextField
                  label={"First Name"}
                  variant="outlined"
                  type="text"
                  {...register("first_name", {
                    required: "Required",
                  })}
                  aria-label={"First Name"}
                  fullWidth
                  error={!!errors.first_name}
                  helperText={errors.first_name && errors.first_name.message}
                />
                <TextField
                  label={"Last Name"}
                  variant="outlined"
                  type="text"
                  {...register("last_name", {
                    required: "Required",
                  })}
                  aria-label={"Last Name"}
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name && errors.last_name.message}
                />
                <EmailInput register={register} errors={errors} />
                <TextField
                  label={"Password"}
                  variant="outlined"
                  type="password"
                  {...register("password", {
                    required: "You must enter a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  aria-label={"Password"}
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                />
                <LoadingButton
                  variant="contained"
                  loading={isSubmitting}
                  type="submit"
                >
                  Submit
                </LoadingButton>
                {errors.login && (
                  <Typography color="error">{errors.login.message}</Typography>
                )}
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </AppContainer>
  );
};

export default PilotSignup;
