import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { default as api } from "../../api/endpoints";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ImageUploader = ({ onUpload, path = "/" }) => (
  <FilePond
    allowRevert={false}
    allowMultiple={false}
    server={{
      process: function (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort
      ) {
        const key = path + Date.now() + "_" + file.name;

        api.images
          .upload({ key, file })
          .then(
            ({
              data: {
                image: { id, url },
              },
            }) => {
              onUpload({ id, url });
              load(key);
            }
          )
          .catch((err) => {
            error("Something went wrong");
            console.error(err);
          });
      },
    }}
    name="files"
    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
  />
);

export default ImageUploader;
