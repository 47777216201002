import { useState, useEffect } from "react";
import {
  Drawer,
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  Divider,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AddNote from "./AddNote";
import Note from "./Note";
import api from "../../api/endpoints";

const Notes = ({ companyId, handleClose, onNotesChange }) => {
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    if (companyId) {
      setLoading(true);

      api.notes
        .index({ company_id: companyId })
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadError(err.message);
          setLoading(false);
        });
    }
  }, [companyId]);

  const handleDeleteNote = (id) => {
    const notes = data.notes.filter((note) => note.id !== id);

    setData({
      ...data,
      notes,
    });

    onNotesChange(companyId, notes.length > 0);
  };

  const handleSaveNote = (note, type) => {
    if (type === "create") {
      const notes = [note, ...data.notes];

      setData({
        ...data,
        notes,
      });

      onNotesChange(companyId, notes.length > 0);
    }

    if (type === "edit") {
      setData({
        ...data,
        notes: data.notes.map((n) => (n.id === note.id ? note : n)),
      });
    }
  };

  const renderContent = () => {
    if (loading) return <CircularProgress />;

    if (loadError)
      return (
        <>
          <Typography>Error:</Typography>
          <Typography color="error">{loadError}</Typography>
        </>
      );

    const hasNotes = data?.notes?.length > 0;

    if (hasNotes)
      return (
        <Stack spacing={2}>
          <AddNote companyId={companyId} onSaveNote={handleSaveNote} />
          {data.notes.map((note) => (
            <Note
              key={note.id}
              {...note}
              onDeleteNote={handleDeleteNote}
              onSaveNote={handleSaveNote}
            />
          ))}
        </Stack>
      );

    return (
      <Stack spacing={2}>
        <AddNote companyId={companyId} onSaveNote={handleSaveNote} />
        <Typography>No notes yet</Typography>
      </Stack>
    );
  };

  return (
    <Drawer open={!!companyId} onClose={handleClose} anchor="right">
      <Card
        sx={{
          height: "100%",
          width: 750,
          maxWidth: "100%",
        }}
      >
        <CardHeader
          title={data?.company?.name ? `${data.company.name} > Notes` : null}
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[200],
            overflowY: "auto",
            height: "calc(100% - 100px)",
          })}
        >
          {renderContent()}
        </CardContent>
      </Card>
    </Drawer>
  );
};

export default Notes;
