import React, { useContext, useState } from "react";
import { Snackbar, Container, CircularProgress, Alert } from "@mui/material";
import _ from "lodash";
import AppContainer from "./AppContainer";
import { context } from "../AppContext";
import Terms from "./Terms";
import SetPassword from "./SetPassword";
import { PilotCreateCompany } from "./";

const Content = ({ userProfile }) => {
  const {
    email,
    needs_action: { needs_terms, needs_password },
  } = userProfile;
  if (needs_terms) {
    return <Terms />;
  }

  if (needs_password) {
    return <SetPassword email={email} />;
  }

  return <PilotCreateCompany />;
};

const PilotPostPayment = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const { state } = useContext(context);

  const { userProfile } = state;

  if (!userProfile) return <CircularProgress />;

  return (
    <AppContainer>
      <Container maxWidth="sm" sx={{ height: "100%" }}>
        <Snackbar
          open={showWelcome}
          autoHideDuration={7000}
          onClose={() => setShowWelcome(false)}
        >
          <Alert onClose={() => setShowWelcome(false)} severity="success">
            Welcome, {userProfile.display_name || userProfile.email}! Thank you
            so much for trying the Quizzify Pilot.
          </Alert>
        </Snackbar>

        <Content userProfile={userProfile} />
      </Container>
    </AppContainer>
  );
};

export default PilotPostPayment;
