import React from "react";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Stack,
} from "@mui/material";
import EmailInput from "../../EmailInput";

const OwnerRegistration = ({ register, errors, watch }) => (
  <Card>
    <CardHeader title="Finally, let's get your admin account set up" />
    <Divider />
    <CardContent>
      <Stack spacing={2}>
        <EmailInput
          register={register}
          errors={errors}
          label="Your Work Email"
          autoFocus
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          {...register("password", {
            required: "Required",
          })}
          aria-label={"Password"}
          fullWidth
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          label="Password Confirmation"
          variant="outlined"
          type="password"
          {...register("passwordConfirmation", {
            required: "Required",
            validate: {
              mustMatchPassword: (value) =>
                value === watch("password") ||
                "Password Confirmation Doesn't Match",
            },
          })}
          aria-label={"Password Confirmation"}
          fullWidth
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
        />
      </Stack>
    </CardContent>
  </Card>
);

export default OwnerRegistration;
