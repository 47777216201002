import React from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  loginContainer: {
    width: "20rem",
  },
}));

const Login = ({ onLogin }) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Card className={classes.loginContainer}>
        <CardHeader title="Log In" />
        <Divider />
        <CardContent>
          <LoginForm onLogin={onLogin} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
