import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Fade,
  Divider,
  TextField,
  AppBar,
  Toolbar,
} from "@mui/material";
import { htmlToText } from "html-to-text";
import { stateToHTML } from "draft-js-export-html";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { default as api } from "../../api/endpoints";
import { Loader } from "../Loader";
import PageContainer from "../PageContainer";
import RichTextField from "./RichTextField";
import BulletListEditor from "./BulletListEditor";
import { htmlToState } from "./helpers";

const PreventConsentEditor = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [payload, setPayload] = useState(null);
  const [header, setHeader] = useState(null);
  const [initialHeader, setInitialHeader] = useState(null);
  const [bullets, setBullets] = useState([]);

  useEffect(() => {
    api.preventConsentContent.get().then(({ data }) => {
      setPayload(data.payload);
      setInitialHeader(htmlToState(data.header));
      setBullets(
        data.bullets.map(({ content, pass_content }) => ({
          contentHtml: content,
          contentText: htmlToText(content),
          contentPass: pass_content,
        }))
      );
      setIsLoading(false);
    });
  }, []);

  const handleHeaderChange = (editorState) => {
    setHeader(stateToHTML(editorState.getCurrentContent()));
  };

  const handleBulletsChange = (newBullets) => {
    setBullets(newBullets);
  };

  const handleSubmit = () => {
    const params = {
      payload,
      header,
      bullets: bullets.map(({ contentHtml, contentPass }) => ({
        content: contentHtml,
        pass_content: contentPass,
      })),
    };

    setIsSubmitting(true);
    api.preventConsentContent.update(params).then(() => {
      setIsSubmitting(false);
      setIsSaved(true);

      setInterval(() => setIsSaved(false), 5000);
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageContainer>
        <Card sx={{ marginBottom: 5 }}>
          <CardHeader title="Prevent Consent Editor" />
          <Divider />
          <CardContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Payload"
                    name="payload"
                    value={payload}
                    fullWidth
                    multiline
                    onChange={(e) => setPayload(e.target.value)}
                    helperText="Note that changing this language does not automatically change Apple Wallet Passes."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs="12">
                      <RichTextField
                        label="Header"
                        name="header"
                        value={initialHeader}
                        onChange={handleHeaderChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <BulletListEditor
                    bullets={bullets}
                    onChange={handleBulletsChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </PageContainer>
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Fade in={isSaved} sx={{ marginTop: 1 }}>
                  <DoneOutlineIcon />
                </Fade>
              </Grid>
            </Grid>
          </CardActions>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PreventConsentEditor;
