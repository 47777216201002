import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Button,
  Breadcrumbs,
  Typography,
  Link as BreadcrumbLink,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useForm, Controller } from "react-hook-form";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { default as api } from "../../api/endpoints";
import PageContainer from "../PageContainer";
import EmailSummary from "./EmailSummary";
import PreviousEmailUsagePrompt from "./PreviousEmailUsagePrompt";
import ControlledAutocomplete from "./ControlledAutocomplete";
import { Loader } from "../Loader";
import Layout from "./Layout";
import muiTheme from "../../mui-theme";

const theme = createTheme(muiTheme);

const useStyles = makeStyles({
  messageField: {
    "& #mui-rte-root": {
      minHeight: 192,
      padding: "0 16px",
    },
  },
});

const bodyToState = (body) => {
  const blocksFromHtml = convertFromHTML(body);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  return JSON.stringify(convertToRaw(contentState));
};

const userGroupOptions = [
  {
    value: "self",
    label: "Only me",
  },
  {
    value: "all",
    label: "All users",
  },
  {
    value: "finished",
    label: "Users who finished quiz",
  },
  {
    value: "not_started",
    label: "Users who did not started quiz",
  },
  {
    value: "new",
    label: "New users",
  },
  {
    value: "inactive",
    label: "Inactive users",
  },
];

const valueToUserSelectorLabel = (userSelector) =>
  userGroupOptions.find(({ value }) => value === userSelector).label;

const SendEmail = () => {
  const inputRef = useRef(null);
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [bodyValue, setBodyValue] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [requireQuiz, setRequireQuiz] = useState(false);
  const [previousEmail, setPreviousEmail] = useState(null);
  const [showPreviousEmailUsagePrompt, setShowPreviousEmailUsagePrompt] =
    useState(false);
  const [quizOptions, setQuizOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [summary, setSummary] = useState({
    company: null,
    userSelector: null,
    quiz: null,
    subject: null,
    message: null,
    sampleEmails: [],
  });
  const [showSummary, setShowSummary] = useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyId: "",
      userSelector: "only_me",
      quizId: "",
    },
  });

  const companyId = watch("companyId");
  const userSelector = watch("userSelector");

  useEffect(() => {
    setQuizOptions([]);
    setValue("quizId", "");

    if (!companyId) {
      return;
    }

    setIsLoading(true);

    api.emails.companyDetails({ companyId: companyId.id }).then(({ data }) => {
      setQuizOptions(data.quizzes);
      setPreviousEmail(data.previous_on_demand_email);
      setIsLoading(false);
    });
  }, [companyId]);

  useEffect(() => {
    if (companyId && companyId.value !== 0 && previousEmail) {
      setShowPreviousEmailUsagePrompt(true);
    }
  }, [companyId, previousEmail]);

  useEffect(() => {
    const hasCompanySelected = companyId && companyId.id !== 0;
    const userSelectorRequiresQuiz =
      userSelector &&
      (userSelector === "finished" || userSelector === "not_started");

    setRequireQuiz(hasCompanySelected && userSelectorRequiresQuiz);
  }, [companyId, userSelector]);

  useEffect(() => {
    api.emails.generalData().then((response) => {
      const {
        data: { companies, quizzes_per_company, previous_on_demand_emails },
      } = response;
      setCompanyOptions(companies);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleMessageChange = (editorState) => {
    setMessage(stateToHTML(editorState.getCurrentContent()));
  };

  const onSubmit = handleSubmit((values) => {
    const { companyId, userSelector, quizId, subject } = values;

    api.emails
      .targetDetails({
        companyId: companyId && companyId.id,
        audience: userSelector,
        quizId: quizId && quizId.value,
      })
      .then((response) => {
        setSummary({
          company: { id: companyId.id, name: companyId.label },
          userSelector: {
            value: userSelector,
            label: valueToUserSelectorLabel(userSelector),
          },
          quiz: { id: quizId && quizId.value, name: quizId && quizId.label },
          subject,
          message,
          emails: {
            allCount: response.data.emails.all_count,
            sample: response.data.emails.sample,
          },
        });
        setShowSummary(true);
      });
  });

  const handleSummarySubmit = ({
    company,
    userSelector,
    quiz,
    subject,
    message,
  }) => {
    setIsSubmitting(true);
    api.emails
      .sendEmail({
        company_id: company.id,
        audience: userSelector.value,
        quiz_id: quiz.id,
        subject,
        body: message,
      })
      .then(({ data }) => {
        setIsSubmitting(false);

        history.push(`/email-manager/deliveries/${data.company_id}/${data.id}`);
        setIsSent(true);
      });
  };

  const handlePreviousEmailUsagePromptClose = () =>
    setShowPreviousEmailUsagePrompt(false);
  const handlePreviousEmailUsagePromptConfirm = () => {
    setShowPreviousEmailUsagePrompt(false);

    const previous = previousEmail;

    setValue("userSelector", previous.audience);
    setValue("subject", previous.subject);
    setBodyValue(bodyToState(previous.body));

    if (previous.quiz_id) {
      const selectedQuizOption = quizOptions.find(
        (quizOption) => quizOption.value === previous.quiz_id
      );

      setTimeout(() => {
        setValue("quizId", selectedQuizOption);
      }, 200); // delayed to re-render form and make field quizId available
    } else {
      setValue("quizId", null);
    }
  };

  return (
    <Layout>
      <EmailSummary
        open={showSummary}
        summary={summary}
        isSubmitting={isSubmitting}
        isSent={isSent}
        onClose={() => setShowSummary(false)}
        onSubmit={handleSummarySubmit}
      />
      <PreviousEmailUsagePrompt
        open={showPreviousEmailUsagePrompt}
        previous={companyId && companyId.id && previousEmail}
        userGroupOptions={userGroupOptions}
        onClose={handlePreviousEmailUsagePromptClose}
        onConfirm={handlePreviousEmailUsagePromptConfirm}
      />

      <form onSubmit={onSubmit}>
        <Card>
          <CardHeader title="Send email" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} alignItems="center">
                <ControlledAutocomplete
                  name="companyId"
                  label="Company"
                  options={companyOptions}
                  control={control}
                  rules={{ required: true }}
                  error={errors.companyId}
                  helperText={errors.companyId?.message}
                />
              </Grid>
              <Grid item xs={12} alignItems="center">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="userSelector"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={errors.userSelector}>
                      <InputLabel id="users-selector-label">
                        Users selector
                      </InputLabel>
                      <Select
                        labelId="users-selector-label"
                        id="users-selector"
                        label="Users selector"
                        name="userSelector"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                      >
                        {userGroupOptions.map(({ value, label }) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              {requireQuiz ? (
                <Grid item xs={12} alignItems="center">
                  <ControlledAutocomplete
                    name="quizId"
                    label="Quiz"
                    options={quizOptions}
                    control={control}
                    rules={{ required: true }}
                    error={errors.quizId}
                    helperText={errors.quizId?.message}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} alignItems="center">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="subject"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Subject"
                      value={value}
                      onChange={onChange}
                      error={errors.subject}
                      helperText={errors.subject?.message}
                      InputLabelProps={{ shrink: !!value }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                alignItems="center"
                className={styles.messageField}
              >
                <Paper elevation={1}>
                  <ThemeProvider theme={theme}>
                    <MUIRichTextEditor
                      label="Message"
                      ref={inputRef}
                      controls={[
                        "bold",
                        "italic",
                        "strikethrough",
                        "link",
                        "quote",
                        "numberList",
                        "bulletList",
                      ]}
                      onChange={handleMessageChange}
                      key={companyId?.id}
                      value={bodyValue}
                      autoFocus
                    />
                  </ThemeProvider>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              Send email
            </Button>
            <Button
              component={Link}
              to="/email-manager"
              variant="contained"
              color="secondary"
              sx={{ marginLeft: 1 }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default SendEmail;
