import React from "react";
import moment from "moment";

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";

const Profile = ({
  name,
  description,
  startTime,
  endTime,
  closedAfterEndTime,
}) => (
  <Card>
    <CardHeader title={name} />
    <CardContent>
      <Stack spacing={2}>
        <Box>
          <Typography variant="caption">Description</Typography>
          <Typography variant="subtitle1">{description}</Typography>
        </Box>

        <Divider />

        <Box>
          <Typography variant="caption">Timeline</Typography>
          <List>
            <ListItem>
              <Box width={40}>
                <ListItemText primary="Start" />
              </Box>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText
                primary={startTime ? moment(startTime).calendar() : "Not set"}
              />
            </ListItem>
            <ListItem>
              <Box width={40}>
                <ListItemText primary="End *" />
              </Box>
              <ListItemIcon>
                <EventBusyIcon />
              </ListItemIcon>
              <ListItemText
                primary={endTime ? moment(endTime).calendar() : "Not set"}
              />
            </ListItem>
            <ListItem>
              <ListItemText>
                {closedAfterEndTime
                  ? "* Quiz stops being available after end time"
                  : "* Quiz remains available after end time"}
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

export default Profile;
