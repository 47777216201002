import { useState, useEffect } from "react";
import { Card, Divider, CardHeader, CardContent } from "@mui/material";
import UsersPanel from "./UsersPanel";
import api from "../../../api/endpoints";

const Users = () => {
  const [users, setUsers] = useState();
  const [showAddUser, setShowAddUser] = useState();

  useEffect(() => {
    if (!showAddUser) {
      api.pilot.getUsers().then(({ data: { users } }) => {
        setUsers(users);
      });
    }
  }, [showAddUser]);

  const title = users ? `Your Users` : "Loading Users";

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <UsersPanel
            users={users}
            showAddUser={showAddUser}
            setShowAddUser={setShowAddUser}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Users;
