import { useState } from "react";
import {
  TextField,
  Alert,
  Button,
  Stack,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import Papa from "papaparse";

import DetailDrawer from "../DetailDrawer";
import CompanySelect from "../CompanySelect";
import LoadingButton from "../LoadingButton";
import { API_ENDPOINT } from "../../api/api-core";

const UsersUpload = ({ handleClose, onUpload }) => {
  const [file, setFile] = useState();
  const [company, setCompany] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const step = data?.step;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const regex = /\.csv$/;

    if (!regex.test(file.name)) {
      alert("Invalid file. Please upload a CSV file.");
      event.target.value = null;
      return;
    }

    // Parse the file
    Papa.parse(file, {
      complete: function (results) {
        if (results.errors.length > 0) {
          alert("The file could not be parsed as a CSV.");
          event.target.value = null;
        } else {
          setFile(file);
        }
      },
    });
  };

  const handleSubmit = async (event) => {
    setSubmitting(true);
    setError();

    event.preventDefault();

    if (!file) {
      setError("Please choose a file to upload.");
      setSubmitting(false);
      return;
    }

    if (!company) {
      setError("Please choose a company.");
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem("authToken");

      const url =
        step === "allow_upload"
          ? `${API_ENDPOINT}/admin/user_rosters/upload`
          : `${API_ENDPOINT}/admin/user_rosters/preview_columns`;

      const response = await axios({
        method: "post",
        url,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        params: {
          company_id: company.id,
        },
      });

      if (!response.status === 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setData(response.data);
      setSubmitting(false);

      // onUpload(response.data.users_created);
    } catch (error) {
      if (error.response?.data.type === "CSV") {
        setError("There was a problem with the csv file and it was not saved");
      } else if (error.response?.data.type === "missing-email") {
        setError(
          `We were not able to find an Email column. Columns processed are: \n${error.response?.data.headers.join(
            ", "
          )}. Please edit the file and try again.`
        );
      } else if (error.response?.data?.error) {
        setError(error.response?.data?.error);
      } else {
        setError(`Something went wrong: ${error.message}`);
      }
      setSubmitting(false);
    }
  };

  const renderStep = (step) => {
    if (step === "results") {
      return (
        <>
          <Alert>Upload completed</Alert>
          <Typography>
            Total Rows: {data?.total_rows} (including header)
          </Typography>
          <Typography>
            Users to Create: {data?.new_users_to_import.length}
          </Typography>
          <Typography>
            Users Created: {data?.users_created.succeeded.length}
          </Typography>
          <Typography>
            User Creation Failed ({data?.users_created.failed.length}):
          </Typography>
          {data?.users_created && (
            <ul>
              {data.users_created.failed?.map((user) => (
                <li key={`${user.first_name} ${user.last_name}`}>
                  {user.first_name} {user.last_name} - {user.email}
                </li>
              ))}
            </ul>
          )}
          <Typography>User Updates: {data.users_updated.length}</Typography>
          <Typography>
            Users Processed With No Update:{" "}
            {data?.users_processed_but_not_updated.length}
          </Typography>
        </>
      );
    }

    if (step === "allow_upload") {
      return (
        <>
          <Divider />
          <Alert severity="warning">
            This is a sample of some rows from the file to verify that columns
            are labelled correctly. Please review and confirm.
          </Alert>
          <Table>
            <TableHead>
              <TableRow>
                {data?.presentable_mapping.map((column) => (
                  <TableCell key={column}>
                    {column.target}
                    {column.original ? (
                      <>
                        <br />
                        <Typography variant="caption">
                          {` (Mapped from ${column.original})`}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.column_sample.map((user) => (
                <TableRow key={Object.values(user).join("-")}>
                  {data?.presentable_mapping.map((column) => {
                    const value = user[column.target];

                    return <TableCell key={value}>{value}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {error && <Alert severity="error">{error}</Alert>}

          <LoadingButton
            type="submit"
            disabled={!file || !company}
            loading={submitting}
          >
            Confirm Upload
          </LoadingButton>
        </>
      );
    }

    return (
      <>
        {error && <Alert severity="error">{error}</Alert>}

        <LoadingButton
          type="submit"
          disabled={!file || !company}
          loading={submitting}
        >
          Preview Upload
        </LoadingButton>
      </>
    );
  };

  return (
    <DetailDrawer
      open
      title="Upload User Roster"
      handleClose={handleClose}
      width={1000}
    >
      <CardContent>
        <Card>
          <CardContent sx={{ pb: 15 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <Alert severity="info">
                  The upload uses email as the primary key to match existing and
                  new users. So the email address will remain constant and other
                  fields will be adjusted as needed. If the email addresses
                  change or are duplicated you should not use this tool.
                </Alert>
                <Box
                  sx={
                    step === "allow_upload"
                      ? {
                          display: "none",
                        }
                      : {}
                  }
                >
                  <Stack spacing={2}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="CSV File"
                      type="file"
                      helperText="Fields accepted are currently Email, First Name, Last Name, Employee ID, and Group. However if there are other fields you don't have to remove them, and if the names of the fields are slightly off you can try the preview without changing the file first and sort it out in the next step. Companies with advanced configurations for upload should not be processed through this tool."
                      name="userList"
                      onChange={handleFileChange}
                    />
                    <CompanySelect
                      value={company}
                      onChange={(company) => {
                        setCompany(company);
                      }}
                    />
                  </Stack>
                </Box>

                {renderStep(step)}
              </Stack>
            </form>
          </CardContent>
        </Card>
      </CardContent>
    </DetailDrawer>
  );
};

export default UsersUpload;
