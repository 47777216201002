import { Stack, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

const LastCommentStatus = ({ status }) => {
  if (!status) return null;

  if (status === "yours")
    return (
      <Stack direction="row" spacing={1}>
        <CommentIcon />
        <Typography>You left comments</Typography>
      </Stack>
    );

  return (
    <Stack direction="row" spacing={1}>
      <CommentIcon color="warning" />
      <Typography>You received comments</Typography>
    </Stack>
  );
};

export default LastCommentStatus;
