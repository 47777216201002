import {
  Card,
  CardContent,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";

const UsersList = ({ users }) => {
  if (!users) return <CircularProgress />;

  if (users.length === 0)
    return <Typography>No users yet. Invite some please!</Typography>;

  return users.map((user) => (
    <Card key={user.id} raised>
      <CardContent>
        <Stack spacing={1}>
          <Typography variant="h6">{user.display_name}</Typography>
          <Typography>{user.email}</Typography>
          <Typography>Lessons Completed: {user.games_completed}</Typography>
        </Stack>
      </CardContent>
    </Card>
  ));
};

export default UsersList;
