import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";

import { default as api } from "../../../api/endpoints";

import { Loader } from "../../Loader";
import PageContainer from "../../PageContainer";
import QuestionReviewRow from "./QuestionReviewRow";
import QuestionDetails from "./QuestionDetails";

const ReviewerDashboard = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [questionDetailId, setQuestionDetailId] = useState();

  useEffect(() => {
    api.questionReview
      .reviewable()
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => window.alert(err));
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (data?.questions?.length === 0) {
    return <EmptyReviewerDashboard />;
  }

  return (
    <PageContainer sx={{ height: "100%" }}>
      {questionDetailId && (
        <QuestionDetails
          id={questionDetailId}
          handleClose={() => setQuestionDetailId()}
          onSubmit={({ updates }) => {
            const updatedQuestions = data.questions.map((question) =>
              question.id === updates.id
                ? {
                    ...question,
                    ...updates,
                  }
                : question
            );

            setData({
              ...data,
              questions: updatedQuestions,
            });
          }}
        />
      )}

      <Card sx={{ height: "100%" }}>
        <CardHeader
          title="Question Review Inbox"
          action={
            <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() => {
                api.questionReview
                  .downloadTextDocument(data.questions.map((q) => q.id))
                  .then(({ data }) => {
                    const link = document.createElement("a");
                    const file = new Blob([data], { type: "text/plain" });
                    link.href = URL.createObjectURL(file);
                    const date = moment();

                    link.download = `review_questions_${date.format(
                      "YYYY-MM-DD_hh-mm_A"
                    )}.txt`;
                    link.click();
                    URL.revokeObjectURL(link.href);

                    setLoading(false);
                  })
                  .catch((err) => window.alert(err));
              }}
            >
              Download as Text Document
            </Button>
          }
        />
        <Divider />
        <CardContent sx={{ height: "75%" }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Review Status</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Last change at</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.questions.map((questionReview) => (
                  <QuestionReviewRow
                    key={questionReview.id}
                    currentUserId={data?.current_user.id}
                    questionReview={questionReview}
                    onClickProcess={() =>
                      setQuestionDetailId(questionReview.id)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default ReviewerDashboard;
