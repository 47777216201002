import { useState, useEffect } from "react";
import {
  Drawer,
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Create from "./Create";

const CreateCompanyDrawer = ({ open, handleClose, onCreate }) => (
  <Drawer open={open} onClose={handleClose} anchor="right">
    <Card
      sx={{
        height: "100%",
        width: 900,
        maxWidth: "100%",
        paddingTop: "64px",
      }}
    >
      <CardHeader
        title="Create a New Company"
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[200],
          overflowY: "auto",
          height: "calc(100% - 64px)",
        })}
      >
        <Create onCreate={onCreate} />
      </CardContent>
    </Card>
  </Drawer>
);

export default CreateCompanyDrawer;
