import { useState } from "react";
import { Stack, Typography, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import api from "../../../../../api/endpoints";
import LoadingButton from "../../../../LoadingButton";
import Results from "./Results";

const formPlaceholder = `email_one@example.com\nemail_two@example.com\nemail_three@example.com`;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const defaultMessage = `Please join me in trying the Quizzify Pilot, where we can improve our health literacy together!`;

const InviteUsers = ({ onClose }) => {
  const [list, setList] = useState("");
  const [error, setError] = useState();
  const [message, setMessage] = useState(defaultMessage);
  const [submitting, setSubmitting] = useState(false);
  const [results, setResults] = useState();

  const handleSend = () => {
    setError();

    if (list.includes(",")) {
      setError("Do not use commas, use new lines to separate emails");
    } else {
      const items = list.split("\n").filter((item) => item);

      const invalidEmails = items.filter((email) => !EMAIL_REGEX.test(email));

      if (invalidEmails.length) {
        setError(
          `The following emails are invalid: ${invalidEmails.join(", ")}`
        );
      } else {
        setSubmitting(true);
        api.pilot
          .inviteParticipants({ emails: items, message })
          .then(({ data }) => {
            setSubmitting(false);
            setResults(data);
            setList("");
          })
          .catch((err) => {
            window.alert(err);
            setSubmitting(false);
          });
      }
    }
  };

  if (results) {
    return <Results results={results} onClose={onClose} />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Enter Emails to Invite</Typography>
      <Typography>
        Enter one or more emails, one per line, do not use commas
      </Typography>
      <TextField
        multiline
        label="Emails to Invite to Pilot"
        rows={5}
        placeholder={formPlaceholder}
        value={list}
        onChange={(e) => setList(e.target.value)}
        error={error}
        helperText={error}
      />
      <TextField
        multiline
        label="Greeting for Email"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <LoadingButton
        startIcon={<SendIcon />}
        onClick={handleSend}
        loading={submitting}
      >
        Send Invites
      </LoadingButton>
      <Button variant="outlined" onClick={onClose}>
        Cancel
      </Button>
    </Stack>
  );
};

export default InviteUsers;
