import AppContainer from "../AppContainer";
import Drawer from "./Drawer";

const Home = () => {
  return (
    <AppContainer>
      <Drawer />
    </AppContainer>
  );
};

export default Home;
