import {
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import pluralize from "pluralize";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";

const QuizzesChanged = ({ title, quizzes, inQuizManager }) => {
  const history = useHistory();

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title={title}
        subheader={`Count: ${pluralize("Quiz", quizzes.length, true)}`}
      />
      <Divider />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              {!inQuizManager && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {quizzes.map((q) => (
              <TableRow key={q.id}>
                <TableCell>{q.id}</TableCell>
                <TableCell>{q.name}</TableCell>
                <TableCell>{q.company.name}</TableCell>
                {!inQuizManager && (
                  <TableCell>
                    <Tooltip title="Manage Quiz" aria-label="manage quiz">
                      <IconButton
                        onClick={() => {
                          history.push(`/quizzes/${q.id}`);
                        }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Preview" aria-label="preview">
                      <IconButton
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_GAME_APP_URL}/quiz/${q.id}`
                          );
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      <Divider />
      <CardActions>
        <Button onClick={() => window.location.reload()}>Create New</Button>
      </CardActions>
    </Card>
  );
};

export default QuizzesChanged;
