import { Button, Toolbar, AppBar, Stack, Typography } from "@mui/material";
import LoadingButton from "../../LoadingButton";

const Controls = ({
  isLoading,
  isDirty,
  editingMode,
  templateButton,
  onRevert,
}) => (
  <AppBar position="fixed" color="gray" sx={{ top: "auto", bottom: 0 }}>
    <Toolbar>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isLoading || !isDirty}
            loading={isLoading}
          >
            {editingMode ? "Save Quiz" : "Create Quiz"}
          </LoadingButton>
          {isDirty ? (
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRevert();
              }}
            >
              Revert
            </Button>
          ) : null}

          {isDirty ? (
            <Typography
              sx={(theme) => ({
                alignSelf: "center",
                color: theme.palette.error.main,
              })}
            >
              Unsaved changes
            </Typography>
          ) : null}

          {templateButton}
        </Stack>
      </Stack>
    </Toolbar>
  </AppBar>
);

export default Controls;
