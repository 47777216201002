import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { stateToHTML } from "draft-js-export-html";
import RichTextField from "./RichTextField";
import { htmlToState } from "./helpers";

const InPlaceBulletEditor = ({
  isEdited,
  index,
  bullet,
  onUpdate,
  onCancel,
}) => {
  const [currentContent, setCurrentContent] = useState(null);
  const [currentPassContent, setCurrentPassContent] = useState(null);
  const [initialContent, setInitialContent] = useState(undefined);
  const [initialPassContent, setInitialPassContent] = useState(undefined);

  useEffect(() => {
    setInitialContent(htmlToState(bullet.contentHtml));
    setInitialPassContent(htmlToState(bullet.contentPass || ""));
  }, [bullet.contentHtml, bullet.contentPass]);

  if (!isEdited) {
    return bullet.contentText;
  }

  const handleContentChange = (editorState) =>
    setCurrentContent(editorState.getCurrentContent());
  const handlePassContentChange = (editorState) =>
    setCurrentPassContent(editorState.getCurrentContent());

  const handleAcceptClick = () => {
    onUpdate({
      contentText: currentContent.getPlainText(),
      contentHtml: stateToHTML(currentContent),
      contentPass:
        currentPassContent.getPlainText().trim().length > 0
          ? stateToHTML(currentPassContent)
          : "",
    });
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <RichTextField
              label="Edit item - web version"
              name="edit_bullet_item"
              onChange={handleContentChange}
              value={initialContent}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <RichTextField
              label="Edit item - pass version (empty if same as web)"
              name="edit_bullet_item"
              onChange={handlePassContentChange}
              value={initialPassContent}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={{ marginTop: 4, marginLeft: 1 }}>
        <IconButton onClick={handleAcceptClick}>
          <CheckSharpIcon />
        </IconButton>
        <IconButton onClick={onCancel}>
          <ClearSharpIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const BulletList = ({ bullets, onDelete, onUpdate }) => {
  const [editIndex, setEditIndex] = useState(null);

  const handleEditClick = (index) => setEditIndex(index);
  const handleEditCancel = () => setEditIndex(null);
  const handleUpdate = (index) => (newValue) => {
    onUpdate(index, newValue);
    setEditIndex(null);
  };

  return (
    <TableContainer sx={{ marginTop: 2 }}>
      <Typography variant="h6">Bullets</Typography>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "80%" }}>Content</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bullets.length > 0 ? (
            bullets.map((bullet, index) => (
              <TableRow>
                <TableCell sx={{ width: "80%" }}>
                  <InPlaceBulletEditor
                    bullet={bullet}
                    index={index}
                    isEdited={editIndex === index}
                    onUpdate={handleUpdate(index)}
                    onCancel={handleEditCancel}
                    key={index}
                  />
                </TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(index)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => onDelete(index)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="2">---</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BulletListEditor = ({ bullets, onChange }) => {
  const [newBulletContent, setNewBulletContent] = useState(null);
  const [newBulletPassContent, setNewBulletPassContent] = useState(null);
  const [currentKey, setCurrentKey] = useState(1);

  const handleDelete = (index) => {
    const newBullets = bullets.slice(0, index).concat(bullets.slice(index + 1));
    onChange(newBullets);
  };
  const handleUpdate = (index, bullet) => {
    const newBullets = bullets
      .slice(0, index)
      .concat([bullet])
      .concat(bullets.slice(index + 1));
    onChange(newBullets);
  };
  const handleNewBulletContentChange = (editorState) =>
    setNewBulletContent(editorState.getCurrentContent());
  const handleNewBulletPassContentChange = (editorState) =>
    setNewBulletPassContent(editorState.getCurrentContent());
  const handleClear = () => clearForm();
  const handleSubmit = () => {
    if (!newBulletContent || newBulletContent.getPlainText().length === 0) {
      return;
    }

    const bullet = {
      contentText: newBulletContent.getPlainText(),
      contentHtml: stateToHTML(newBulletContent),
      contentPass:
        newBulletPassContent.getPlainText().trim().length > 0
          ? stateToHTML(newBulletPassContent)
          : "",
    };

    onChange(bullets.concat([bullet]));
    clearForm();
  };

  const clearForm = () => {
    setNewBulletContent(null);
    setNewBulletPassContent(null);
    setCurrentKey(currentKey + 1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BulletList
                  bullets={bullets}
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <RichTextField
                      label="New item - web version"
                      name="new_bullet_item"
                      onChange={handleNewBulletContentChange}
                      key={`content-${currentKey}`}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <RichTextField
                      label="New item - pass version (empty if same as web)"
                      name="new_bullet_pass_item"
                      onChange={handleNewBulletPassContentChange}
                      key={`pass-content-${currentKey}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={handleClear}>
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default BulletListEditor;
