import { useState, useEffect } from "react";
import { Box, Tabs, Tab, CircularProgress } from "@mui/material";

import TabPanel from "../../TabPanel";
import Summary from "../../QuizReport/Summary";
import Table from "../../QuizReport/Table";
import Preview from "../../QuizReport/Preview";
import EditQuiz from "../../QuizReport/EditQuiz";
import api from "../../../api/endpoints";
import DetailDrawer from "../DetailDrawer";

const QuizDetail = ({ quiz, handleClose }) => {
  const id = quiz?.id;

  const [tab, setTab] = useState(0);
  const [summary, setSummary] = useState();
  const [table, setTable] = useState();
  const [preview, setPreview] = useState();
  const [updatePreview, setUpdatePreview] = useState();

  useEffect(() => {
    if (id) {
      api.reports.quiz.summary.get({ id }).then(({ data }) => {
        setSummary(data);
      });
      api.reports.quiz.table.get({ id }).then(({ data }) => {
        setTable(data);
      });
      api.reports.quiz.preview.get({ id }).then(({ data }) => {
        setPreview(data);
      });
    }
  }, [id]);

  useEffect(() => {
    if (updatePreview) {
      api.reports.quiz.preview.get({ id }).then(({ data }) => {
        setPreview(data);
        setUpdatePreview(false);
      });
    }
  }, [id, updatePreview]);

  if (!id) return null;

  return (
    <DetailDrawer open title="Quiz Manager" handleClose={handleClose}>
      <Box position="static">
        <Tabs
          value={tab}
          onChange={(ev, newValue) => {
            setTab(newValue);
          }}
          aria-label="report tabs"
        >
          <Tab label="Summary" />
          <Tab label="Full Report" />
          <Tab label="Preview Quiz" />
          <Tab label="Edit Quiz" />
        </Tabs>
      </Box>

      <div
        style={{
          padding: 24,
          width: "100%",
        }}
      >
        <TabPanel value={tab} index={0}>
          {summary ? <Summary {...summary} /> : <CircularProgress />}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {table ? <Table {...table} id={id} /> : <CircularProgress />}
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Preview {...preview} />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <EditQuiz id={id} onFinishEditing={() => setUpdatePreview(true)} />
        </TabPanel>
      </div>
    </DetailDrawer>
  );
};

export default QuizDetail;
