import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Message = ({ open, onClose, message }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={onClose}
    message={message}
    action={
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    }
  />
);

export default Message;
