import { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Autocomplete,
  Typography,
  Grid
} from "@mui/material";
import moment from "moment";
import PageContainer from "./PageContainer";
import { Loader } from "./Loader";
import { default as api } from "../api/endpoints";

const CompanySelect = ({searchCompany, companies, onChange}) => {
  if (companies.length < 2) {
    return null;
  }

  return (
    <Autocomplete
      sx={{ maxWidth: 500, mt: 2, mb: 2 }}
      id="company_id-autocomplete"
      value={searchCompany}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      options={companies}
      freeSolo
      autoHighlight
      openOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search Company"
        />
      )}
      />
  )
}

const Activity = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [searchEmployeeId, setSearchEmployeeId] = useState("");
  const [searchCompany, setSearchCompany] = useState(null);

  useEffect(() => {
    setLoading(true);

    api.activity
      .list({ employee_id: searchEmployeeId, company_id: searchCompany?.id })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [searchEmployeeId, searchCompany?.id]);

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return "Error";
  }

  return (
    <PageContainer title="Activity Log" subtitle="Quiz Completions">
      <Grid container>
        <Grid item xs="6">
          <Autocomplete
            sx={{ maxWidth: 500, mt: 2, mb: 2 }}
            id="employee-ids-autocomplete"
            value={searchEmployeeId}
            onChange={(_, newValue) => {
              setSearchEmployeeId(newValue);
            }}
            options={data.employee_ids}
            freeSolo
            autoHighlight
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Search Employee ID"
                placeholder="ABC123"
              />
            )}
          />
        </Grid>
        <Grid item xs="6">
          <CompanySelect companies={data.companies} onChange={(value) => setSearchCompany(value)} searchCompany={searchCompany} />
        </Grid>
      </Grid>
      <Typography sx={{ fontStyle: "italic" }}>
        Showing First {data.webhook_results.length} Results
      </Typography>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>User Email</TableCell>
              <TableCell>User Employee ID</TableCell>
              <TableCell>Quiz Name</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Times Completed</TableCell>
              <TableCell>Webhook Result Code</TableCell>
              <TableCell>Completed At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.webhook_results.map((d) => (
              <TableRow key={d.id}>
                <TableCell>{d.user_id}</TableCell>
                <TableCell sx={{ wordBreak: "break-word" }}>
                  {d.user_email}
                </TableCell>
                <TableCell sx={{ wordBreak: "break-word" }}>
                  {d.user_employee_id}
                </TableCell>
                <TableCell>{d.quiz_name}</TableCell>
                <TableCell>{d.game_score}</TableCell>
                <TableCell>{d.game_number}</TableCell>
                <TableCell>{d.res_code}</TableCell>
                <TableCell>
                  {moment(d.updated_at).format("M/D/YY h:mm A")}
                </TableCell>
              </TableRow>
            ))}
            {data.webhook_results.length === 0 && (
              <TableRow>
                <TableCell sx={{ fontStyle: "italic" }}>
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default Activity;
