import { Stack, TextField } from "@mui/material";

const TemplateForm = ({ errors, register }) => (
  <Stack spacing={2}>
    <TextField
      label="Name"
      variant="outlined"
      {...register("name", {
        required: "You must name your template",
      })}
      fullWidth
      error={!!errors.name}
      placeholder="Nicotine (No Humor)"
      helperText={errors.name?.message}
    />
    <TextField
      label="Description"
      variant="outlined"
      {...register("description")}
      fullWidth
      error={!!errors.description}
      placeholder="For new clients who don't want any color/humor"
      helperText={errors.name?.description}
    />
  </Stack>
);

export default TemplateForm;
