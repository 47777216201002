import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import _ from "lodash";
import moment from "moment";
import Scores from "./Scores";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: "calc(100vh - 212px)",
  },
}));

const getMaxNumScores = (rows) =>
  rows.reduce((maxScores, row) => Math.max(maxScores, row.scores.length), 0);

const getGamesCompleted = (row) =>
  row.scores.filter((score) => score.complete).length;

const packageData = (data) =>
  data
    .map((e) =>
      e
        .map((content) =>
          typeof content === "string" && content.includes(",")
            ? `"${content}"`
            : content
        )
        .join(",")
    )
    .join("\n");

const packageCsv = (data, id) => {
  const csv = packageData(data);

  const hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = `quizzify-quiz-${id}-user-data-${moment().format(
    "YYYY-MM-DD"
  )}.csv`;
  hiddenElement.click();
};

const QuizTable = ({ rows, description, id }) => {
  const classes = useStyles();
  const numScores = getMaxNumScores(rows);

  const headers = [
    "Employee ID",
    "Email",
    "First Name",
    "Last Name",
    "Games Completed",
    "Game 1 Score",
    "Game 2 Score",
  ];

  if (numScores > 2) {
    headers.push("Game 3+ Scores");
  }

  const data = rows.map((row) => {
    const sortedScores = row.scores.sort((a, b) => a.number - b.number) || [];
    return {
      ...row,
      gamesCompleted: getGamesCompleted(row),
      gameOne: _.get(sortedScores, "[0].points", ""),
      gameTwo: _.get(sortedScores, "[1].points", ""),
      otherGames: sortedScores
        .filter((s) => s.number > 2)
        .map((s) => s.points)
        .join(", "),
    };
  });

  return (
    <Paper>
      <Box p={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              startIcon={<SaveAltIcon />}
              onClick={() => {
                const csvData = [headers].concat(
                  data.map((d) => [
                    d.employee_id,
                    d.email,
                    d.first_name,
                    d.last_name,
                    d.gamesCompleted,
                    d.gameOne,
                    d.gameTwo,
                    d.otherGames,
                  ])
                );

                packageCsv(csvData, id);
              }}
            >
              Download Data
            </Button>
          </Grid>
          <Grid item>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item>
            <Typography>Total Rows: {rows.length}</Typography>
          </Grid>
        </Grid>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.employee_id}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.first_name}</TableCell>
                <TableCell>{row.last_name}</TableCell>
                <TableCell>{row.gamesCompleted}</TableCell>
                <Scores scores={row.scores} numScores={numScores} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default QuizTable;
