import { Typography } from "@mui/material";
import sanitizeHtml from "../../../utils/sanitizeHtml";

const Prompt = ({ currentQuestion }) => (
  <>
    <Typography variant="h6">Prompt</Typography>

    <Typography
      variant="subtitle1"
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(currentQuestion.prompt, { formatLinks: true }),
      }}
    />
  </>
);

export default Prompt;
