import React from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  TableFooter,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import theme from "../../mui-theme";

import { htmlToText } from "html-to-text";

const getWarning = (list) => {
  const keys = Object.keys(list);

  return keys
    .map(
      (key) =>
        `- ${key}: ${list[key]
          .map(
            ({ id, name, start_date }) =>
              `[ID#${id}] ${name}${
                start_date ? ` (${moment(start_date).calendar()})` : ""
              }`
          )
          .join(", ")}`
    )
    .join("\n");
};

const DnDQuestionsList = React.memo(function QuestionsList({
  questions,
  onRemove,
  onDragEnd,
  setEditQuestion,
  repeatQuestionsWarning,
}) {
  if (!questions.length) return null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" style={{ display: "table" }}>
        {(provided) => (
          <Table size="small" ref={provided.innerRef}>
            <TableHead>
              <TableRow style={{ fontWeight: "bold" }}>
                <TableCell>
                  <ImportExportIcon />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                <TableCell>Custom</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Harvard Approved</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>Format</TableCell>
                <TableCell>Quizzes Using</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((q, i) => {
                return (
                  <Draggable
                    draggableId={q.id.toString()}
                    key={`question-${q.id}`}
                    index={i}
                  >
                    {(provided) => (
                      <>
                        <TableRow
                          key={q.id}
                          hover
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TableCell {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </TableCell>
                          <TableCell>{q.id}</TableCell>
                          <TableCell>
                            {htmlToText(q.prompt)}

                            {repeatQuestionsWarning[q.id] ? (
                              <>
                                <br /> <br />
                                <Typography color="error">
                                  {q.id} also appears in:
                                  <br />
                                  {getWarning(repeatQuestionsWarning[q.id])}
                                </Typography>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell sx={{ color: theme.palette.error.light }}>
                            {q.custom_for}
                          </TableCell>

                          <TableCell>
                            <Typography variant="caption">
                              {moment(q.updated_at).calendar()}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {q.published ? (
                              <CheckCircleIcon color="primary" />
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            {q.harvard_approved ? (
                              <CheckCircleIcon
                                style={{
                                  color: "#A41034",
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>{q.learning_module}</TableCell>
                          <TableCell>{q.format}</TableCell>
                          <TableCell>{q.num_quizzes}</TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            <Tooltip title="Preview" aria-label="preview">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(
                                    `${process.env.REACT_APP_GAME_APP_URL}/quiz?embed=true&questions=${q.id}`
                                  );
                                }}
                              >
                                <OpenInNewIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" aria-label="edit">
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  setEditQuestion(q);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Remove from Quiz"
                              aria-label="remove question"
                            >
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  onRemove(q);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </Draggable>
                );
              })}
            </TableBody>
            <TableFooter>{provided.placeholder}</TableFooter>
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DnDQuestionsList;
