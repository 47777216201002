import React from "react";
import { Grid, Paper, Typography, Divider } from "@mui/material";
import CorrectIndicator from "./CorrectIndicator";
import sanitizeHtml from "../../../utils/sanitizeHtml";

const AnswerItem = ({ answer, lastApproved }) => {
  const approvedAnswer = lastApproved?.answers?.find(
    (approvedAnswer) => approvedAnswer.id === answer.id
  );

  const differentHeader =
    approvedAnswer && approvedAnswer.name !== answer.mainText;
  const differentExplanation =
    approvedAnswer && approvedAnswer.explanation !== answer.feedbackText;

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">Header</Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold" }}
            color={differentHeader ? "error" : ""}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(answer.mainText, { formatLinks: true }),
            }}
          />
          {differentHeader ? (
            <Typography
              variant="subtitle1"
              color={"green"}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(approvedAnswer.name, {
                  formatLinks: true,
                }),
              }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">Feedback / Explanation</Typography>
          <Typography
            variant="subtitle1"
            color={differentExplanation ? "error" : ""}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(answer.feedbackText, { formatLinks: true }),
            }}
          />
          {differentExplanation ? (
            <Typography
              variant="subtitle1"
              color={"green"}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(approvedAnswer.explanation, {
                  formatLinks: true,
                }),
              }}
            />
          ) : null}
        </Grid>
        {answer.images?.map((image) =>
          image ? (
            <Grid item xs={12}>
              <img
                src={image.url}
                alt={image.alt}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          ) : null
        )}
        <Grid item xs={12}>
          <CorrectIndicator isCorrect={answer.isCorrect} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AnswerItem;
