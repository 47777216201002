import React, { useCallback, useEffect, useContext, useState } from "react";
import queryString from "query-string";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";

import { setAuthToken } from "../../api/api-core";
import { context, actions } from "../../AppContext";
import { default as api } from "../../api/endpoints";
import { Loader } from "../Loader";
import Login from "./Login";
import deleteCode from "../../utils/deleteCode";

const Authorization = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(context);

  const location = useLocation();

  const publicPaths = ["/pilot/start", "/pilot/signup"];

  const isPublicPath = publicPaths.includes(location.pathname);

  const handleLogin = (values) =>
    api.auth
      .login(values)
      .then((res) => {
        const { auth_token: authToken } = res.data;
        if (authToken) {
          setAuthToken(authToken);

          const decodedToken = jwt_decode(authToken);

          dispatch({
            type: actions.loadState,
            data: { authenticated: true, decodedToken },
          });
        }

        getProfile();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

  const handleLoginCallback = useCallback(handleLogin, [dispatch]);

  const isAuthenticated = !!state.authenticated;
  const { code } = queryString.parse(window.location.search);

  useEffect(() => {
    if (code) {
      handleLoginCallback({ code }).then(deleteCode).catch(deleteCode);
      // Clear url params should remove the code entirely from the url
    }
  }, [code, handleLoginCallback]);

  const getProfile = () => {
    console.log("Get Profile Ran");

    api.auth
      .getProfile()
      .then(({ data }) =>
        dispatch({
          type: actions.setUserProfile,
          data: {
            userProfile: data.current_user,
          },
        })
      )
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!isAuthenticated && !code) {
      api.auth
        .verify()
        .then(() => {
          dispatch({
            type: actions.login,
          });
          setLoading(false);

          getProfile();
        })
        .catch((err) => {
          if (_.get(err, "response.status") === 401) {
            setLoading(false);
          } else {
            setLoading(false);

            try {
              const { message } = err.toJSON();
              window.alert(
                `An error occurred. Please try again.\n\n${message}`
              );
            } catch {
              window.alert("An error occurred. Please try again.");
            }
          }
        });
    }
  }, [isAuthenticated, handleLoginCallback, dispatch, code]);

  if (isAuthenticated || isPublicPath) {
    return children;
  } else {
    if (code || loading) {
      return <Loader />;
    }

    return <Login onLogin={handleLogin} />;
  }
};

export default Authorization;
