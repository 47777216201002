import { Typography, Alert } from "@mui/material";
import pluralize from "pluralize";

const Result = ({ emails, type }) => {
  let message = "";
  let severity = "";

  if (type === "sent") {
    message = `${pluralize("invites", emails.length, true)} sent successfully:`;
    severity = "success";
  } else if (type === "failed") {
    message = `${pluralize("emails", emails.length, true)} failed to send:`;
    severity = "error";
  } else if (type === "already_accepted") {
    message = `${pluralize(
      "emails",
      emails.length,
      true
    )} already accepted invitations previously:`;
    severity = "warning";
  }

  return (
    <>
      <Alert severity={severity}>{message}</Alert>
      <ul>
        {emails.map((email) => (
          <li key={email}>
            <Typography>{email}</Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Result;
