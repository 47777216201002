import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import History from "../../History";
import Question from "../../Question";
import ReviewForm from "./ReviewForm";
import DetailDrawer from "../../../DetailDrawer";
import { default as api } from "../../../../api/endpoints";

const QuestionDetails = ({ id, handleClose, onSubmit }) => {
  const [question, setQuestion] = useState(null);
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState();
  const [showEditor, setShowEditor] = useState(false);

  const fetchHistoryAndRelated = (id) => {
    api.questionReview.history(id).then(({ data }) => {
      setQuestion(data.question);
      setHistory(data.history);
      setStatus(data.status);
    });
  };

  useEffect(() => {
    if (id) {
      fetchHistoryAndRelated(id);
    }
  }, [id]);

  const handleSubmit = ({ action, comment }) => {
    const handler = api.questionReview.actions[action.handler];

    handler({ id, comment }).then(({ data }) => {
      fetchHistoryAndRelated(id);
      onSubmit(data);
    });
  };

  const handleEditSubmit = (values) => {
    const { id: question_id, comment, answers, learnMoreUrl, prompt } = values;
    const formattedAnswers = answers.map((a) => ({
      id: a.id,
      name: a.mainText,
      explanation: a.feedbackText,
    }));

    api.questionReview.actions
      .approveConditionally({
        id,
        comment,
        approved_update: {
          question_id,
          prompt,
          learnMoreUrl,
          answers: formattedAnswers,
        },
      })
      .then(() => {
        api.questionReview.history(id).then(({ data }) => {
          setQuestion(data.question);
          setHistory(data.history);
          setShowEditor(false);
          onSubmit(data);
        });
      });
  };

  const wasApproved = !!history.find((item) => item.type === "approved");

  return (
    <DetailDrawer
      title={`Question ID: ${id}`}
      open={!!id}
      handleClose={handleClose}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        {!question ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{ mb: 2 }}
              startIcon={<ArrowBackIcon />}
              onClick={handleClose}
            >
              Back to List
            </Button>
            <Grid container spacing={2} sx={{ mb: 8 }}>
              <Grid item xs={8}>
                <Card>
                  <CardContent>
                    <Question
                      question={question}
                      showEditor={showEditor}
                      setShowEditor={setShowEditor}
                      handleEditSubmit={handleEditSubmit}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent>
                    <History history={history} />

                    <ReviewForm
                      status={status}
                      onSubmit={handleSubmit}
                      wasApproved={wasApproved}
                      setShowEditor={setShowEditor}
                      showEditor={showEditor}
                      questionFormat={question.format}
                      onBackToList={handleClose}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </DetailDrawer>
  );
};

export default QuestionDetails;
