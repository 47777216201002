import React, { createContext, useReducer } from "react";
import keyMirror from "keymirror";
import { initialState } from "./stateModel";
import reducer from "./utils/curriedReducer";

const actions = keyMirror({
  loadState: null,
  login: null,
  logout: null,
  setUserProfile: null,
  loginPilotOwner: null,
  acceptTerms: null,
  setPassword: null,
});

const {
  loadState,
  login,
  logout,
  setUserProfile,
  loginPilotOwner,
  acceptTerms,
  setPassword,
} = actions;

const context = createContext(initialState);
const { Provider } = context;

const handlers = {
  [loadState]: (action) => (state) => {
    return {
      ...state,
      ...action.data,
    };
  },
  [login]: () => (state) => ({
    ...state,
    authenticated: true,
  }),
  [loginPilotOwner]: (action) => (state) => ({
    ...state,
    ...action.data,
  }),
  [logout]: () => () => {
    return initialState;
  },
  [setUserProfile]:
    ({ data: { userProfile } }) =>
    (state) => {
      return {
        ...state,
        userProfile,
      };
    },
  [acceptTerms]: () => (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      needs_action: {
        ...state.userProfile.needs_action,
        needs_terms: false,
      },
    },
  }),
  [setPassword]: () => (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      needs_action: {
        ...state.userProfile.needs_action,
        needs_password: false,
      },
    },
  }),
};

const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer(handlers), initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { context, actions, AppStateProvider };
