import { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";

import api from "../../../api/endpoints";

const CompanySelect = ({ onSelect }) => {
  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    api.companies
      .list()
      .then(({ data }) => {
        setCompanies(data.companies);
      })
      .catch((error) => {
        window.alert(error);
      });
  }, []);

  if (!companies) return <CircularProgress />;

  return (
    <FormControl fullWidth>
      <InputLabel id="company-select-label">Company</InputLabel>
      <Select
        labelId="company-select-label"
        id="company-select"
        onChange={(event) => {
          setSelectedCompany(event.target.value);
          onSelect(event.target.value);
        }}
        value={selectedCompany}
        label="Company"
      >
        {companies.map((company) => (
          <MenuItem key={company.id} value={company}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CompanySelect;
