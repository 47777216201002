import React from "react";
import { TableCell } from "@mui/material";

const Scores = ({ scores, numScores }) => {
  const showExtraScores = numScores > 2;
  if (!scores)
    return (
      <>
        <TableCell />
        <TableCell />
        {showExtraScores && <TableCell />}
      </>
    );

  const sortedScores = scores.sort((a, b) => a.number - b.number);

  if (scores.length === 1) {
    return (
      <>
        <TableCell>{sortedScores[0].points}</TableCell>
        <TableCell />
        {showExtraScores && <TableCell />}
      </>
    );
  }

  if (scores.length === 2) {
    return (
      <>
        <TableCell>{sortedScores[0].points}</TableCell>
        <TableCell>{sortedScores[1].points}</TableCell>
        {showExtraScores && <TableCell />}
      </>
    );
  }

  return (
    <>
      <TableCell>{sortedScores[0].points}</TableCell>
      <TableCell>{sortedScores[1].points}</TableCell>
      <TableCell>
        {sortedScores
          .slice(2)
          .map((score) => score.points)
          .join(", ")}
      </TableCell>
    </>
  );
};

export default Scores;
