import { Typography } from "@mui/material";
import sanitizeHtml from "../../../../../../utils/sanitizeHtml";

const AnswerLine = ({ title, body }) => (
  <>
    <Typography variant="caption">{title}</Typography>
    <Typography
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(body, {
          formatLinks: true,
        }),
      }}
    />
  </>
);

export default AnswerLine;
