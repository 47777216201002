import React, { useState, useContext, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Typography, Divider, Stack, Box, Drawer } from "@mui/material";
import pluralize from "pluralize";

import Summary from "./Summary";
import Images from "./Images";
import Time from "./Time";
import Settings from "./Settings";
import Companies from "./Companies";
import Templates from "./Templates";
import Questions from "./Questions";
import Controls from "./Controls";
import Errors from "./Errors";
import Message from "./Message";
import { context } from "../QuizzesContext";
import { default as api } from "../../../api/endpoints";
import QuestionEditorInQuiz from "./QuestionEditorInQuiz";
import CreateOrUpdateTemplate from "./CreateOrUpdateTemplate";

const DEFAULT_START_TIME = moment().hours(3).minutes(0).seconds(0);
const DEFAULT_END_TIME = moment().hours(2).minutes(59).seconds(59);

const standardizeIncomingData = (quiz) => {
  if (!quiz) return quiz;

  const data = {
    startDate: null,
    startTime: DEFAULT_START_TIME,
    endDate: null,
    endTime: DEFAULT_END_TIME,
  };

  const { startTime, endTime, closedAfterEndTime } = quiz;

  if (startTime) {
    data.startDate = moment(startTime);
    data.startTime = moment(startTime);
  }

  if (endTime) {
    data.endDate = moment(endTime);
    data.endTime = moment(endTime);
  }

  if (!closedAfterEndTime) {
    data.closedAfterEndTime = false;
  }

  return {
    ...quiz,
    ...data,
  };
};

const standardizeTimes = (data) => {
  const { startDate, startTime, endDate, endTime } = data;

  const combinedStartTime = startDate
    ? startDate.hours(startTime.hours()).minutes(startTime.minutes()).seconds(0)
    : null;

  const combinedEndTime = endDate
    ? endDate.hours(endTime.hours()).minutes(endTime.minutes()).seconds(0)
    : null;
  // API receives a "startTime" and "endTime" value for the combined date and time.

  delete data.startDate;
  delete data.endDate;

  return {
    ...data,
    startTime: combinedStartTime,
    endTime: combinedEndTime,
  };
};

const CreateOrEditQuiz = ({
  id,
  companyId,
  onFinishEditing = () => {},
  setQuizzesChanged,
}) => {
  const { state } = useContext(context);
  const { quiz, companies: allCompanies = [] } = state;

  const [serverError, setServerError] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const [questionToEdit, setQuestionToEdit] = useState();
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [templateSelected, setTemplateSelected] = useState();
  const [templates, setTemplates] = useState();
  const [questionsChanged, setQuestionsChanged] = useState();
  const [repeatQuestionsLoading, setRepeatQuestionsLoading] = useState();
  const [repeatQuestionsWarning, setRepeatQuestionsWarning] = useState({});

  const editingMode = !!id;

  const initialCompany = allCompanies.find((c) => c.id === Number(companyId));

  const blankValues = {
    name: null,
    description: null,
    startDate: null,
    startTime: DEFAULT_START_TIME,
    endDate: null,
    endTime: DEFAULT_END_TIME,
    companies: initialCompany ? [initialCompany] : [],
    questions: [],
    emailSubject: null,
    emailIntro: null,
    isPublic: false,
    publicResultEmailRecipients: null,
    closedAfterEndTime: false,
    eligible: true,
    bannerImage: null,
  };

  // If editing a quiz start with its values
  const defaultValues = standardizeIncomingData(quiz) || blankValues;

  const { handleSubmit, register, setValue, control, watch, reset, formState } =
    useForm({
      defaultValues,
    });

  const handleReset = (values) => {
    setQuestionsChanged();
    reset(values);
  };

  const { errors, isDirty: isFormDirty } = formState;

  const isDirty = isFormDirty || questionsChanged;

  const beforeUnloadCallback = useCallback(
    (isDirty) => () => {
      if (isDirty) {
        return "Do you really want to leave unsaved changes?";
      }
    },
    []
  );

  useEffect(() => {
    window.onbeforeunload = beforeUnloadCallback(isDirty);

    return () => {
      window.onbeforeunload = () => null;
    };
  }, [beforeUnloadCallback, isDirty]);

  const selectedCompanies = watch("companies");
  const selectedQuestions = watch("questions");

  useEffect(() => {
    const companyIds = selectedCompanies?.map((c) => c.id);
    const questionIds = selectedQuestions.map((q) => q.id);

    if (questionIds.length > 0) {
      setRepeatQuestionsLoading(true);
      api.quizManager.checkForRepeatQuestions
        .get({ companyIds, questionIds, quizId: id })
        .then((res) => {
          setRepeatQuestionsLoading(false);
          setRepeatQuestionsWarning(res.data);
        })
        .catch((err) => {
          window.alert("Failed to checked repeat questions with error: " + err);
          setRepeatQuestionsLoading(false);
        });
    }
  }, [selectedCompanies, selectedQuestions, id]);

  const watchIsPublic = watch("isPublic", false);

  const onSubmit = handleSubmit((initialData) => {
    const updatedData = standardizeTimes(initialData);

    setIsSubmitting(true);

    if (editingMode) {
      api.quizzes
        .update(updatedData)
        .then(() => {
          setSnackbarMessage(`Quiz ${updatedData.id} Saved`);
          setIsSubmitting(false);
          handleReset(initialData);
          onFinishEditing();
        })
        .catch((error) => {
          console.log(error.message);
          setIsSubmitting(false);
          onFinishEditing();
        });
    } else {
      api.quizzes
        .create(updatedData)
        .then((res) => {
          if (res.data.status === "error") {
            setServerError(res.data.message);
          } else {
            const newQuizzes = res.data;
            setSnackbarMessage(
              `${pluralize("Quiz", newQuizzes.length, true)} Created`
            );
            setQuizzesChanged(res.data);
          }
          setIsSubmitting(false);
          handleReset(initialData);
          onFinishEditing();
        })
        .catch((error) => {
          console.error(error);
          setIsSubmitting(false);
          onFinishEditing();
        });
    }
  });

  const handleEditQuestion = (q) => {
    setQuestionToEdit(q);
  };

  return (
    <>
      <Drawer anchor="right" open={!!questionToEdit} sx={{ width: "100%" }}>
        <QuestionEditorInQuiz
          questionToEdit={questionToEdit}
          onCancelEditing={() => setQuestionToEdit()}
          limited
          onFinishEditing={(updatedQuestion) => {
            const currentQuestions = watch("questions");

            // Use the existing id not the updatedQ id as these might change
            const updatedQuestions = currentQuestions.map((q) =>
              q.id === questionToEdit.id ? updatedQuestion : q
            );

            setValue("questions", updatedQuestions);
            setQuestionsChanged(true);

            setSnackbarMessage(
              `Question #${questionToEdit.id} has been replaced by the new custom question #${updatedQuestion.id}. Please remember to Save Quiz to make this change live.`
            );

            setQuestionToEdit();
          }}
        />
      </Drawer>

      <form onSubmit={onSubmit}>
        <Box sx={{ pb: 10 }}>
          {editingMode && <input type="hidden" {...register("id")} />}
          <Stack spacing={2}>
            <Typography variant="h6" gutterBottom>
              {editingMode ? `Edit Quiz ${id}` : "Create New Quiz"}
            </Typography>
            <Summary register={register} errors={errors} />
            <Images control={control} />
            <Time control={control} hasEndDate={watch("endDate")} />
            <Settings
              isPublic={watchIsPublic}
              control={control}
              register={register}
            />
            <Companies
              allCompanies={allCompanies}
              control={control}
              editingMode={editingMode}
            />
            <Templates
              templateSelected={templateSelected}
              setTemplateSelected={setTemplateSelected}
              templates={templates}
              setTemplates={setTemplates}
              setQuestionsValue={(value) => setValue("questions", value)}
            />
            <Questions
              control={control}
              setEditQuestion={handleEditQuestion}
              loading={questionsLoading}
              setLoading={setQuestionsLoading}
              repeatQuestionsWarning={repeatQuestionsWarning}
            />
          </Stack>
          <Divider />
          <Errors serverError={serverError} />
          <Controls
            isLoading={isSubmitting || repeatQuestionsLoading}
            isDirty={isDirty}
            editingMode={editingMode}
            templateButton={
              <CreateOrUpdateTemplate
                {...state}
                selectedQuestions={watch("questions")}
                questionsLoading={questionsLoading}
                setTemplateSelected={setTemplateSelected}
                templateSelected={templateSelected}
                templates={templates}
                setTemplates={setTemplates}
                name={watch("name")}
                description={watch("description")}
              />
            }
            onRevert={handleReset}
          />

          <Message
            open={!!snackbarMessage}
            message={snackbarMessage}
            onClose={() => setSnackbarMessage(null)}
          />
        </Box>
      </form>
    </>
  );
};

export default CreateOrEditQuiz;
