import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import History from "../../History";
import Question from "../../Question";
import ActionsForm from "./ActionsForm";
import DetailDrawer from "../../../DetailDrawer";
import ConditionalApprovalEditor from "./ConditionalApprovalEditor";
import { default as api } from "../../../../api/endpoints";

const ReviewHistory = ({ id, handleClose, onUpdate }) => {
  const [question, setQuestion] = useState(null);
  const [status, setStatus] = useState(null);
  const [history, setHistory] = useState([]);

  const fetchHistoryAndRelated = (id) => {
    api.questionReview.history(id).then(({ data }) => {
      setQuestion(data.question);
      setStatus(data.status);
      setHistory(data.history);
      onUpdate(data);
    });
  };

  const isConditionallyApproved = status === "approved_conditionally";

  useEffect(() => fetchHistoryAndRelated(id), [id]);

  const handleSubmit = ({ action, comment }) => {
    const handler = api.questionReview.actions[action.handler];

    handler({ id, comment }).then(() => fetchHistoryAndRelated(id));
  };

  return (
    <DetailDrawer
      open
      handleClose={handleClose}
      title="Question Review History"
    >
      <CardContent>
        {!question ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ mb: 2 }}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
            <Grid container spacing={4}>
              <Grid item xs={7}>
                {isConditionallyApproved ? (
                  <ConditionalApprovalEditor
                    question={question}
                    history={history}
                    onUpdate={(id) => {
                      fetchHistoryAndRelated(id);
                    }}
                  />
                ) : (
                  <Card>
                    <CardContent>
                      <Question question={question} history={history} />
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={5}>
                <Card>
                  <CardContent>
                    <History history={history} />

                    <Divider />

                    <ActionsForm status={status} onSubmit={handleSubmit} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </DetailDrawer>
  );
};

export default ReviewHistory;
