const cities = `New York, New York
Los Angeles, California
Chicago, Illinois
Houston, Texas
Phoenix, Arizona
Philadelphia, Pennsylvania
San Antonio, Texas
San Diego, California
Dallas, Texas
San Jose, California
Austin, Texas
Jacksonville, Florida
Fort Worth, Texas
Columbus, Ohio
San Francisco, California
Charlotte, North Carolina
Indianapolis, Indiana
Seattle, Washington
Denver, Colorado
Washington, District of Columbia
Boston, Massachusetts
El Paso, Texas
Detroit, Michigan
Nashville, Tennessee
Portland, Oregon
Memphis, Tennessee
Oklahoma City, Oklahoma
Las Vegas, Nevada
Louisville, Kentucky
Baltimore, Maryland
Milwaukee, Wisconsin
Albuquerque, New Mexico
Tucson, Arizona
Fresno, California
Sacramento, California
Kansas City, Missouri
Long Beach, California
Mesa, Arizona
Atlanta, Georgia
Colorado Springs, Colorado
Virginia Beach, Virginia
Raleigh, North Carolina
Omaha, Nebraska
Miami, Florida
Oakland, California
Minneapolis, Minnesota
Tulsa, Oklahoma
Wichita, Kansas
New Orleans, Louisiana
Arlington, Texas
Cleveland, Ohio
Tampa, Florida
Bakersfield, California
Aurora, Colorado
Anaheim, California
Santa Ana, California
Riverside, California
Corpus Christi, Texas
Lexington, Kentucky
Stockton, California
Saint Paul, Minnesota
Cincinnati, Ohio
Henderson, Nevada
Greensboro, North Carolina
Pittsburgh, Pennsylvania
St. Louis, Missouri
Lincoln, Nebraska
Anchorage, Alaska
Plano, Texas
Orlando, Florida
Irvine, California
Newark, New Jersey
Durham, North Carolina
Chula Vista, California
Toledo, Ohio
Fort Wayne, Indiana
St. Petersburg, Florida
Laredo, Texas
Jersey City, New Jersey
Chandler, Arizona
Madison, Wisconsin
Lubbock, Texas
Scottsdale, Arizona
Reno, Nevada
Buffalo, New York
Gilbert, Arizona
Glendale, Arizona
North Las Vegas, Nevada
Winston–Salem, North Carolina
Chesapeake, Virginia
Norfolk, Virginia
Fremont, California
Garland, Texas
Irving, Texas
Hialeah, Florida
Richmond, Virginia
Boise, Idaho
Spokane, Washington
Baton Rouge, Louisiana
Tacoma, Washington
San Bernardino, California
Modesto, California
Fontana, California
Des Moines, Iowa
Moreno Valley, California
Santa Clarita, California
Birmingham, Alabama
Oxnard, California
Fayetteville, North Carolina
Rochester, New York
Port St. Lucie, Florida
Grand Rapids, Michigan
Huntsville, Alabama
Salt Lake City, Utah
Frisco, Texas
Yonkers, New York
Amarillo, Texas
Glendale, California
Huntington Beach, California
McKinney, Texas
Montgomery, Alabama
Augusta, Georgia
Aurora, Illinois
Akron, Ohio
Little Rock, Arkansas
Tempe, Arizona
Columbus, Georgia
Overland Park, Kansas
Grand Prairie, Texas
Tallahassee, Florida
Cape Coral, Florida
Mobile, Alabama
Knoxville, Tennessee
Shreveport, Louisiana
Worcester, Massachusetts
Ontario, California
Vancouver, Washington
Sioux Falls, South Dakota
Chattanooga, Tennessee
Brownsville, Texas
Fort Lauderdale, Florida
Providence, Rhode Island
Newport News, Virginia
Rancho Cucamonga, California
Santa Rosa, California
Peoria, Arizona
Oceanside, California
Elk Grove, California
Garden Grove, California
Pembroke Pines, Florida
Eugene, Oregon
Corona, California
Cary, North Carolina
Springfield, Missouri
Fort Collins, Colorado
Jackson, Mississippi
Alexandria, Virginia
Hayward, California
Lancaster, California
Lakewood, Colorado
Clarksville, Tennessee
Palmdale, California
Salinas, California
Springfield, Massachusetts
Hollywood, Florida
Pasadena, Texas
Sunnyvale, California
Macon, Georgia
Kansas City, Kansas
Pomona, California
Escondido, California
Killeen, Texas
Naperville, Illinois
Joliet, Illinois
Bellevue, Washington
Rockford, Illinois
Savannah, Georgia
Paterson, New Jersey
Torrance, California
Bridgeport, Connecticut
McAllen, Texas
Mesquite, Texas
Syracuse, New York
Midland, Texas
Pasadena, California
Murfreesboro, Tennessee
Miramar, Florida
Dayton, Ohio
Fullerton, California
Olathe, Kansas
Orange, California
Thornton, Colorado
Roseville, California
Denton, Texas
Waco, Texas
Surprise, Arizona
Carrollton, Texas
West Valley City, Utah
Charleston, South Carolina
Warren, Michigan
Hampton, Virginia
Gainesville, Florida
Visalia, California
Coral Springs, Florida
Columbia, South Carolina
Cedar Rapids, Iowa
Sterling Heights, Michigan
New Haven, Connecticut
Stamford, Connecticut
Concord, California
Kent, Washington
Santa Clara, California
Elizabeth, New Jersey
Round Rock, Texas
Thousand Oaks, California
Lafayette, Louisiana
Topeka, Kansas
Simi Valley, California
Fargo, North Dakota
Norman, Oklahoma
Columbia, Missouri
Abilene, Texas
Wilmington, North Carolina
Hartford, Connecticut
Victorville, California
Pearland, Texas
Vallejo, California
Ann Arbor, Michigan
Berkeley, California
Allentown, Pennsylvania
Richardson, Texas
Odessa, Texas
Arvada, Colorado
Cambridge, Massachusetts
Sugar Land, Texas
Beaumont, Texas
Lansing, Michigan
Evansville, Indiana
Rochester, Minnesota
Independence, Missouri
Fairfield, California
Provo, Utah
Clearwater, Florida
College Station, Texas
West Jordan, Utah
Carlsbad, California
El Monte, California
Murrieta, California
Temecula, California
Springfield, Illinois
Palm Bay, Florida
Costa Mesa, California
Westminster, Colorado
North Charleston, South Carolina
Miami Gardens, Florida
Manchester, New Hampshire
High Point, North Carolina
Downey, California
Clovis, California
Pompano Beach, Florida
Pueblo, Colorado
Elgin, Illinois
Lowell, Massachusetts
Antioch, California
West Palm Beach, Florida
Peoria, Illinois
Everett, Washington
Ventura, California
Centennial, Colorado
Lakeland, Florida
Gresham, Oregon
Richmond, California
Billings, Montana
Inglewood, California
Broken Arrow, Oklahoma
Sandy Springs, Georgia
Jurupa Valley, California
Hillsboro, Oregon
Waterbury, Connecticut
Santa Maria, California
Boulder, Colorado
Greeley, Colorado
Daly City, California
Meridian, Idaho
Lewisville, Texas
Davie, Florida
West Covina, California
League City, Texas
Tyler, Texas
Norwalk, California
San Mateo, California
Green Bay, Wisconsin
Wichita Falls, Texas
Sparks, Nevada
Lakewood, New Jersey
Burbank, California
Rialto, California
Allen, Texas
El Cajon, California
Las Cruces, New Mexico
Renton, Washington
Davenport, Iowa
South Bend, Indiana
Edison, New Jersey
Woodbridge, New Jersey
San Angelo, Texas
Kenosha, Wisconsin
Vacaville, California
Roanoke, Virginia
Arvada, Colorado
Orem, Utah
Albany, New York
Sandy, Utah
Athens, Georgia
Camden, New Jersey
Brockton, Massachusetts
Lawton, Oklahoma
Yuma, Arizona
Shawnee, Kansas
Kissimmee, Florida
Schenectady, New York
Beaverton, Oregon
Madera, California
Conroe, Texas
New Bedford, Massachusetts
Chico, California
Auburn, Alabama
Lake Forest, California
Napa, California
Redding, California
Hammond, Indiana
Fayetteville, Arkansas
St. George, Utah
Suffolk, Virginia
Newton, Massachusetts
Reading, Pennsylvania
Norwalk, Connecticut
Wyoming, Michigan
Doral, Florida
Jonesboro, Arkansas
Yakima, Washington
Palm Coast, Florida
Toms River, New Jersey
Troy, Michigan
Gastonia, North Carolina
Union City, California
Cheyenne, Wyoming
Portsmouth, Virginia
Dearborn, Michigan
South Gate, California
Santa Monica, California
Greeley, Colorado
Berwyn, Illinois
Mount Pleasant, South Carolina
Perris, California
Bethlehem, Pennsylvania
Chapel Hill, North Carolina
Goodyear, Arizona
Rancho Cordova, California
Largo, Florida
Farmington Hills, Michigan
Newport Beach, California
Apple Valley, California
Bend, Oregon
Racine, Wisconsin
Merced, California
Lake Charles, Louisiana
Jacksonville, North Carolina
South San Francisco, California
Bismarck, North Dakota
Cranston, Rhode Island
Lynchburg, Virginia
Pasco, Washington
Mount Vernon, New York
Pharr, Texas
Visalia, California
Waukegan, Illinois
Gulfport, Mississippi
Fremont, Ohio
Bryan, Texas
Mission, Texas
Lawrence, Massachusetts
Fishers, Indiana
Palm Desert, California
La Habra, California
San Clemente, California
Flagstaff, Arizona
Federal Way, Washington
North Little Rock, Arkansas
Waltham, Massachusetts
Porterville, California
Carson City, Nevada
Santa Barbara, California
San Marcos, Texas
Haverhill, Massachusetts
Council Bluffs, Iowa
North Lauderdale, Florida
Florence, South Carolina
Prescott, Arizona
Manteca, California
West Allis, Wisconsin
Pacifica, California
Cedar Park, Texas
Sheboygan, Wisconsin
New Britain, Connecticut
Wylie, Texas
Goose Creek, South Carolina
Logan, Utah
Prescott Valley, Arizona
Pittsburg, California
Casa Grande, Arizona
Palm Springs, California
Brookhaven, Georgia
Plainfield, New Jersey
Grand Island, Nebraska
Pocatello, Idaho
Battle Creek, Michigan
Grand Junction, Colorado
Grapevine, Texas
Wheaton, Illinois
Corvallis, Oregon
Atlantic City, New Jersey
Kingsport, Tennessee
Lake Elsinore, California
Portage, Michigan
Moorhead, Minnesota
Pleasanton, California
Sherman, Texas
Anderson, Indiana
Elmhurst, Illinois
Apopka, Florida
Marana, Arizona
Cypress, California
Bowie, Maryland
Cerritos, California
Ceres, California
Coppell, Texas
Aliso Viejo, California
Farmington, Missouri
Annapolis, Maryland
Greenwood, Indiana
Stillwater, Oklahoma
Ottumwa, Iowa
St. Cloud, Florida
Aventura, Florida
Albany, Oregon
Twin Falls, Idaho
Diamond Bar, California
Normal, Illinois
Delano, California
Midland, Michigan
Charlottesville, Virginia
Romeoville, Illinois
San Jacinto, California
Hoboken, New Jersey
York, Pennsylvania
Lompoc, California
Belleville, Illinois
Burlington, North Carolina
Madison, Alabama
Plainfield, Illinois
Placentia, California
Brentwood, Tennessee
Palatine, Illinois
Des Plaines, Illinois
Hoffman Estates, Illinois
Florissant, Missouri
Plattsburgh, New York
Linden, New Jersey
Westfield, Massachusetts
Savage, Minnesota
Bedford, Texas
Campbell, California
Huntersville, North Carolina
Weslaco, Texas
Parker, Colorado
La Quinta, California
Crystal Lake, Illinois
Chesterfield, Missouri
Meridian, Mississippi
Middletown, Ohio
Mount Prospect, Illinois
Coral Gables, Florida
Auto, California
Texarkana, Texas
Monroe, Louisiana
Concord, North Carolina
Galveston, Texas
Castle Rock, Colorado
Casa Grande, Arizona
Maricopa, Arizona
St. Louis Park, Minnesota
Friendswood, Texas
Minnetonka, Minnesota
Methuen, Massachusetts
Rockville, Maryland
Buckeye, Arizona
Reading, Pennsylvania
Fort Myers, Florida
Lakewood, Ohio
Dover, Delaware
Peabody, Massachusetts
Leesburg, Virginia
Lawrence, Indiana
Bonita Springs, Florida
Caldwell, Idaho
Covington, Kentucky
Azusa, California
Keller, Texas
Cambridge, Ohio
Elyria, Ohio
Cedar Hill, Texas
Summerville, South Carolina
Mansfield, Texas
Oak Park, Illinois
El Centro, California
Nicholasville, Kentucky
Kirkland, Washington
Charleston, Illinois
Hickory, North Carolina
Sarasota, Florida
San Luis Obispo, California
Lacey, Washington
Eastvale, California
Brooklyn Park, Minnesota
Bountiful, Utah
Coppell, Texas
Harrisonburg, Virginia
Strongsville, Ohio
Glenview, Illinois
Portage, Michigan
Addison, Texas
Plant City, Florida
Dublin, Ohio
Titusville, Florida
Cutler Bay, Florida
Margate, Florida
Westerville, Ohio
Kingsport, Tennessee
Euless, Texas
Haltom City, Texas
Watsonville, California
Coachella, California
Huntington, Indiana
Cleveland, Tennessee
Draper, Utah
Olympia, Washington
Parker, Colorado
San Juan, Texas
Niagara Falls, New York
Pine Bluff, Arkansas
Texas City, Texas
Hot Springs, Arkansas
Great Falls, Montana
Schertz, Texas
La Crosse, Wisconsin
Grapevine, Texas
Burlington, Iowa
DeSoto, Texas
Hagerstown, Maryland
Prescott, Arizona
Elkhart, Indiana
La Porte, Texas
Moline, Illinois
Plainfield, New Jersey
Leavenworth, Kansas
Mission Viejo, California
St. Cloud, Minnesota
Pacifica, California
Monroe, Louisiana
Temple City, California
Bell Gardens, California
Stanton, California
Pottstown, Pennsylvania
Waterford, Michigan
Roseville, Michigan
Novato, California
Port Huron, Michigan
Westfield, New Jersey
Vineland, New Jersey
Kearny, New Jersey
Bentonville, Arkansas
Brea, California
Covina, California
Rocklin, California
Quincy, Massachusetts
Yorba Linda, California
New Rochelle, New York
Lake Oswego, Oregon
Cheyenne, Wyoming
Gaithersburg, Maryland
Rosemead, California
Oviedo, Florida
Sierra Vista, Arizona
Weymouth Town, Massachusetts
Leesburg, Florida
Melbourne, Florida
St. Clair Shores, Michigan
Cape Girardeau, Missouri
Rock Island, Illinois
Carpentersville, Illinois
Castle Rock, Colorado
Hurst, Texas
Lima, Ohio
Marana, Arizona
Casa Grande, Arizona
Sedalia, Missouri
Merrillville, Indiana
Hutchinson, Kansas
Mooresville, North Carolina
Greenwood, Indiana
Valley Stream, New York
Heroica Nogales, Arizona
St. Charles, Missouri
Easley, South Carolina
Orange, Texas
College Park, Maryland
Jackson, Ohio
New Berlin, Wisconsin
Grand Island, Nebraska
Florissant, Missouri
Grand Junction, Colorado
Texarkana, Texas
Monroe, Louisiana
New Brunswick, New Jersey
Galveston, Texas
Johnstown, Pennsylvania
Cuyahoga Falls, Ohio
Spanish Fork, Utah
La Crosse, Wisconsin
Porterville, California
Bremerton, Washington
Coral Gables, Florida
Texarkana, Texas
Ankeny, Iowa
Burlington, Iowa
DeKalb, Illinois
Ocoee, Florida
St. Louis Park, Minnesota
Friendswood, Texas
Minnetonka, Minnesota
Coral Gables, Florida
Georgetown, Texas
Urbandale, Iowa
Pensacola, Florida
Casa Grande, Arizona
Missoula, Montana
Rock Hill, South Carolina
Jacksonville, Arkansas
Franklin, Wisconsin
Hallandale Beach, Florida
New Berlin, Wisconsin
North Miami Beach, Florida
Apopka, Florida
Bartlett, Tennessee
Rowlett, Texas
Novi, Michigan
White Plains, New York
Arcadia, California
Redmond, Washington
Lake Elsinore, California
Ocala, Florida
Tinley Park, Illinois
Port Orange, Florida
Medford, Massachusetts
Oak Lawn, Illinois
Rocky Mount, North Carolina
Kokomo, Indiana
Coconut Creek, Florida
Bowie, Maryland
Berwyn, Illinois
Midwest City, Oklahoma
Fountain Valley, California
Buckeye, Arizona
Dearborn Heights, Michigan
Woodland, California
Noblesville, Indiana
Valdosta, Georgia
Diamond Bar, California
Manhattan, Kansas
Santee, California
Taunton, Massachusetts
Sanford, Florida
Kettering, Ohio
Chicopee, Massachusetts
Watsonville, California
Gilroy, California
Milford, Connecticut
Rosemead, California
Lompoc, California
Lehi, Utah
Goshen, Indiana
Golden Valley, Minnesota
Cypress, California
Casa Grande, Arizona
Garden City, Michigan
Huntington, West Virginia
Tigard, Oregon
Lenexa, Kansas
Saginaw, Michigan
Aliso Viejo, California
Farmington, Missouri
Annapolis, Maryland
Greenwood, Indiana`.split("\n");

export default cities;
