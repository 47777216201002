import { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import _ from "lodash";

import api from "../api/endpoints";

function compareByName(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

const CompanySelect = ({ onChange, value }) => {
  const [companies, setCompanies] = useState();

  useEffect(() => {
    api.companies
      .list()
      .then(({ data }) => {
        const { companies } = data;
        setCompanies(companies);

        // If it's just the one it must be the selection
        if (companies.length === 1) {
          onChange(companies[0]);
        }
      })
      .catch((error) => {
        window.alert(error);
      });
  }, []);

  if (!companies || companies.length === 1) return <CircularProgress />;

  return (
    <Autocomplete
      value={value || undefined}
      onChange={(_, company) => {
        onChange(company);
      }}
      options={companies.sort(compareByName)}
      openOnFocus
      getOptionLabel={(option) => {
        if (option.num_users) {
          return `${option.id} - ${option.name} (${option.num_users} users)`;
        }

        if (option.id && option.name) {
          return `${option.id} - ${option.name}`;
        }

        return "";
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Company" />
      )}
    />
  );
};

export default CompanySelect;
