import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Modal,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const PreviousEmailUsagePrompt = ({ open, previous, userGroupOptions, onConfirm, onClose }) => {
  if (!open) {
    return null;
  }

  const audienceLabel = userGroupOptions.find((x) => x.value === previous.audience)?.label || 'unknown'
  const audience = audienceLabel + (previous.quiz_id ? ` (quiz ID: ${previous.quiz_id})` : '');

  return (
    <Modal open={true}>
      <Box sx={{ width: 800, height: 600, margin: '50px auto', overflow: 'auto' }}>
        <Card>
          <CardHeader
            title="Use previous email as a template?" action={
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <p>Do you want to use previous email as a template for this one?</p>
            <p>It was delivered to {audience} on {previous.created_at}.</p>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={onConfirm}>Yes</Button>
            <Button variant="contained" color="secondary" onClick={onClose}>No</Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}

export default PreviousEmailUsagePrompt;
