import { useState } from "react";
import {
  Stack,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Collapse,
} from "@mui/material";
import AnswerLine from "./AnswerLine";
import ExpandMore from "./ExpandMore";

const SimpleDisplay = ({ title, subtitle, answer }) => {
  const [open, setOpen] = useState();

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subtitle}
        action={
          <ExpandMore
            expanded={open}
            handleExpandClick={() => setOpen(!open)}
          />
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <AnswerLine title="Header" body={answer.name} />
            <AnswerLine title="Explanation" body={answer.explanation} />
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SimpleDisplay;
