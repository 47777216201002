import {
  Drawer,
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DetailDrawer = ({ children, title, handleClose, width = 1250 }) => (
  <Drawer open onClose={handleClose} anchor="right">
    <Card
      sx={{
        height: "100%",
        width: width,
        maxWidth: "100%",
        paddingTop: "64px",
      }}
    >
      <CardHeader
        title={title}
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent sx={{ height: "calc(100% - 64px)", overflowY: "auto" }}>
        {children}
      </CardContent>
    </Card>
  </Drawer>
);

export default DetailDrawer;
