import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import commaNumber from "comma-number";
import moment from "moment";
import formatPercentage from "./formatPercentage";

const sortHash = (hash) =>
  Object.keys(hash)
    .map((key) => {
      const value = hash[key];

      return {
        key,
        value,
      };
    })
    .sort((a, b) => b.value - a.value);

const packageCsv = (data, id) => {
  const csv = data
    .map((e) =>
      e
        .map((content, index) => (index === 1 ? `"${content}"` : content))
        .join(",")
    )
    .join("\n");

  const hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = `quizzify-quiz-${id}-link-click-data-${moment().format(
    "YYYY-MM-DD"
  )}.csv`;
  hiddenElement.click();
};

const Links = ({
  total,
  linkData,
  textData,
  questionData,
  rawData,
  userData,
  id,
}) => (
  <Grid container spacing={2}>
    <Grid item>
      <Card style={{ width: "fit-content", height: "100%" }}>
        <CardHeader title="Total Clicks" />
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            {commaNumber(total)}
          </Typography>
          <Button color="primary" onClick={() => packageCsv(rawData, id)}>
            Download full data
          </Button>
        </CardContent>
      </Card>
    </Grid>
    <Grid item>
      <Card style={{ width: "fit-content" }}>
        <CardHeader title="Percent of Users Who Clicked" />
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            {formatPercentage(
              userData.uniqueUsersWhoClicked,
              userData.totalUsers
            )}
          </Typography>
          <Typography>
            Users Who Clicked: {commaNumber(userData.uniqueUsersWhoClicked)}
          </Typography>
          <Typography>
            Total Users Who Started Playing: {commaNumber(userData.totalUsers)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} />
    <Grid item xs={12} lg={6}>
      <Card>
        <CardHeader title="Clicks by Link" />
        <CardContent>
          <Table>
            <TableBody>
              {sortHash(linkData).map((link) => (
                <TableRow key={link.key}>
                  <TableCell style={{ wordBreak: "break-word" }}>
                    {link.key}
                  </TableCell>
                  <TableCell>{link.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} lg={6}>
      <Card>
        <CardHeader title="Clicks by Text" />
        <CardContent>
          <Table>
            <TableBody>
              {sortHash(textData).map((link) => (
                <TableRow key={link.key}>
                  <TableCell style={{ wordBreak: "break-word" }}>
                    {link.key}
                  </TableCell>
                  <TableCell>{link.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default Links;
