import React from "react";
import { Stack } from "@mui/material";

import Profile from "./Profile";
import QuestionsOverview from "./QuestionsOverview";
import Scores from "./Scores";
import Completions from "./Completions";
import UserBreakdown from "./UserBreakdown";

const Summary = (props) => (
  <Stack spacing={2} sx={{ width: "100%" }}>
    <Profile {...props} />
    <QuestionsOverview {...props} />
    <Scores {...props} />
    <Completions {...props} />
    <UserBreakdown {...props} />
  </Stack>
);

export default Summary;
