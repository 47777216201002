import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";

const Check = () => <span style={{ color: "green" }}>✔</span>;

const Cross = () => <span style={{ color: "red" }}>✘</span>;

const truncate = (str) => {
  if (str.length < 35) {
    return str;
  }
  return str.slice(0, 35) + "...";
};

const ReportTable = ({
  entries,
  quizzes,
  hasCompanyGroups,
  supportsSpouses,
  hasEmployeeId,
  totalCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  showRoster,
}) => {
  const handlePageChange = (e, newPage) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    onPageSizeChange(parseInt(e.target.value, 10));
  };

  return (
    <div style={{ flex: 1 }}>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                key="first_name"
                sx={(theme) => ({
                  fontWeight: "bold",
                  backgroundColor: theme.palette.grey[200],
                  zIndex: 100,
                  width: 125,
                  minWidth: 125,
                  left: 0,
                  top: 0,
                  position: "sticky",
                  fontSize: 12,
                  padding: "4px 8px",
                  verticalAlign: "top",
                })}
              >
                Name
              </TableCell>
              <TableCell
                key="email"
                sx={(theme) => ({
                  fontWeight: "bold",
                  backgroundColor: theme.palette.grey[200],
                  zIndex: 100,
                  left: 125,
                  top: 0,
                  position: "sticky",
                  fontSize: 12,
                  padding: "4px 8px",
                  verticalAlign: "top",
                })}
              >
                Email
              </TableCell>

              {hasCompanyGroups ? (
                <TableCell
                  key="company_group"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    backgroundColor: theme.palette.grey[200],
                    fontSize: 12,
                    position: "sticky",
                    padding: "4px 8px",
                    verticalAlign: "top",
                  })}
                >
                  Company Group
                </TableCell>
              ) : null}
              {supportsSpouses ? (
                <TableCell
                  key="is_spouse"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    backgroundColor: theme.palette.grey[200],
                    fontSize: 12,
                    position: "sticky",
                    padding: "4px 8px",
                    verticalAlign: "top",
                  })}
                >
                  Spouse?
                </TableCell>
              ) : null}
              {hasEmployeeId ? (
                <TableCell
                  key="employee_id"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    backgroundColor: theme.palette.grey[200],
                    fontSize: 12,
                    position: "sticky",
                    padding: "4px 8px",
                    verticalAlign: "top",
                  })}
                >
                  Employee ID
                </TableCell>
              ) : null}
              {showRoster && (
                <TableCell
                  key="on-roster"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    backgroundColor: theme.palette.grey[200],
                    fontSize: 12,
                    position: "sticky",
                    padding: "4px 8px",
                    verticalAlign: "top",
                  })}
                >
                  Last Roster Update At
                </TableCell>
              )}
              <TableCell
                key="total"
                sx={(theme) => ({
                  fontWeight: "bold",
                  backgroundColor: theme.palette.grey[200],
                  zIndex: 100,
                  left: 350,
                  top: 0,
                  position: "sticky",
                  textAlign: "right",
                  borderRight: "1px solid grey",
                  fontSize: 12,
                  padding: "4px 8px",
                  verticalAlign: "top",
                })}
              >
                Num Quizzes Completed
              </TableCell>
              {quizzes.map((quiz) => (
                <Tooltip key={quiz.id} title={quiz.name}>
                  <TableCell
                    sx={{
                      maxWidth: 75,
                      minWidth: 75,
                      padding: "4px 8px",
                      verticalAlign: "top",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }} gutterBottom>
                        {truncate(quiz.name)}
                      </Typography>
                      <>
                        <Typography sx={{ fontSize: 8 }}>
                          {quiz.start}
                        </Typography>
                        <Typography sx={{ fontSize: 8 }}>
                          (Series: {quiz.year})
                        </Typography>
                      </>
                    </Box>
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ flexShrink: 1, overflowY: "auto" }}>
            {entries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell
                  key="full_name"
                  sx={(theme) => ({
                    width: 125,
                    minWidth: 125,
                    maxWidth: 125,
                    wordBreak: "break-all",
                    boxSizing: "border-box",
                    left: 0,
                    position: "sticky",
                    backgroundColor: theme.palette.grey[50],
                    fontSize: 12,
                    padding: "4px 8px",
                  })}
                >
                  {entry.first_name} {entry.last_name}
                </TableCell>
                <TableCell
                  key="email"
                  sx={(theme) => ({
                    width: 225,
                    minWidth: 225,
                    wordBreak: "break-all",
                    boxSizing: "border-box",
                    left: 125,
                    position: "sticky",
                    backgroundColor: theme.palette.grey[50],
                    fontSize: 12,
                    padding: "4px 8px",
                  })}
                >
                  {entry.email}
                </TableCell>

                {hasCompanyGroups ? (
                  <TableCell
                    key="company_group"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey[50],
                      fontSize: 12,
                      padding: "4px 8px",
                    })}
                  >
                    {entry.companyGroup}
                  </TableCell>
                ) : null}
                {supportsSpouses ? (
                  <TableCell
                    key="is_spouse"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey[50],
                      fontSize: 12,
                      padding: "4px 8px",
                    })}
                  >
                    {entry.is_spouse ? <Check /> : <Cross />}
                  </TableCell>
                ) : null}
                {hasEmployeeId ? (
                  <TableCell
                    key="employee_id"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey[50],
                      fontSize: 12,
                      padding: "4px 8px",
                    })}
                  >
                    {entry.employee_id}
                  </TableCell>
                ) : null}
                {showRoster && (
                  <TableCell
                    key="roster"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey[50],
                      fontSize: 12,
                      padding: "4px 8px",
                    })}
                  >
                    {entry.last_roster_update_at
                      ? moment(entry.last_roster_update_at).calendar()
                      : ""}
                  </TableCell>
                )}
                <TableCell
                  key="total"
                  sx={(theme) => ({
                    width: 60,
                    minWidth: 60,
                    left: 350,
                    position: "sticky",
                    backgroundColor: theme.palette.grey[50],
                    textAlign: "right",
                    borderRight: "1px solid grey",
                    fontSize: 12,
                    padding: "4px 8px",
                  })}
                >
                  {entry.completed_count}
                </TableCell>
                {quizzes.map((quiz) => (
                  <TableCell key={quiz.id} sx={{ textAlign: "center" }}>
                    {entry.completed.includes(quiz.id) ? <Check /> : <Cross />}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};

export default ReportTable;
