import queryString from "query-string";

const deleteCode = () => {
  const parsed = queryString.parse(window.location.search);
  delete parsed.code;

  const stringified = queryString.stringify(parsed);

  // This changes the link without reloading the page
  const newPath = window.location.pathname + "?" + stringified;

  window.history.replaceState({}, document.title, newPath);
};

export default deleteCode;
